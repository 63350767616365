import React, { useState, useEffect } from "react";

import {
  Container,
  Button,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";

import API from "../../../axios";
import {
  UsePagination,
  PopUpMsg,
  SelectBox,
  SearchBox,
} from "../../../components";
import AddLabelForm from "./add-label-form";
import TableRowComponent from "./table-row-component";

const _mode = process.env.REACT_APP_MODE ?? "production";

const generateKey = () => {
  return Math.ceil(Math.random() * 100000);
};

export default function AdTranslation() {
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const [update, setUpdate] = useState({
    key1: generateKey(),
    key2: generateKey(),
  });

  useEffect(() => {
    console.log("there");
    getData();
  }, [page, search, role]);

  const handleSubmission = () => {
    setShowForm(false);
  };

  const getData = async() => {
    setIsLoading(true);
   try {
          const res = await API(`admin/translations?page=${page}&key=${search}&role=${role}`, "get")
        setRecords(res.data);
        setIsLoading(false);
      }
      catch(err) {
        setIsLoading(false);
      };
  };

  var spanTd = 5;

  return (
    <Container maxWidth="100%" sx={{ py: 3, position: "relative" }}>
      <Typography variant="h6">Translation</Typography>
      <Box textAlign="end" mt={2}>
        {_mode === "development" ? (
          <>
            <Button variant="contained" onClick={() => setShowForm(!showForm)}>
              {showForm ? "Cancel" : "Create"}
            </Button>{" "}
          </>
        ) : null}
        <Button variant="contained" disabled={isLoading} onClick={getData}>
          Refresh
        </Button>
      </Box>
      {showForm && <AddLabelForm handleSubmission={handleSubmission} />}
      <Box mt={3}>
        <Stack direction="row" spacing={2}>
          <SelectBox
            size="small"
            label="Dashboard"
            required
            key={update.key1}
            handleChange={(e) => setRole(e.target.value)}
            items={[
              { label: "Super Admin", value: "superadmin" },
              { label: "Manager", value: "manager" },
              { label: "Employee", value: "employee" },
            ]}
          />
          <SearchBox
            size="small"
            key={update.key2}
            label="Search by key"
            placeholder="search"
            handleChange={(v) => setSearch(v)}
          />
          <Button
            variant="contained"
            onClick={() => {
              setRole("");
              setSearch("");
              setUpdate({
                key1: generateKey(),
                key2: generateKey(),
              });
            }}
          >
            Clear
          </Button>
        </Stack>
      </Box>
      {!!records && records?.last_page > 1 && (
        <Box component="div" sx={{ mt: 4 }}>
          <UsePagination
            total={records?.total}
            perPage={records?.per_page}
            page={page}
            setPage={setPage}
          />
        </Box>
      )}
      <TableContainer>
        <Table sx={{ mt: 4, minWidth: "700px" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#E6F5FE" }}>
              {["Key", "English (en)", "German (de)", "Other", "Action"].map(
                (v, i) => (
                  <TableCell key={i}>{v}</TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={spanTd} align="center">
                  <CircularProgress size={22} />
                </TableCell>
              </TableRow>
            ) : !!records && !records?.data?.length ? (
              <TableRow>
                <TableCell colSpan={spanTd} align="center">
                  <Typography variant="caption1">No Records Found</Typography>
                </TableCell>
              </TableRow>
            ) : (
              records?.data.map((v, i) => (
                <TableRowComponent
                  data={v}
                  key={i}
                  onDelete={() => {
                    getData();
                    setOpen(true);
                  }}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!!records && records?.last_page > 1 && (
        <Box component="div" sx={{ mt: 2 }}>
          <UsePagination
            total={records?.total}
            perPage={records?.per_page}
            page={page}
            setPage={setPage}
          />
        </Box>
      )}
      <PopUpMsg
        open={open}
        type="success"
        message={"Record has been deleted successfully!"}
        handleClose={() => setOpen(false)}
      />
    </Container>
  );
}
