import React, { useState, useEffect } from "react";

import {
  Container,
  Button,
  Box,

  TableRow,
  TableCell,
  Typography,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";

import API from "../../../axios";
import { TableWrapper, UsePagination } from "../../../components";
import CreateModel from "./create-model";
import UpdateModel from "./update-model";
import ApiImage from "../../../hooks/fetch-image";

export default function GetModels() {
  const [isLoading, setIsLoading] = useState(false);
  const [models, setModels] = useState(null);
  const [modelId, setModelId] = useState(0);
  const [id, setId] = useState(0);
  const [page, setPage] = useState(1);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  
  useEffect(() => {
    GetModels();
  }, [page]);
  const GetModels = async () => {
    setIsLoading(true);
    try {
       const res = await API(`admin/modals?page=${page}`, "get")
        setModels(res.data);
        setIsLoading(false);
      }
      catch(err) {
        setIsLoading(false);
        setModels(null);
      };
  };
  var tableCols = ["Title", "Manufacturer", "Picture", "Status", "Action"];

  return (
    <>
      <Container maxWidth="100%" sx={{ py: 3 }}>
        <Typography variant="h6">Models</Typography>
        <Box textAlign="end" mt={2} mb={2}>
          <Button variant="contained" onClick={() => setCreateModal(true)}>
            Create
          </Button>
        </Box>
        <TableWrapper
          thContent={tableCols.map((column, index) => (
            <TableCell key={index}>{column}</TableCell>
          ))}
          spanTd={tableCols.length}
          isLoading={isLoading}
          isContent={!!models && models?.data.length ? true : false}
          tableStyle={{minWidth:"400px"}}
        >
          {!!models &&
            models?.data.map((model, index) => (
              <TableRow key={index}>
                <TableCell>{model.title}</TableCell>
                <TableCell>{model.manufacturers?.manufacturer}</TableCell>
                <TableCell>
                  <ApiImage url={`modal-image/${model.id}`} styles={{width:'50px', height: '50px', borderRadius:'100%' }} />
                </TableCell>
                <TableCell>
                  <Chip
                    label={model.active ? "active" : "disabled"}
                    color={model.active ? "success" : "error"}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={() => {
                        setUpdateModal(true);
                        setId(model.id);
                        setModelId(model.manufacturer_id);
                      }}
                      color="primary"
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableWrapper>
        {!!models && models?.last_page > 1 && (
          <Box component="div" sx={{ mt: 2 }}>
            <UsePagination
              total={models?.total}
              perPage={models?.per_page}
              page={page}
              setPage={setPage}
            />
          </Box>
        )}
      </Container>
      {createModal && (
        <CreateModel
          handleClose={() => setCreateModal(false)}
          getData={GetModels}
        />
      )}
      {updateModal && (
        <UpdateModel
          id={id}
          setId={setId}
          manufacturerId={modelId}
          setManufacturerId={setModelId}
          handleClose={() => setUpdateModal(false)}
          getData={GetModels}
        />
      )}
    </>
  );
}


