export const _data={
    gn_info_card:{
        title:'GENERAL INFORMATION',
        rows:[
            {
                title:'Manufacturer',
                disc:'Huawei'
            },
            {
                title:'Model',
                disc:'LUNA2000-10-50'
            },
            {
                title:'Status',
                disc:'Active'
            },
            {
                title:'Data sheet',
                disc:'DN, EN-GB'
            },
        ]
    },
    main_pm_card:{
        title:'MAIN PARAMETERS',
        rows:[
            {
                title:'Max. charging power of the system',
                disc:'Huawei',
                tip:'tooltip'
            },
            {
                title:'Nominal capacity Max. discharge capacity of the system',
                disc:'LUNA2000-10-50',
                tip:'tooltip'
            },
            {
                title:'Degradation',
                disc:'Huawei',
                tip:'tooltip'
            },
            {
                title:'Module performance Efficiency (round-trip)',
                disc:'Active',
                tip:'tooltip'
            }
        ]
    },
    memo_mod_card:{
        title:'MEMORY MODULE PARAMETERS',
        rows:[
            {
                title:'Rated capacity',
                disc:'10kWh',
                tip:'tooltip'
            },
            {
                title:'Usable capacity',
                disc:'10kWh',
                tip:'tooltip'
            },
            {
                title:'Charging cycles',
                disc:'6000',
                tip:'tooltip'
            },
            {
                title:'Module performance',
                disc:'7 kw',
                tip:'tooltip'
            },
            {
                title:'Min.',
                disc:'1',
                tip:'tooltip'
            },
            {
                title:'Max.',
                disc:'1',
                tip:'tooltip'
            }
        ]
    }
}