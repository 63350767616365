import React, { useState } from "react";
import {
    Box,
    Stack,
    Divider,
    Button,
    Typography,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Tooltip
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import InfoIcon from '@mui/icons-material/Info';

import { useTranslation } from "react-i18next";

import { logo2 } from "../../../../assets";
import { InputField, StartIconBtn } from "../../../../components";
import { _data } from "../data";
import NewComponentForm from "../forms/new-component-form";
import { useNavigate } from "react-router-dom";

export default function EditComponentsPanel({ switchScreen, ...props }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const printCard = (_d) => {
        return (
            <div>
                <Typography
                    variant="h6"
                    sx={{ textAlign: 'center', fontSize: '14px' }}
                >
                    {_d.title}
                </Typography>
                {_d.rows.map((_v, _i) => {
                    return <Stack
                        key={_i}
                        direction='row'
                        alignItems='flex-start'
                        justifyContent='space-between'
                        spacing={1}
                        sx={{ mt: 1 }}
                    >
                        <Box>
                            <p>{_v.title}</p>
                        </Box>
                        <Stack
                            key={_i}
                            direction='row'
                            alignItems='flex-center'
                            justifyContent='space-between'
                            spacing='5px'
                            sx={{ mt: 1 }}>
                            {_v.tip && <Tooltip title={_v.tip}>
                                <InfoIcon sx={{ color: '#969696' }} fontSize="small" />
                            </Tooltip>}
                            <small style={{ color: '#969696' }}>{_v.disc}</small>
                        </Stack>
                    </Stack>
                })}
            </div>
        );
    }

    return (
        <>
            <Box sx={{ pt: 2, pb: 2 }}>
                <Grid container spacing={2} >
                    <Grid item sm={12} xs={12}>
                        <Box
                        >
                            <Stack
                                direction="row"
                                alignItems='center'
                                divider={<Divider orientation="vertical" flexItem />}
                                spacing={2}
                            >
                                <Button
                                    sx={{ textTransform: 'none' }}
                                    onClick={() => switchScreen()}
                                >
                                    Back
                                </Button>
                                <Typography
                                    variant='p'
                                    component='p'
                                    sx={{ fontSize: '13px' }}
                                >
                                    Huawei - LUNA2000-10-50
                                </Typography>
                                <Button
                                    sx={{
                                        textTransform: 'none',
                                        color:'#fff',
                                        backgroundColor: '#000',
                                        '&:hover': {
                                            backgroundColor: '#000',
                                        }
                                    }}
                                >
                                    Archive
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: { md: 'display', xs: 'none' } }} >
                        <Box sx={{ textAlign: 'end' }}>
                            <img src={logo2} width="100%" style={{ maxWidth: '150px' }} alt='' />
                        </Box>
                    </Grid>
                </Grid>
                <Typography
                    variant='h6'
                    component='h6'
                    sx={{ mt: 3 }}
                >
                    BATTERY MEMORY
                </Typography>
                <Box sx={{ borderBottom: '1px solid #ccc', mt: 2 }}>
                    <Typography
                        variant='p'
                        component='p'
                        sx={{ borderBottom: '1px solid', pb: 1, borderColor: 'primary.main', width: 'fit-content' }}
                    >
                        DEUTCH (CH)
                    </Typography>
                </Box>
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item>
                        <InputField
                            size='small'
                            required
                            label='Name'
                            fullWidth
                            error=''
                            handleChange={() => { }}
                        />
                        <Box sx={{ mt: 2 }}>
                            <InputField
                                size='small'
                                label='Item Number (optional)'
                                fullWidth
                                error=''
                                handleChange={() => { }}
                            />
                        </Box>
                    </Grid>
                    <Grid item>
                        <InputField
                            placeholder='Description'
                            size='small'
                            label='Description'
                            fullWidth
                            error=''
                            multiline
                            rows={4}
                            handleChange={() => { }}
                        />
                    </Grid>
                    <Grid item>
                        <InputField
                            placeholder='Manual input'
                            size='small'
                            required
                            label='Tags'
                            fullWidth
                            error=''
                            handleChange={() => { }}
                        />
                    </Grid>
                </Grid>
                <Typography
                    variant='h6'
                    component='h6'
                    sx={{ mt: 3 }}
                >
                    PRICE PARAMETERS
                </Typography>
                <Typography
                    variant='h6'
                    component='h6'
                    sx={{ mt: 3 }}
                >
                    PRICE PARAMETERS (BATTERY CABINET) (EXCL. VAT)
                </Typography>
                <Box component='div' maxWidth='800px' sx={{ mt: 3 }}>
                    <TableContainer>
                        <Table
                            sx={{
                                minWidth: 650,
                                '& .MuiTableCell-root': {
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                }
                            }}
                        >
                            <TableHead>
                                <TableRow
                                    sx={{
                                        backgroundColor: "#E6F5FE",
                                    }}
                                >
                                    <TableCell>Price level</TableCell>
                                    <TableCell>Cost price</TableCell>
                                    <TableCell>Calculation surcharge</TableCell>
                                    <TableCell>Installation costs</TableCell>
                                    <TableCell>Selling price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>1.00 St.</TableCell>
                                    <TableCell>8250.00 CHF/St.</TableCell>
                                    <TableCell>0.0000%</TableCell>
                                    <TableCell>0.00 CHF/St.</TableCell>
                                    <TableCell>8250.00 CHF/St.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <StartIconBtn
                    title='Add price'
                    variant='contained'
                    size='small'
                    fullWidth
                    styles={{ maxWidth: '150px', mt: 4 }}
                    handleClick={() => setOpen(true)}
                    icon={<AddIcon />} />
                <Typography
                    variant='h6'
                    component='h6'
                    sx={{ mt: 3 }}
                >
                    COMPONENT INFORMATION
                </Typography>
                <Grid container spacing={2} sx={{ mt: 3, alignItems: 'stretch' }}>
                    <Grid item md={3} sm={6} xs={12}>
                        <Box component={Paper}
                            sx={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 1
                            }}
                        >
                            <ImageIcon size='large' />
                        </Box>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <Box component={Paper}
                            sx={{
                                p: 2,
                                height: '100%',
                            }}
                        >
                            {printCard(_data.gn_info_card)}
                        </Box>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <Box component={Paper}
                            sx={{
                                p: 2,
                                height: '100%',
                            }}
                        >
                            {printCard(_data.main_pm_card)}
                        </Box>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <Box component={Paper}
                            sx={{
                                height: '100%',
                                p: 2
                            }}
                        >
                            {printCard(_data.memo_mod_card)}
                        </Box>
                    </Grid>
                </Grid>
                <NewComponentForm open={open} handleClose={() => setOpen(false)} />
            </Box>
        </>
    )
}