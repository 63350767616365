import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";
import AppRoutes from './routes/app-routes';
import React from 'react';
import store from './store/store';
import { Provider } from 'react-redux';

export default function App() {  
  const theme = createTheme({
    typography: {
      fontFamily: [
        'Inter',
      ].join(','),
    },
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </Provider >
  );
}