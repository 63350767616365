import React, { useState, useEffect } from "react";

import {
  Box,
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
  Popover,
  Stack,
  IconButton,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import API from "../../../axios";
import { InputField } from "../../../components";

const _mode = process.env.REACT_APP_MODE ?? "production";

export default function TableRowComponent({ data = {}, onDelete = () => {} }) {
  const [rowData, setRowData] = useState({
    key: "",
    english: "",
    german: "",
    other: "",
  });
  const [changeOccur, setChangeOccur] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [errors, setErrors] = useState({
    english: "",
    german: "",
  });

  useEffect(() => {
    setRowData({
      key: data.key ?? "",
      english: data.english ?? "",
      german: data.german ?? "",
      other: data.other ?? "",
    });
  }, [data !== null]);

  const handleChange = (e = "", index) => {
    if (!changeOccur) setChangeOccur(true);
    const { name, value } = e.target;
    setRowData({ ...rowData, [name]: value });
  };

  const validate = () => {
    let flag = false;
    let msg = {
      english: "",
      german: "",
    };
    if (rowData.english === "") {
      flag = true;
      msg.english = "Field is required!";
    }
    if (rowData.german === "") {
      flag = true;
      msg.german = "Field is required!";
    }
    setErrors(msg);
    return flag;
  };

  const updateRow = async () => {
    if (validate()) return;
    setIsLoading(true);
    let url = `admin/translation/${data.id}`;
    try {
         const res = await API(url, "post", rowData)
        setOpen(true);
        setMessage("Record updated successfully!");
        setIsLoading(false);
        setChangeOccur(false);
      }
      catch(err) {
        setIsLoading(false);
        setOpen(true);
        setMessage("Error Occurred. Please try again!");
      };
  };

  const DeleteRow = async () => {
    if (changeOccur) setChangeOccur(false);
    setIsLoading(true);
    let url = `admin/delete-translation/${data.id}`;
    try {
          const res = await API(url, "delete", rowData)
        setIsLoading(false);
        onDelete();
      }
      catch(err) {
        setIsLoading(false);
        setOpen(true);
        setMessage("Error Occurred. Please try again!");
      };
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ verticalAlign: "top" }}>{rowData.key}</TableCell>
        <TableCell sx={{ verticalAlign: "top" }}>
          <InputField
            initValue={rowData.english}
            placeholder="Type..."
            multiline
            name="english"
            fullWidth
            error={errors.english}
            label="English (En)*"
            size="small"
            handleChange={(e) => handleChange(e)}
            style={{
              fontSize: "14px !important",
            }}
          />
        </TableCell>
        <TableCell sx={{ verticalAlign: "top" }}>
          <InputField
            placeholder="Type..."
            initValue={rowData.german}
            multiline
            label="German (De)*"
            error={errors.german}
            name="german"
            fullWidth
            size="small"
            handleChange={(e) => handleChange(e)}
          />
        </TableCell>
        <TableCell sx={{ verticalAlign: "top" }}>
          <InputField
            initValue={rowData.other}
            multiline
            placeholder="Type..."
            label="Other"
            name="other"
            fullWidth
            size="small"
            handleChange={(e) => handleChange(e)}
          />
        </TableCell>
        <TableCell sx={{ verticalAlign: "top" }}>
          <Stack direction="row" spacing={2} alignItems="center">
            {changeOccur ? (
              <Box>
                <IconButton
                  onClick={updateRow}
                  disabled={isLoading}
                  type="button"
                  color="success"
                  aria-describedby={data.id}
                >
                  {isLoading ? (
                    <CircularProgress
                      size={18}
                      sx={{ color: "#fff", mr: 1, size: "12px" }}
                    />
                  ) : (
                    <CheckIcon />
                  )}
                </IconButton>
              </Box>
            ) : null}
            {_mode === "development" ? (
              <IconButton
                onClick={DeleteRow}
                disabled={isLoading}
                type="button"
                aria-describedby={data.id}
                color="error"
              >
                {isLoading ? (
                  <CircularProgress
                    size={18}
                    sx={{ color: "#fff", mr: 1, size: "12px" }}
                  />
                ) : (
                  <DeleteForeverIcon />
                )}
              </IconButton>
            ) : null}
          </Stack>
        </TableCell>
      </TableRow>

      <Popover
        id={data.id}
        open={open ? "simple-popover" : undefined}
        anchorEl={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Typography sx={{ p: 2 }}>{message}</Typography>
      </Popover>
    </>
  );
}
