import React, { useState } from 'react';
import {
    Box,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Grid,
    Typography,
    Button,
    CircularProgress,
    Stack,
    FormControl
} from "@mui/material";
import { InputField, SearchComboBox, SelectBox } from "../../../../components";
import API from '../../../../axios';

function ComponentForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [isCustom, setIsCustom] = useState(true);
    const [selectedType, setSelectedType] = useState(null);
    const [componentTypes, setComponentTypes] = useState(null);

    React.useEffect(() => {
        getComponentTypes();
        console.log('there');
    }, []);

    const getComponentTypes=()=>{
    }

    const handleform = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }
    return (
        <Box
            component='form'
            autoCapitalize="off"
            autoComplete="off"
            onSubmit={handleform}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        variant='h6'
                        sx={{
                            textAlign: 'center',
                            mb: 3
                        }}
                    >
                        Create Component
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                    <SearchComboBox
                        label='Component Type'
                        fullWidth
                        size='small'
                        handleChange={(e) => console.log('1',e.target.value)}                    
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    {isCustom ?
                        <InputField
                            size="small"
                            fullWidth
                            label='Name'
                            name='name'
                        />
                        :
                        <SelectBox
                            label='Component Type'
                            items={[{ value: '1', label: 'asd' }, { value: 'asdasd', label: 'asd' }]}                            
                            fullWidth
                            size='small'
                            handleChange={(e) => console.log('2',e.target.value)}
                        />
                    }
                </Grid>
                <Grid item md={4} xs={12}>
                    {isCustom ?
                        <InputField
                            size="small"
                            fullWidth
                            label='Item Number'
                            type="number"
                            inputProps={{
                                'min': '0'
                            }}
                            name='item_number'
                        />
                        :
                        <SelectBox
                            label='Component Type'
                            items={[{ value: '1', label: 'asd' }, { value: 'asdasd', label: 'asd' }]}
                            fullWidth
                            size='small'
                            handleChange={(e) => console.log('3',e.target.value)}
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    <InputField
                        size="small"
                        fullWidth
                        label='Description'
                        name='description'
                        multiline
                        rows="4"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox color='success' />} label="Energy Management" />
                        <FormControlLabel control={<Checkbox color='success' />} label="Battery Storage" />
                        <FormControlLabel control={<Checkbox color='success' />} label="Photovoltaic" />
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{ textAlign: 'end' }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => { }}
                            sx={{
                                mt: 2,
                                backgroundColor: '#000',
                                '&:hover': {
                                    backgroundColor: '#000'
                                }
                            }}
                            disabled={isLoading}
                            type="submit"
                        >
                            {isLoading ? (
                                <CircularProgress
                                    size={18}
                                    sx={{ color: "#fff", mr: 1, size: "12px" }}
                                />
                            ) : null}
                            Send
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ComponentForm;