import React from "react";
import {
    Container,
    Box,
    Tabs,
    Tab,
    Stack
} from "@mui/material";

import { Header } from "../../../components";
import { Link, useNavigate } from "react-router-dom";

export default function LibraryLayout({ children }) {
    const navigate = useNavigate();
    const routeName = window.location.pathname;

    const handleChange = (event, newValue) => {
        navigate(newValue);
    };

    return (
        <>
            <Header hideMenu />
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
                <Box sx={{ width: '100%', borderBottom: '1px solid #ccc' }}>
                    <Tabs value={routeName} onChange={handleChange}>
                        <Tab label="Components" value='/library' />
                        <Tab label="The Group" value='/library/groups' />
                        <Tab label="Main Component Template" value='/library/main-template' />
                        <Tab label="Project Template" value='/library/project-template' />
                        <Tab label="Solar calculator" value='/library/solar-calculator' />
                        <Tab label="Requirements" value='/library/requirements' />
                    </Tabs>
                </Box>
                {/* <Box sx={{ width: '100%', borderBottom: '1px solid #ccc' }}>
                    <Stack
                        direction='row'
                        spacing={2}
                    >
                        <Link to='/library'>Components</Link>
                        <Link to='/library/groups'>The Group</Link>
                        <Link to='/library/main-template'>Main Component Template</Link>
                        <Link to='/library/project-template'>Project Template</Link>
                        <Link to='/library/solar-calculator'>Solar calculator</Link>
                        <Link to='/library/requirements'>Requirements</Link>
                    </Stack>
                </Box> */}
                <Box sx={{ pt: "15px", pb: "15px" }}>
                    {children}
                </Box>
            </Container>
        </>
    )
}