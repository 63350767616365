import React from "react";
import {
    useMediaQuery,
    TextField,
    Box,
    Stack,
    Button,
    IconButton,
    Typography,
    Modal,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Grid,
    CircularProgress,
    Backdrop,
    Snackbar
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from "react-i18next";
import axios from "axios";

export default function ProjectForm({
    open = false,
    getData = () => { },
    singleRecord = {},
    setSingleRecord,
    handleClose }) {
    const [formData, setFormData] = React.useState({
        customer_type: 'firma',
        gender: 'herr',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        language: 'de',
        address: '',
        project_template: 'template1',
    });
    const [formError, setFormError] = React.useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
    });
    const [openDrop, setOpenDrop] = React.useState(false);
    const [showMessage, setShowMessage] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const matches = useMediaQuery('(min-width:900px)');
    const { t } = useTranslation();


    React.useEffect(() => {
        // if (singleRecord !== null) {
        //     setFormData({
        //         customer_type: singleRecord.customer_type ?? '',
        //         gender: singleRecord.gender ?? '',
        //         first_name: singleRecord.first_name ?? '',
        //         last_name: singleRecord.last_name ?? '',
        //         email: singleRecord.email ?? '',
        //         phone: singleRecord.phone ?? '',
        //         language: singleRecord.language ?? '',
        //         address: singleRecord.address ?? '',
        //         project_template: singleRecord.project_template ?? '',
        //     });
        // }
    }, []);

    const validate = () => {
        let flag = true;
        let reqMsg = 'Feld ist erforderlich!';
        let msg = 'Bitte geben Sie eine gültige E-Mail-Adresse an!';
        let phMsg = 'gültige Telefonnummer angeben!';
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        let reg2 = /^[+]?\d+$/;
        let errors = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            address: '',
        }
        if (formData.first_name === '') {
            errors.first_name = reqMsg;
            flag = false;
        }
        if (formData.last_name === '') {
            errors.last_name = reqMsg;
            flag = false;
        }
        if (formData.address === '') {
            errors.address = reqMsg;
            flag = false;
        }
        if (formData.email !== '' && reg.test(formData.email) === false) {
            errors.email = msg;
            flag = false;
        }
        if (formData.phone !== '' && reg2.test(formData.phone) === false) {
            errors.phone = phMsg;
            flag = false;
        }
        setFormError(errors);
        return flag;
    }

    const handleForm = (e) => {
        e.preventDefault();
        return;
        if (!validate()) return;
        let data = formData;
        Object.keys(data).forEach(key => {
            if (data[key] === '') {
                delete data[key];
            }
        });
        setOpenDrop(true);
        let url = singleRecord !== null ? `https://sourceignite.com/gamaa/api/update-project/${singleRecord.id}` : 'https://sourceignite.com/gamaa/api/project';
        axios.post(url, data, {
            headers: {
                'content-type': 'application/json; charset=utf-8',
            }
        }).then(res => {
            setOpenDrop(false);
            getData();
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 3000);
            setMessage(res.data.response.message);
            if (singleRecord !== null) {
                setSingleRecord(null);
            }
            resetObj();
            handleClose();
        }).catch(err => {
            setOpenDrop(false);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 3000);
            setMessage('Es ist ein Fehler aufgetreten. Bitte versuche es erneut!');
        })
    }

    const resetObj = () => {
        setFormData({
            customer_type: 'firma',
            gender: 'herr',
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            language: 'de',
            address: '',
            project_template: 'template1',
        });
    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => {
                    handleClose();
                    // if (singleRecord !== null) {
                    //     setSingleRecord(null);
                    //     resetObj();
                    // }
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflowY: 'auto',
                }}
            >
                <Box sx={matches ? modalStyle : modalStyle2}>
                    <Box
                        component='form'
                        noValidate
                        autoComplete="off"
                        onSubmit={handleForm}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    handleClose();
                                    // if (singleRecord !== null) {
                                    //     setSingleRecord(null);
                                    //     resetObj();
                                    // }
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Typography variant="h6" component="h5">
                            {t('HOME.FORM1.CREATE_NEW_PROJECT')}
                        </Typography>
                        <Typography variant="subtitle1" component="h5"
                            mt={2}
                            sx={{
                                color: '#464646'
                            }}
                        >
                            {t('HOME.FORM1.Customer_Type')}
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                row
                                name="controlled-radio-buttons-group">
                                <FormControlLabel value="Privat" control={<Radio
                                    checked={formData.customer_type === 'privat' ? true : false}
                                    onChange={() => setFormData({ ...formData, customer_type: 'privat' })}
                                    sx={rdBtnStyles} size="small" />} label={t('HOME.FORM1.Private')} />
                                <FormControlLabel value="Firma"
                                    control={<Radio
                                        checked={formData.customer_type === 'firma' ? true : false}
                                        onChange={() => setFormData({ ...formData, customer_type: 'firma' })}
                                        sx={rdBtnStyles} size="small" />} label={t('HOME.FORM1.Business')} />
                            </RadioGroup>
                        </FormControl>
                        <Typography variant="subtitle1" component="h5"
                            mt={2}
                            sx={{
                                color: '#464646'
                            }}>
                            {t('HOME.FORM1.Gender')}
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                row
                                name="controlled-radio-buttons-group">
                                <FormControlLabel value="herr" control={<Radio
                                    checked={formData.gender === 'herr' ? true : false}
                                    onChange={() => setFormData({ ...formData, gender: 'herr' })}
                                    sx={rdBtnStyles} size="small" />} label={t('HOME.FORM1.Mister')} />
                                <FormControlLabel value="frau"
                                    control={<Radio
                                        checked={formData.gender === 'frau' ? true : false}
                                        onChange={() => setFormData({ ...formData, gender: 'frau' })}
                                        sx={rdBtnStyles} size="small" />} label={t('HOME.FORM1.Woman')} />
                                <FormControlLabel value="keine_angabe"
                                    control={<Radio
                                        checked={formData.gender === 'keine_angabe' ? true : false}
                                        onChange={() => setFormData({ ...formData, gender: 'keine_angabe' })}
                                        sx={rdBtnStyles} size="small" />} label={t('HOME.FORM1.No_information')} />
                            </RadioGroup>
                        </FormControl>
                        <Grid container spacing={2}>
                            <Grid item md={3} xs={12}>
                                <Typography variant="subtitle1" component="h5"
                                    mt={1}
                                    sx={{
                                        color: '#464646'
                                    }}>
                                    {t('HOME.FORM1.First_Name')}
                                </Typography>
                                <TextField
                                    value={formData.first_name}
                                    required
                                    fullWidth
                                    error={formError.first_name}
                                    onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                                    id="outlined-basic" variant="outlined" placeholder="Aka|"
                                    sx={inputStyle}
                                    helperText={formError.first_name}
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Typography variant="subtitle1" component="h5"
                                    mt={1}
                                    sx={{
                                        color: '#464646'
                                    }}>
                                    {t('HOME.FORM1.Surname')}
                                </Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={formData.last_name}
                                    onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                                    id="outlined-basic" variant="outlined" placeholder="Nachname"
                                    sx={inputStyle}
                                    error={formError.last_name}
                                    helperText={formError.last_name}
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Typography variant="subtitle1" component="h5"
                                    mt={1}
                                    sx={{
                                        color: '#464646'
                                    }}>
                                    {t('HOME.FORM1.Language')}
                                </Typography>
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    sx={inputStyle}
                                    value={formData.language}
                                    onChange={(e) => setFormData({ ...formData, language: e.target.value })}
                                >
                                    {[{ label: 'German', key: 'de' },
                                    { label: 'English', key: 'en' }].map((v, i) => (
                                        <option key={i} value={v.key}>
                                            {v.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={3} xs={12}>
                                <Typography variant="subtitle1" component="h5"
                                    mt={2}
                                    sx={{
                                        color: '#464646'
                                    }}>
                                    {t('HOME.FORM1.Email_Optional')}
                                </Typography>
                                <TextField
                                    value={formData.email}
                                    fullWidth
                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    type='email'
                                    id="outlined-basic" variant="outlined" placeholder="doe@gmail.com"
                                    sx={inputStyle}
                                    error={formError.email}
                                    helperText={formError.email}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="subtitle1" component="h5"
                                    mt={2}
                                    sx={{
                                        color: '#464646'
                                    }}>
                                    {t('HOME.FORM1.Telephone_Number_Optional')}
                                </Typography>
                                <TextField
                                    value={formData.phone}
                                    fullWidth
                                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                    id="outlined-basic" variant="outlined"
                                    placeholder="E.g +41 11 111 11 11"
                                    sx={inputStyle}
                                    error={formError.phone}
                                    helperText={formError.phone}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Typography variant="subtitle1" component="h5"
                                    mt={1}
                                    sx={{
                                        color: '#464646'
                                    }}>
                                    {t('HOME.FORM1.Building_Address')}
                                </Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={formData.address}
                                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                                    id="outlined-basic" variant="outlined" placeholder="Addrese"
                                    sx={[inputStyle, { width: '100%' }]}
                                    error={formError.address}
                                    helperText={formError.address}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Typography variant="subtitle1" component="h5"
                                    mt={2}
                                    sx={{
                                        color: '#464646'
                                    }}>
                                    {t('HOME.FORM1.Project_Template')}
                                </Typography>
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    sx={inputStyle}
                                    value={formData.project_template}
                                    onChange={(e) => setFormData({ ...formData, project_template: e.target.value })}
                                >
                                    {['template1', 'template2', 'template3'].map((v, i) => (
                                        <option key={i} value={v}>
                                            {v}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Stack
                            direction='row'
                            spacing={1}
                            mt={7}
                        >
                            <Button
                                type="submit"
                                sx={{
                                    backgroundColor: '#000',
                                    color: '#fff',
                                    textTransform: 'none',
                                    paddingLeft: '25px',
                                    paddingRight: '25px',
                                    '&:hover': {
                                        backgroundColor: '#000',
                                    }
                                }}
                            >
                                {t('HOME.FORM1.Create_Project')}
                            </Button>
                            <Button
                                type="button"
                                sx={{
                                    backgroundColor: '#D71B1B',
                                    color: '#fff',
                                    textTransform: 'none',
                                    paddingLeft: '25px',
                                    paddingRight: '25px',
                                    '&:hover': {
                                        backgroundColor: '#D71B1B',
                                    }
                                }}
                                onClick={() => {
                                    handleClose();
                                    // if (singleRecord !== null) {
                                    //     setSingleRecord(null);
                                    //     resetObj();
                                    // }
                                }}
                            >
                                {t('HOME.FORM1.Abort')}
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Modal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999999 }}
                open={openDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={showMessage}
                autoHideDuration={6000}
                onClose={() => setShowMessage(false)}
                message={message}
            />
        </>
    );
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '800px',
    bgcolor: '#fff',
    border: '0',
    borderRadius: '7px',
    boxShadow: 24,
    p: 4,
    zIndex: 100
};

const modalStyle2 = {
    width: '100%',
    maxWidth: '100%',
    bgcolor: '#fff',
    border: '0',
    borderRadius: '7px',
    boxShadow: 24,
    p: 4,
    zIndex: 100
};

const inputStyle = {
    mt: 1,
    width: '100%',
    '& .MuiInputBase-input': {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    "& .Mui-focused fieldset": {
        borderColor: "#1B9607 !important",
    },
    '& .MuiNativeSelect-select': {
        paddingTop: '10px',
        paddingBottom: '10px',
    }
}

const rdBtnStyles = {
    color: '#1B9607',
    '&.Mui-checked': {
        color: '#1B9607',
    },
}