import React, { useState } from 'react';
import {
    Box,
    Container,
    Paper,
    Typography,
    Stack,
    Button,
    CircularProgress,
} from "@mui/material";
import PageTitle from "../../hooks/page-title";
import { InputField, PopUpMsg } from "../../components";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

export default function ForgetPassword() {
    PageTitle('Forget Password');
    const [isLoading, setIsLoading] = React.useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [payload, setPayload] = React.useState({
        type: "info",
        message: "",
    });

    const handleSubmit = ((e) => {
        e.preventDefault();
        setIsLoading(true);
        let fd = new FormData();
        fd.append('email', email);
        const url = process.env.REACT_APP_API_KEY;
        axios.post(url + 'forget/password', fd)
            .then((res) => {
                setIsLoading(false);
                setEmailErr('');
                setOpen(true);
                setPayload({message: res?.data, type: 'success' });
            }).catch(err => {
                if (err?.response?.status === 422) {
                    let msg = err?.response?.data?.detail?.email[0];
                    setEmailErr(msg);
                } else {
                    setOpen(true);
                    setPayload({message: err?.response?.data?.message, type: 'error' });
                }
                setIsLoading(false);
            })
    })

    return (
        <>
            <div className='sign_in_page'>
                <Container maxWidth="100%" sx={{ pt: 2, pb: 2 }}>
                    <Box
                        component={Paper}
                        sx={{
                            maxWidth: "450px",
                            py: 4,
                            px: 4,
                        }}
                    >
                        <Typography
                            variant="h4"
                            component="h4"
                            sx={{
                                color: "#407BFF",
                                textAlign: "center",
                            }}
                        >
                            Forget Password
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{ padding: { sm: "75px 0", xs: "25px 0" } }}
                        >
                            <Stack direction="column" spacing={2}>
                                <InputField
                                    type="email"
                                    name="email"
                                    placeholder="Johndoe@gmail.com"
                                    size="small"
                                    labelTop="Email"
                                    fullWidth
                                    required
                                    error={emailErr}
                                    handleChange={(e) => setEmail(e.target.value)}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                spacing={2}
                                mt={1}
                            >
                                <Button
                                    variant="text"
                                    type="button"
                                    onClick={() => navigate('/sign-in')}
                                    style={{ color: "#FF0000", fontSize: "12px" }}
                                >
                                    Sign In
                                </Button>
                            </Stack>
                            <div style={{ marginTop: "35px" }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={isLoading}
                                    type="submit"
                                    style={{
                                        backgroundColor: "#333",
                                        borderRadius: "25px",
                                        textTransform: "none",
                                    }}
                                >
                                    {isLoading ? (
                                        <CircularProgress
                                            size={18}
                                            sx={{ color: "#fff", mr: 1, size: "12px" }}
                                        />
                                    ) : null}
                                    Submit
                                </Button>
                            </div>
                        </Box>
                    </Box>
                </Container>
            </div>
            <PopUpMsg
                open={open}
                type={payload.type}
                message={payload.message ?? "error occurred"}
                handleClose={() => setOpen(false)}
            />
        </>
    )
}
