import React from 'react'
import {
    Box,
    Container,
    Typography,
    Grid
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const OrderForm = () => {
    const min535px = useMediaQuery('(min-width:33.4375rem)');
    return (
        <Container maxWidth="100%" sx={{ paddingTop: "25px", paddingBottom: "105px" }}>
            <Box sx={{ px: { md: 5 } }}>
                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", mb: 5 }}>
                    <Typography component="h5" variant="h5" sx={{ fontSize: { xs: '1rem', md: "1.45rem" }, fontWeight: 500 }}>BESTELLFORMULAR</Typography>
                    <img width={min535px ? "154" : "100"} src={require('../../../../../assets/images/logo.png')}></img>
                </Box>
                <Grid container spacing={2}>
                    <Grid item lg={4}>
                        <Typography component="h5" sx={styles.head}>Timo Böhme</Typography>
                        <Typography component="p" sx={styles.body}>Algisbündtstrasse 9</Typography>
                        <Typography component="p" sx={styles.body}>9443 Widnau</Typography>
                        <Typography component="p" sx={styles.body}>Tel: 6+542156+586</Typography>
                        <Typography component="p" sx={styles.body}>info@tb-software.ch</Typography>
                    </Grid>
                    <Grid item lg={4}>
                        <Typography component="h5" sx={styles.head}>Ihr Kontakt für Rückfragen</Typography>
                        <Typography component="p" sx={styles.body}>Marcel Steiert</Typography>
                        <Typography component="p" sx={styles.body}>Wieslistrasse 15</Typography>
                        <Typography component="p" sx={styles.body}>9434 Au SG</Typography>
                        <Typography component="p" sx={styles.body}>Tel: +41794170600</Typography>
                        <Typography component="p" sx={styles.body}>marcel.steiert@gama-pv.ch</Typography>
                    </Grid>
                    <Grid item lg={4}>
                        <img style={{ borderRadius: 5, width: "100%", maxWidth: "237px" }} src={require("../../../../../assets/images/order-form.png")}></img>
                    </Grid>
                </Grid>
                <Typography sx={[styles.body, { marginTop: "2.7em" }]}>Bitte unterzeichnen Sie das Bestellformular und retournieren Sie es an uns:</Typography>
                <Grid container spacing={2} sx={{ mt: 5 }}>
                    <Grid item lg={4}>
                        <Typography component="h5" sx={styles.head}>Per E-Mail</Typography>
                        <Typography component="p" sx={styles.body}>marcel.steiert@gama-pv.ch</Typography>
                    </Grid>
                    <Grid item lg={4}>
                        <Typography component="h5" sx={styles.head}>Per Post</Typography>
                        <Typography component="p" sx={styles.body}>Gama PV</Typography>
                        <Typography component="p" sx={styles.body}>Wieslistrasse 15</Typography>
                        <Typography component="p" sx={styles.body}>9434 Au SG</Typography>
                    </Grid>
                </Grid>
                <Typography sx={[styles.body, { marginTop: "2.7em", mr: { lg: 5 } }]}>Hiermit bestelle ich gemäss dem Angebot vom 05.10.2022 die Variante "Photovoltaikanlage mit der Referenz gam-448 zum Gesamtbetrag inkl. MWST von 41'446.85 CHF (ohne Berücksichtigung Fördergelder)-</Typography>
                <Grid container sx={{ display: "flex", justifyContent: { sm: "flex-end", xs: "flex-start" }, mt: 8 }}>
                    <Grid item >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={[styles.head, styles.title]}>Datum:</Typography>
                            <Box sx={styles.hr}></Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={[styles.head, styles.title]}>Ort:</Typography>
                            <Box sx={styles.hr}></Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={[styles.head, styles.title]}>Unterschrift:</Typography>
                            <Box sx={styles.hr}></Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

const styles = {
    arrow: {
        width: 0,
        height: 0,
        display: 'inline-block',
        borderTop: '7px solid transparent',
        borderBottom: '7px solid transparent',
        borderLeft: '7px solid black',
        transform: 'rotate(221deg)',
        marginRight: '5px'
    },
    head: {
        fontSize: { md: "1.625rem", xs: "1.15rem" },
        color: "#000000",
        fontWeight: 500,
        marginBottom: "0.9em"
    },
    body: {
        fontSize: { md: "1.5rem", xs: "1.1rem" },
        color: "#464646",
        marginTop: "0.2em"
    },
    title: {
        mb: 0,
        mr: { sm: 4, xs: 1 },
        minWidth: { sm: "150px", xs: "100px" },
        textAlign: "left",
        fontSize: { xs: "1rem", md: "1.2rem" }
    },
    hr: {
        borderBottom: "1px solid #000000",
        width: "100%",
        minWidth: { md: "400px", xs: "200px" },
        height: "2px",
        display: "block"
    }
}

export default OrderForm