import React, { useState } from "react";


export default function AdHome() {
   
    return (
        <>
          <h1>Admin Home</h1>
        </>
    )
}
