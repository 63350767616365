import { useState, memo, useEffect } from "react";

function ApiImage({ url , styles }) {
  const TOKEN = localStorage.getItem("@ACCESS_TOKEN");
  const api = process.env.REACT_APP_API_KEY;
  const [img, setImg] = useState('https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg');
  const HEADER = {
    headers: { Authorization: `Bearer ${TOKEN}` },
  };
  useEffect(() => {
    fetch(api + url, HEADER)
      .then(async (res) => {
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImg(imageObjectURL);
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: fetch-image.js ~ line 28 ~ ApiImage ~ err",
          err
        );
      });
  }, [url]);
  return (
    <>
      <a href={img} target="_blank">
        <img src={img} style={styles}/>
      </a>
    </>
  );
}
const areEqual = (prevProps, nextProps) => {
  return prevProps.url === nextProps.url;
};
export default memo(ApiImage, areEqual);
