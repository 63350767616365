import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { openPopUp } from "../../../store/reducer";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Switch,
  Typography,
} from "@mui/material";
import { CustomModal, InputField } from "../../../components";
import API from "../../../axios";

export default function ComponentType({
  open,
  handleClose,
  getData = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    is_custom: true,
  });
  const [formErrors, setFormErrors] = React.useState({});

  const dispatch = useDispatch();

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
   try {
        const res = await API("admin/component-types", "post", formData)
        setIsLoading(false);
        dispatch(
          openPopUp({
            message: "Component type has been created.",
            type: "success",
          })
        );
        handleClose(false);
        getData();
      }
      catch(err) {
        let errObj = {};
        setFormData(errObj);
        if (err?.response?.status === 422) {
          for (const [key, value] of Object.entries(
            err?.response?.data?.detail
          )) {
            errObj = { ...errObj, [key]: value[0] };
          }
          setFormErrors(errObj);
        } else {
          let obj = {
            message: err?.response?.message,
          };
          dispatch(openPopUp(obj));
        }
        setIsLoading(false);
      };
  };
  return (
    <>
      <CustomModal
        open={open}
        handleClose={() => {
          handleClose();
        }}
      >
        <Container maxWidth="100%">
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
              Create Component Type
            </Typography>
          </Box>
          <form autoComplete="off" onSubmit={handleForm}>
            <Box mt={2} mb={3}>
              <InputField
                placeholder="Title"
                size="small"
                label="Title"
                required
                fullWidth
                styles={{ mb: 2 }}
                name="title"
                error={formErrors.title}
                handleChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
              <Box>
                <label>Custom</label>
                <br />
                <small>No</small>
                <Switch
                  defaultChecked
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      is_custom: e.target.checked ? 1 : 0,
                    })
                  }
                  color="success"
                />
                <small>Yes</small>
              </Box>
              <Box sx={{ textAlign: "end" }}>
                <Button
                  variant="contained"
                  onClick={() => {}}
                  sx={{
                    mt: 2,
                  }}
                  disabled={isLoading}
                  type="submit"
                >
                  {isLoading ? (
                    <CircularProgress
                      size={18}
                      sx={{ color: "#fff", mr: 1, size: "12px" }}
                    />
                  ) : null}
                  Create
                </Button>
              </Box>
            </Box>
          </form>
        </Container>
      </CustomModal>
    </>
  );
}

