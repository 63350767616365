import React, { useState } from "react";

import { Box, Button, CircularProgress } from "@mui/material";

import { InputField, PopUpMsg, SelectBox } from "../../../components";
import API from "../../../axios";

const generateKey = () => {
  return Math.ceil(Math.random() * 100000);
};

export default function AddLabelForm({ handleSubmission = () => {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    key: "",
    english: "",
    german: "",
    other: "",
    role: "",
  });

  const [update, setUpdate] = useState({
    key: generateKey(),
    eng: generateKey(),
    ge: generateKey(),
    ot: generateKey(),
  });

  const [payload, setPayload] = useState({
    vairant: "success",
    message: "",
  });
  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const reset = () => {
    setFormData({
      key: "",
      english: "",
      german: "",
      other: "",
      role: "",
    });
    setUpdate({
      key: generateKey(),
      eng: generateKey(),
      ge: generateKey(),
      ot: generateKey(),
    });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    let data = formData;
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }
    setIsLoading(true);
    try {
      const res = await API("admin/translation", "post", data);
      setIsLoading(false);
      setOpen(true);
      setPayload({
        vairant: "success",
        message: "Key has been added successfully!",
      });
      reset();
    } catch (err) {
      setIsLoading(false);
      setOpen(true);
      setPayload({
        vairant: "error",
        message: "Error Occured. Please try again!",
      });
    }
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleForm}>
        <Box mt={2} mb={3}>
          <InputField
            key={update.key}
            placeholder="Type..."
            size="small"
            label="Key"
            name="key"
            required
            fullWidth
            styles={{ mb: 2 }}
            handleChange={handleChange}
          />
          <InputField
            key={update.eng}
            placeholder="Type..."
            size="small"
            label="English (en)"
            name="english"
            multiline
            required
            fullWidth
            styles={{ mb: 2 }}
            handleChange={handleChange}
          />
          <InputField
            key={update.ge}
            placeholder="Type..."
            size="small"
            label="German (de)"
            name="german"
            required
            fullWidth
            multiline
            styles={{ mb: 2 }}
            handleChange={handleChange}
          />
          <InputField
            key={update.ot}
            placeholder="Type..."
            size="small"
            label="Other lang"
            name="other"
            fullWidth
            multiline
            styles={{ mb: 2 }}
            handleChange={handleChange}
          />
          <SelectBox
            size="small"
            label="Role"
            name="role"
            handleChange={handleChange}
            items={[
              { label: "All", value: "" },
              { label: "Super Admin", value: "superadmin" },
              { label: "Manager", value: "manager" },
              { label: "Employee", value: "employee" },
            ]}
          />
          <Box sx={{ textAlign: "end" }}>
            <Button
              variant="contained"
              onClick={() => {}}
              sx={{
                mt: 2,
              }}
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <CircularProgress
                  size={18}
                  sx={{ color: "#fff", mr: 1, size: "12px" }}
                />
              ) : null}
              Send
            </Button>
          </Box>
        </Box>
      </form>
      <PopUpMsg
        open={open}
        type={payload.vairant}
        message={payload.message}
        handleClose={() => setOpen(false)}
      />
    </>
  );
}
