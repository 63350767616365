import React from "react";
import {
    useMediaQuery,
    Box,
    Stack,
    Button,
    IconButton,
    Typography,
    Modal,
    CircularProgress,
    Backdrop,
    Snackbar,
    Grid,
    Tooltip,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Divider
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

import { InputField, SelectBox } from "../../../../components";

export default function NewComponentForm({
    open = false,
    handleClose = () => { } }) {
    const [openDrop, setOpenDrop] = React.useState(false);
    const [showMessage, setShowMessage] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const matches = useMediaQuery('(min-width:900px)');

    const handleForm = (e) => {
        e.preventDefault();
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflowY: 'auto',
                }}
            >
                <Box sx={matches ? modalStyle : modalStyle2}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <IconButton
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography variant="h6" component="h5">
                        ADD NEW COMPONENT
                    </Typography>
                    <Box component="small" sx={{color:'#969696'}}>
                        Select the manufacturer and type of component you want
                    </Box>
                    {/* Form */}
                    <Box
                        component='form'
                        noValidate
                        autoComplete="off"
                        onSubmit={handleForm}
                        sx={{ mt: 6, mb: 4 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item md={4} xs={12}>
                                <SelectBox
                                    items={['other component','battery storage','energy manager','Photovoltaikmodul','substructure','inverter','recurring costs']}
                                    size='small'
                                    label='component type'
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputField
                                    size='small'
                                    placeholder='Name'
                                    label='Name'
                                    error=''
                                    required
                                    fullWidth
                                    handleChange={() => { }}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputField
                                    size='small'
                                    placeholder='item number'
                                    label='Artikelnummer (optional)'
                                    error=''
                                    fullWidth
                                    handleChange={() => { }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    size='small'
                                    placeholder='short description'
                                    label='Beschreibung (optional)'
                                    error=''
                                    multiline
                                    rows={4}
                                    fullWidth
                                    handleChange={() => { }}
                                />
                            </Grid>
                        </Grid>
                        <Stack
                            direction='row'
                            spacing={4}
                            alignItems='center'
                            mt={4}
                        >
                            <Typography variant="h6" component="h5">
                                Technology Affiliation
                            </Typography>
                            <Tooltip title='Info'>
                                <InfoIcon sx={{ opacity: 0.5 }} />
                            </Tooltip>


                        </Stack>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked size='small' />} label="battery storage" />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked size='small' />} label="energy management" />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked size='small' />} label="installation costs" />
                        </FormGroup>
                        <Stack
                            direction='row'
                            spacing={1}
                            mt={7}
                        >
                            <Button
                                type="submit"
                                sx={{
                                    backgroundColor: '#000',
                                    '&:hover': {
                                        backgroundColor: '#000',
                                    },
                                    ...btn
                                }}
                            >
                                Add to
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#D71B1B',
                                    '&:hover': {
                                        backgroundColor: '#D71B1B',
                                    },
                                    ...btn
                                }}
                                onClick={handleClose}
                            >
                                Abort
                            </Button>
                        </Stack>
                    </Box>
                    <Divider />
                    <Typography variant="h6" component="h6" mt={3}>
                        CAN'T FIND THE COMPONENTS YOU ARE LOOKING FOR?
                    </Typography>
                    <Box component="small" sx={{color:'#969696'}}>
                        Fordern Sie die Registrierung von Komponenten an, indem Sie auf die Schaltfläche unten klicken und uns eine E-Mail an <a href="#">support@eturnity.com</a> senden.
                    </Box>
                </Box>
            </Modal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999999 }}
                open={openDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={showMessage}
                autoHideDuration={6000}
                onClose={() => setShowMessage(false)}
                message={message}
            />
        </>
    );
}

const btn = {
    color: '#fff',
    textTransform: 'none',
    paddingLeft: '25px',
    paddingRight: '25px',
}
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '800px',
    bgcolor: '#fff',
    border: '0',
    borderRadius: '7px',
    boxShadow: 24,
    p: 4,
    zIndex: 100
};

const modalStyle2 = {
    width: '100%',
    maxWidth: '100%',
    bgcolor: '#fff',
    border: '0',
    borderRadius: '7px',
    boxShadow: 24,
    p: 4,
    zIndex: 100
};