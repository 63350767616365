import React, { useState, useEffect, Fragment } from "react";

import {
  Container,
  Button,
  Box,
  CircularProgress,
  Switch,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { InputField, PopUpMsg } from "../../../components";
import { useDispatch } from "react-redux";
import { openPopUp } from "../../../store/reducer";
import API from "../../../axios";

export default function UpdateOrganization() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useLocation().state.id;
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    status: true,
    _method: "PATCH",
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [open, setOpen] = useState(false);
  const [payload, setPayload] = useState({
    type: "error",
    message: "",
  });
  useEffect(() => {
    getOrganization();
  }, []);
  const getOrganization = async () => {
    try {
      const res = await API(`admin/organizations/${id}`, "get");
      let data = res?.data;
      setFormData({
        ...formData,
        title: data?.title,
        description: data?.description,
        status: data?.active,
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setOpen(true);
      setPayload({ ...payload, message: err?.response?.message });
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name == "status") {
      setFormData({ ...formData, [name]: formData.status ? 0 : 1 });
    } else setFormData({ ...formData, [name]: value });
  };
  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let errObj = {};
    setFormErrors({});
    try {
      const res = await API(`admin/organizations/${id}`, "post", formData);
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: "Organization has been updated.",
          type: "success",
        })
      );
      navigate("/admin/organization");
    } catch (err) {
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        setOpen(true);
        setPayload({ ...payload, message: err?.response?.message });
      }
      setIsLoading(false);
    }
  };
  var inputFields = [
    {
      placeholder: "Title",
      label: "Title",
      name: "title",
      value: formData.title,
      error: formErrors.title,
      type: "text",
      required: true,
    },
    {
      placeholder: "Description",
      name: "description",
      label: "Description",
      error: formErrors.description,
      value: formData.description,
      type: "text_area",
      required: true,
    },
  ];
  return (
    <Container maxWidth="100%" sx={{ py: 3 }}>
      <form autoComplete="off" onSubmit={handleForm}>
        <Box mt={2} mb={3}>
          <Typography variant="h6" component="h6" mb={2}>
            Organization Info
          </Typography>
          {inputFields.map((input, index) => {
            return (
              <Fragment key={index}>
                <InputField
                  placeholder={input.placeholder}
                  label={input.label}
                  name={input.name}
                  value={input.value}
                  error={input.error}
                  required={input.required}
                  size="small"
                  fullWidth
                  styles={{ mb: 2 }}
                  multiline={input.type === "text_area"}
                  handleChange={handleChange}
                />
              </Fragment>
            );
          })}
          <Box>
            <label>Status</label>
            <br />
            <small>Disabled</small>
            <Switch
              name="status"
              checked={formData.status}
              error={formErrors.status}
              value={formData.status}
              onChange={handleChange}
              color="success"
            />
            <small>Active</small>
          </Box>
          <Box sx={{ textAlign: "end" }}>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                mr: 1,
              }}
              onClick={() => navigate("/admin/organization")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {}}
              sx={{
                mt: 2,
              }}
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <CircularProgress
                  size={18}
                  sx={{ color: "#fff", mr: 1, size: "12px" }}
                />
              ) : null}
              Update
            </Button>
          </Box>
        </Box>
      </form>
      <PopUpMsg
        open={open}
        type={payload.type}
        message={payload.message ?? "error occurred"}
        handleClose={() => setOpen(false)}
      />
    </Container>
  );
}
