import React, { useState } from "react";

import PageTitle from "../../../../hooks/page-title";
import LibraryLayout from "../library-layout";
import ViewMainTemplatePanel from "./view-main-template-panel";
import EditMainTemplatePanel from "./edit-main-template-panel";

export default function MainTemplatePanel() {
    PageTitle('Main Template');
    const [showEdit, setShowEdit] = useState(false);

    const switchScreen = () => setShowEdit(!showEdit)

    return (
        <LibraryLayout>
            {showEdit ?
                <EditMainTemplatePanel switchScreen={switchScreen} />
                :
                <ViewMainTemplatePanel switchScreen={switchScreen} />
            }
        </LibraryLayout>
    )
}