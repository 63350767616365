import React, { useState, useEffect } from "react";

import {
  Container,
  Button,
  Box,
  TableRow,
  TableCell,
  Typography,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";

import API from "../../../axios";
import { TableWrapper, UsePagination } from "../../../components";
import CreateModel from "./create-model";
import UpdateModel from "./update-model";

export default function GetModelsInfo() {
  const [isLoading, setIsLoading] = useState(false);
  const [modelsInfo, setModels] = useState(null);
  const [modelId, setModelId] = useState(0);
  const [id, setId] = useState(0);
  const [page, setPage] = useState(1);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  useEffect(() => {
    GetModelInfo();
  }, [page]);
  const GetModelInfo = async () => {
    setIsLoading(true);
    try {
      const res = await API(`admin/modalInformations?page=${page}`, "get");
      setModels(res.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setModels(null);
    }
  };
  var tableCols = [
    "Group",
    "Model",
    "Language",
    "Label",
    "value",
    "Status",
    "Action",
  ];

  return (
    <>
      <Container maxWidth="100%" sx={{ py: 3 }}>
        <Typography variant="h6">Models</Typography>
        <Box textAlign="end" mt={2} mb={2}> 
          <Button variant="contained" onClick={() => setCreateModal(true)}>
            Create
          </Button>
        </Box>
        <TableWrapper
          thContent={tableCols.map((column, index) => (
            <TableCell key={index}>{column}</TableCell>
          ))}
          spanTd={tableCols.length}
          isLoading={isLoading}
          isContent={!!modelsInfo && modelsInfo?.data.length ? true : false}
          tableStyle={{minWidth:"400px"}}
        >
          {!!modelsInfo &&
             modelsInfo?.data.map((modelsInfo, index) => (
              <TableRow key={index}>
                <TableCell>{modelsInfo.group}</TableCell>
                <TableCell>{modelsInfo.modals?.title}</TableCell>
                <TableCell>{modelsInfo.language}</TableCell>
                <TableCell>{modelsInfo.label}</TableCell>
                <TableCell>{modelsInfo.value}</TableCell>
                <TableCell>
                  <Chip
                    label={modelsInfo.active ? "active" : "disabled"}
                    color={modelsInfo.active ? "success" : "error"}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={() => {
                        setUpdateModal(true);
                        setId(modelsInfo.id);
                        setModelId(modelsInfo.modal_id);
                      }}
                      color="primary"
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableWrapper>
        {!!modelsInfo && modelsInfo?.last_page > 1 && (
          <Box component="div" sx={{ mt: 2 }}>
            <UsePagination
              total={modelsInfo?.total}
              perPage={modelsInfo?.per_page}
              page={page}
              setPage={setPage}
            />
          </Box>
        )}
      </Container>
      {createModal && (
        <CreateModel
          handleClose={() => setCreateModal(false)}
          getData={GetModelInfo}
        />
      )}
      {updateModal && (
        <UpdateModel
          id={id}
          setId={setId}
          modelId={modelId}
          setModelId={setModelId}
          handleClose={() => setUpdateModal(false)}
          getData={GetModelInfo}
        />
      )}
    </>
  );
}
