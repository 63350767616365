import React from "react";
import {
    Box,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Stack,
    Divider,
    Button,
    Tooltip,
    IconButton
} from "@mui/material";

import SettingsIcon from '@mui/icons-material/Settings';
import { InputField, SelectBox, StartIconBtn } from "../../../../components";
import InfoIcon from '@mui/icons-material/Info';

export default function EditCalculatorPanel({ switchScreen, ...props }) {

    return (
        <Box>
            <Box
            >
                <Stack
                    direction="row"
                    alignItems='center'
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                >
                    <Button
                        sx={{ textTransform: 'none' }}
                        onClick={() => switchScreen()}
                    >
                        Back
                    </Button>
                    <Typography
                        variant='p'
                        component='p'
                        sx={{ fontSize: '13px' }}
                    >
                        Photovoltaic
                    </Typography>
                </Stack>
            </Box>
            <Box sx={{ pt: 4, pb: 2 }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography
                        variant='h6'
                        component='h6'
                    >
                        SOLAR COMPUTER SYSTEM BATTERY RETORFIT
                    </Typography>
                    <IconButton
                        sx={{
                            backgroundColor: '#000',
                            '&:hover': {
                                backgroundColor: '#ccc',
                            }
                        }}
                        size='small'
                        onClick={() => { }}
                    >
                        <SettingsIcon sx={{ color: '#fff' }} />
                    </IconButton>
                    <Tooltip title="information">
                        <InfoIcon sx={{ opacity: 0.5 }} />
                    </Tooltip>
                </Stack>
                <Box sx={{ borderBottom: '1px solid #ccc', mt: 2 }}>
                    <Typography
                        variant='p'
                        component='p'
                        sx={{ borderBottom: '1px solid', pb: 1, borderColor: 'primary.main', width: 'fit-content' }}
                    >
                        DEUTCH (CH)
                    </Typography>
                </Box>
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <Typography
                            variant='h6'
                            component='h6'
                            sx={{ mb: 2 }}
                        >
                            SYSTEM WITH BATTERY
                        </Typography>
                        <Box sx={{ maxWidth: '500px' }}>
                            <Stack spacing={2}>
                                <InputField
                                    placeholder='Battery retrofit system'
                                    label='Name'
                                    error=''
                                    size="small"
                                    required
                                    fullWidth
                                    handleChange={() => { }}
                                />
                                <InputField
                                    placeholder='Description of the battery retrofit system'
                                    label='description'
                                    error=''
                                    size="small"
                                    required
                                    multiline
                                    fullWidth
                                    rows={4}
                                    handleChange={() => { }}
                                />
                            </Stack>
                        </Box>
                        <Divider sx={{ mt: 4, mb: 2 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant='h6'
                            component='h6'
                            sx={{ mb: 2 }}
                        >
                            SYSTEM WITHOUT BATTERY
                        </Typography>
                        <Box sx={{ maxWidth: '500px' }}>
                            <Stack spacing={2}>
                                <InputField
                                    placeholder='Name'
                                    label='Name'
                                    error=''
                                    size="small"
                                    required
                                    fullWidth
                                    handleChange={() => { }}
                                />
                                <InputField
                                    placeholder='Description of the battery retrofit system'
                                    label='description'
                                    error=''
                                    size="small"
                                    required
                                    multiline
                                    fullWidth
                                    rows={4}
                                    handleChange={() => { }}
                                />
                            </Stack>
                        </Box>
                        <Typography
                            variant='h6'
                            component='h6'
                            sx={{ mt: 2 }}
                        >
                            BATTERY MEMORY
                        </Typography>
                        <Typography
                            variant='p'
                            component='p'
                            sx={{ mt: 2, mb: 2 }}
                        >
                            battery storage
                        </Typography>
                        <Box sx={{ maxWidth: '100%' }}>
                            <Stack direction={{ sm: 'row', xs: 'column' }}
                                sx={{ '& > div': { flexGrow: 1 } }}
                                spacing={2}>
                                <InputField
                                    placeholder='photovoltaic system'
                                    error=''
                                    size="small"
                                    required
                                    fullWidth
                                    initValue={'SolarEdge'}
                                    handleChange={() => { }}
                                    styles={{ flexGrow: 1 }}
                                />
                                <InputField
                                    placeholder='photovoltaic system'
                                    error=''
                                    size="small"
                                    required
                                    initValue={'SE7K'}
                                    fullWidth
                                    handleChange={() => { }}
                                />
                                <SelectBox
                                    items={['1', '2', '2', '3', '4', '5', '6']}
                                    size='small'
                                    label='value'
                                    required
                                    fullWidth
                                />
                                <InputField
                                    error=''
                                    label='Name'
                                    size="small"
                                    required
                                    initValue='1'
                                    fullWidth
                                    handleChange={() => { }}
                                />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant='h6'
                            component='h6'
                            sx={{ mt: 3, mb: 2 }}
                        >
                            OTHER COMPONENT
                        </Typography>
                        <TableContainer>
                            <Table
                                sx={{
                                    minWidth: 650,
                                    '& .MuiTableCell-root': {
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }
                                }}
                            >
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            backgroundColor: "#E6F5FE",
                                        }}
                                    >
                                        <TableCell>components</TableCell>
                                        <TableCell>item number</TableCell>
                                        <TableCell>group</TableCell>
                                        <TableCell>Crowd</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan='4' sx={{ textAlign: 'center' }}>No components found -</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack
                            direction={{ sm: 'row', xs: 'column' }}
                            spacing={2}
                            sx={{ mt: 2 }}
                        >
                            <StartIconBtn
                                title='add component'
                            />
                            <StartIconBtn
                                title='add from group'
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant='h6'
                            component='h6'
                            sx={{ mt: 3, mb: 2 }}
                        >
                            RECURRING COSTS
                        </Typography>
                        <TableContainer>
                            <Table
                                sx={{
                                    minWidth: 650,
                                    '& .MuiTableCell-root': {
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }
                                }}
                            >
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            backgroundColor: "#E6F5FE",
                                        }}
                                    >
                                        <TableCell>components</TableCell>
                                        <TableCell>item number</TableCell>
                                        <TableCell>group</TableCell>
                                        <TableCell>Crowd</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan='4' sx={{ textAlign: 'center' }}>No components found -</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <StartIconBtn
                            title='Add RECURRING COSTS'
                            styles={{ mt: 2 }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}