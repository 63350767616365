import React from 'react'
import {
    Box,
    Container,
    Typography,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import PageTitle from '../../../hooks/page-title';

export default function Customer() {
    PageTitle('Customer');

    const [formData, setFormData] = React.useState({
        customer_type: 'firma',
        gender: 'herr',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        language: 'de',
        address: '',
        project_template: 'template1',
    });

    const [formError, setFormError] = React.useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
    });

    return (
        <Container
            maxWidth="100%"
            sx={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
            <Typography
                variant="h5"
                component="h5"
                fontSize={"18px"}
                mt={"12px"}
                mb={"10px"}
            >
                Kunde
            </Typography>
            <Typography
                variant="p"
                component="p"
                sx={{
                    color: "#969696",
                }}
            >
                Ändern Sie die Kundendetails
            </Typography>
            <Box
                component='form'
                noValidate
                autoComplete="off"
            >
                <Typography variant="subtitle1" component="h5"
                    mt={3}
                    sx={{
                        color: '#464646'
                    }}
                >
                    Kundentyp*
                </Typography>
                <FormControl>
                    <RadioGroup
                        row
                        name="controlled-radio-buttons-group">
                        <FormControlLabel value="Privat" control={<Radio
                            checked={formData.customer_type === 'privat' ? true : false}
                            onChange={() => setFormData({ ...formData, customer_type: 'privat' })}
                            sx={rdBtnStyles} size="small" />} label="Privat" />
                        <FormControlLabel value="Firma"
                            control={<Radio
                                checked={formData.customer_type === 'firma' ? true : false}
                                onChange={() => setFormData({ ...formData, customer_type: 'firma' })}
                                sx={rdBtnStyles} size="small" />} label="Firma" />
                    </RadioGroup>
                </FormControl>
                <Typography variant="subtitle1" component="h5"
                    mt={2}
                    sx={{
                        color: '#464646'
                    }}>
                    Geschlecht*
                </Typography>
                <FormControl>
                    <RadioGroup
                        row
                        name="controlled-radio-buttons-group">
                        <FormControlLabel value="herr" control={<Radio
                            checked={formData.gender === 'herr' ? true : false}
                            onChange={() => setFormData({ ...formData, gender: 'herr' })}
                            sx={rdBtnStyles} size="small" />} label="Herr" />
                        <FormControlLabel value="frau"
                            control={<Radio
                                checked={formData.gender === 'frau' ? true : false}
                                onChange={() => setFormData({ ...formData, gender: 'frau' })}
                                sx={rdBtnStyles} size="small" />} label="Frau" />
                        <FormControlLabel value="keine_angabe"
                            control={<Radio
                                checked={formData.gender === 'keine_angabe' ? true : false}
                                onChange={() => setFormData({ ...formData, gender: 'keine_angabe' })}
                                sx={rdBtnStyles} size="small" />} label="Keine Angabe" />
                    </RadioGroup>
                </FormControl>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" component="h5"
                            mt={1}
                            sx={{
                                color: '#464646'
                            }}>
                            Vorname*
                        </Typography>
                        <TextField
                            value={formData.first_name}
                            required
                            fullWidth
                            error={formError.first_name}
                            onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                            id="outlined-basic" variant="outlined" placeholder="Aka|"
                            sx={inputStyle}
                            helperText={formError.first_name}
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" component="h5"
                            mt={1}
                            sx={{
                                color: '#464646'
                            }}>
                            Nachname*
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            value={formData.last_name}
                            onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                            id="outlined-basic" variant="outlined" placeholder="Nachname"
                            sx={inputStyle}
                            error={formError.last_name}
                            helperText={formError.last_name}
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" component="h5"
                            mt={1}
                            sx={{
                                color: '#464646'
                            }}>
                            Sprache*
                        </Typography>
                        <TextField
                            id="outlined-select-currency-native"
                            select
                            SelectProps={{
                                native: true,
                            }}
                            sx={inputStyle}
                            value={formData.language}
                            onChange={(e) => setFormData({ ...formData, language: e.target.value })}
                        >
                            {[{ label: 'German', key: 'de' },
                            { label: 'English', key: 'en' }].map((v, i) => (
                                <option key={i} value={v.key}>
                                    {v.label}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" component="h5"
                            mt={2}
                            sx={{
                                color: '#464646'
                            }}>
                            Email (Optional)
                        </Typography>
                        <TextField
                            value={formData.email}
                            fullWidth
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            type='email'
                            id="outlined-basic" variant="outlined" placeholder="doe@gmail.com"
                            sx={inputStyle}
                            error={formError.email}
                            helperText={formError.email}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} >
                        <Typography variant="subtitle1" component="h5"
                            mt={2}
                            sx={{
                                color: '#464646'
                            }}>
                            Telefonnumber (Optional)
                        </Typography>
                        <TextField
                            value={formData.phone}
                            fullWidth
                            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                            id="outlined-basic" variant="outlined"
                            placeholder="E.g +41 11 111 11 11"
                            sx={inputStyle}
                            error={formError.phone}
                            helperText={formError.phone}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12} >
                        <Typography variant="subtitle1" component="h5"
                            mt={2}
                            sx={{
                                color: '#464646',

                            }}>
                            Gebäudeadresse*
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            value={formData.address}
                            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                            id="outlined-basic" variant="outlined" placeholder="Addrese"
                            sx={[inputStyle, { width: '100%' }]}
                            error={formError.address}
                            helperText={formError.address}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

const inputStyle = {
    mt: 1,
    width: '100%',
    '& .MuiInputBase-input': {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    "& .Mui-focused fieldset": {
        borderColor: "#1B9607 !important",
    },
    '& .MuiNativeSelect-select': {
        paddingTop: '10px',
        paddingBottom: '10px',
    }
}

const rdBtnStyles = {
    color: '#1B9607',
    '&.Mui-checked': {
        color: '#1B9607',
    },
}