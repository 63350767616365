import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { openPopUp } from "../../../store/reducer";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Switch,
  Typography,

} from "@mui/material";

import { CustomModal, InputField, SearchComboBox,  } from "../../../components";
import API from "../../../axios";

const modalInfoFields = [
  {
    label: "Group",
    name: "group",
  },
  {
    label: "Language",
    name: "language",
  },
  {
    label: "Label",
    name: "label",
  },
  {
    label: "Value",
    name: "value",
  },
  {
    label: "Description",
    name: "description",
  },
];

export default function UpdateModelInfo({
  id,
  setId,
  modelId,
  setModelId,
  handleClose,
  getData = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);

  const [formData, setFormData] = useState({
    modal_id: 0,
    modal: '',
    group: "",
    language: "",
    label: "",
    value: "",
    description: "",
    status: true,
    _method: "patch",
  });

  const [formErrors, setFormErrors] = React.useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    getModelsInfo();
  }, []);


  const getModelsInfo = async () => {
    setGetLoading(true);
    try {
      const res = await API(`admin/modalInformations/${id}`, "get");
      console.log("🚀 ~ file: update-model.js:77 ~ getModelsInfo ~ res", res)
      setFormData({
        ...formData,
        group: res?.data?.group,
        language: res?.data?.language,
        label: res?.data?.label,
        value: res?.data?.value,
        description: res?.data?.description,
        status: res?.data?.active,
        modal_id: res?.data?.modal_id,
        modal: res?.data?.modals?.title,
      });
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      let obj = {
        message: err?.response?.message,
        type: "error",
      };
      dispatch(openPopUp(obj));
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let errObj = {};
    let obj = {};
    setFormErrors(errObj);
    try {
     await API(
        `admin/modalInformations/${id}`,
        "post",
        formData
      );
      setIsLoading(false);
      obj = {
        message: "Model has been updated.",
        type: "success",
      };
      dispatch(openPopUp(obj));
      getData();
      handleClose(false);
    } catch (err) {
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        obj = {
          message: err?.response?.message,
          type: "error",
        };
        dispatch(openPopUp(obj));
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        handleClose={() => {
          handleClose();
          setId(null);
          setModelId(null);
        }}
      >
        <Container maxWidth="100%" >
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
              Update Model Info
            </Typography>
          </Box>
          {getLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={30} />
            </Box>
          ) : (
            <form autoComplete="off" onSubmit={handleForm}>
              <Box mt={2} mb={3}>
                 <SearchComboBox
                initValue={formData.modal}
                url={"admin/modals?active=active&"}
                fullWidth
                required={false}
                objkey="id"
                objLabel="title"
                size="small"
                handleChange={(e) => {
                  setFormData({
                    ...formData,
                    modal: e.title,
                    modal_id: e.id,
                  });
                }}
                label="Model"
                styles={{ mb: 2 }}
              />
                {modalInfoFields.map((field, index) => {
                  return (
                    <Fragment key={index}>
                      <InputField
                        placeholder={field.label}
                        size="small"
                        label={field.label}
                        value={formData?.[field.name]}
                        name={field.name}
                        error={formErrors?.[field.name]}
                        required
                        fullWidth
                        styles={{ mb: 2 }}
                        handleChange={(e) =>
                          setFormData({
                            ...formData,
                            [field.name]: e.target.value,
                          })
                        }
                      />
                    </Fragment>
                  );
                })}
                <Box mt={2}>
                  <label>Status</label>
                  <br />
                  <small>Disabled</small>
                  <Switch
                    checked={formData.status}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        status: e.target.checked ? true : false,
                      })
                    }
                    color="success"
                  />
                  <small>Active</small>
                </Box>
                <Box sx={{ textAlign: "end" }}>
                  <Button
                    variant="contained"
                    onClick={() => {}}
                    sx={{
                      mt: 2,
                    }}
                    disabled={isLoading}
                    type="submit"
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={18}
                        sx={{ color: "#fff", mr: 1, size: "12px" }}
                      />
                    ) : null}
                    Update
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Container>
      </CustomModal>
    
    </>
  );
}
