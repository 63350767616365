import React from 'react'
import {
    Box,
    Container,
    Typography,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import useMediaQuery from '@mui/material/useMediaQuery';

const InstallationProcedure = () => {
    const min535px = useMediaQuery('(min-width:33.4375rem)');
    return (
        <Container maxWidth="100%" sx={{ paddingTop: "25px", paddingBottom: "105px" }}>
            <Box sx={{ px: { md: 4 } }}>
                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                    <Typography component="h5" variant="h5" sx={{ fontSize: { xs: '0.9rem', sm: "1.35rem" }, fontWeight: 500, }}>INSTALLATIONSABLAUF</Typography>
                    <img width={min535px ? "154" : "100"} src={require('../../../../../assets/images/logo.png')}></img>
                </Box>
                <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
                    <Box component="div" sx={styles.wrapper}>
                        <Boxitem text="1" bg="#929292" />
                        <Box sx={{ ml: 3 }}>
                            <Box sx={{ display: 'flex' }}>
                                <span style={styles.arrow}></span>
                                <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', md: "1.3rem" }, fontWeight: 500 }}>PERSÖNLICHE OFFERTE STUDIEREN</Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "flex-start", ml: 1, mt: 2 }}>
                                <CreateIcon sx={{ background: "#000000", color: "#fff", borderRadius: "5px", fontSize: "1.2rem", px: 0.4 }} />
                                <Typography component="p" sx={{ ml: 1, maxWidth: { lg: "550px" } }}>Nehmen Sie sich Zeit und prüfen Sie Ihre Persönliche Offerte. Bei Fragen sind wir gerne für Sie da.</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box component="div" sx={styles.wrapper}>
                        <Boxitem text="2" bg="#4c4c4c" />
                        <Box sx={{ ml: 3 }}>
                            <Box sx={{ display: 'flex' }}>
                                <span style={styles.arrow}></span>
                                <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', md: "1.3rem" }, fontWeight: 500 }}>BESTELLEN</Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "flex-start", ml: 1, mt: 2 }}>
                                <CreateIcon sx={{ background: "#000000", color: "#fff", borderRadius: "5px", fontSize: "1.2rem", px: 0.4 }} />
                                <Typography component="p" sx={{ ml: 1, maxWidth: { lg: "550px" } }}>Wenn Sie sich von unserem Angebot überzeugt haben, können Sie die Bestellung auslösen.</Typography>
                            </Box>
                            <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', md: "1.05rem" }, fontWeight: 500, mt: 3.4 }}>Jetzt Anlage bestellen:</Typography>
                            <Box sx={{ mt: 3, display: "inline-flex", flexDirection: { md: "row", xs: "column" } }} >
                                <Box component="a" href="mailto: marcel.steiert@gama-pv.ch" sx={styles.link}>
                                    <MailIcon />
                                    <Typography component="p" sx={{ ml: 2, fontSize: { xs: "0.8rem", sm: "1rem" } }}>marcel.steiert@gama-pv.ch</Typography>
                                </Box>
                                <Box component="a" href="tel: +41794170600" sx={[styles.link, { ml: { md: 2 }, mt: { xs: 2, md: 0 } }]}>
                                    <CallIcon />
                                    <Typography component="p" sx={{ ml: 2, fontSize: { xs: "0.8rem", sm: "1rem" } }}>+41794170600</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box component="div" sx={styles.wrapper}>
                        <Boxitem text="3" bg="#929292" />
                        <Box sx={{ ml: 3 }}>
                            <Box sx={{ display: 'flex' }}>
                                <span style={styles.arrow}></span>
                                <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', md: "1.3rem" }, fontWeight: 500 }}>PERSÖNLICHE OFFERTE STUDIEREN</Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "flex-start", ml: 1, mt: 2 }}>
                                <CreateIcon sx={{ background: "#000000", color: "#fff", borderRadius: "5px", fontSize: "1.2rem", px: 0.4 }} />
                                <Typography component="p" sx={{ ml: 1, maxWidth: { lg: "550px" } }}>In wenigen Woche ist Ihre Anlage installiert und betriebsbereit.</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

const Boxitem = ({ text, bg }) => {
    return (
        <Box sx={[styles.box, { background: bg }]}>{text}</Box>
    )
}

const styles = {
    arrow: {
        width: 0,
        height: 0,
        display: 'inline-block',
        borderTop: '7px solid transparent',
        borderBottom: '7px solid transparent',
        borderLeft: '7px solid black',
        transform: 'rotate(221deg)',
        marginRight: '5px'
    },
    box: {
        display: "block",
        background: "black",
        color: "#fff",
        paddingLeft: "15px",
        paddingRight: "15px",
        fontWeight: 500,
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    wrapper: {
        mt: 3,
        display: "inline-flex",
        alignItems: "flex-start",
        paddingTop: "3.0375rem",
        paddingBottom: "3.0375rem",
        borderTop: "1px solid #B5B5B5"
    },
    link: {
        display: "inline-flex",
        alignItems: "center",
        textDecoration: "none",
        color: "#fff",
        background: "#0874B1",
        px: 2,
        py: 1,
        borderRadius: "3px"
    }
}

export default InstallationProcedure