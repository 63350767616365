import React, { useState } from "react";
import {
    Box,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@mui/material";

import { FilterBar2 } from "../../../../components";

export default function ViewProjectTemplatePanel({ switchScreen, ...props }) {
    
    return (
        <Box sx={{ pt: 2, pb: 2 }}>
            <FilterBar2 />
            <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item md={8} sm={10} xs={12}>
                    <TableContainer>
                        <Table
                            sx={{
                                minWidth: 650,
                                '& .MuiTableCell-root': {
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                }
                            }}
                        >
                            <TableHead>
                                <TableRow
                                    sx={{
                                        backgroundColor: "#E6F5FE",
                                    }}
                                >
                                    <TableCell>Name</TableCell>
                                    <TableCell>language</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <button
                                            style={{ backgroundColor: "transparent", border: 'none', cursor: 'pointer' }}
                                            onClick={() => switchScreen()}
                                        >
                                            PV system standard
                                        </button>
                                    </TableCell>
                                    <TableCell>GERMAN (CH)</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    )
}