import React from "react";
import {
  Box,
  Container,
  Paper,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Button,
  CircularProgress,
} from "@mui/material";
import PageTitle from "../../hooks/page-title";
import { InputField, PasswordInputField, PopUpMsg } from "../../components";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginUser, openPopUp } from "../../store/reducer";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
  PageTitle("SignIn");
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [valuesErr, setValuesErr] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [payload, setPayload] = React.useState({
    type: "error",
    message: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleForm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let errObj = {};
    const url = process.env.REACT_APP_API_KEY;
    axios
      .post(url + "login", values)
      .then((res) => {
        setOpen(true);
        dispatch(openPopUp({ message: "Login Successfully!", type:"success" }));
        dispatch(loginUser(res.data));
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          for (const [key, value] of Object.entries(err?.response?.data?.detail)) {
            errObj = { ...errObj, [key]: value[0] };
          }
          setValuesErr(errObj);
        } else if (err?.response?.status === 400) {
          setOpen(true);
          setPayload({ ...payload, message: err?.response?.data?.message });
        } else {
          setOpen(true);
          setPayload({ ...payload, message: err?.response?.data?.message });
        }
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="sign_in_page">
        <Container maxWidth="100%" sx={{ pt: 2, pb: 2 }}>
          <Box
            component={Paper}
            sx={{
              maxWidth: "450px",
              py: 4,
              px: 4,
            }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                color: "#407BFF",
                textAlign: "center",
              }}
            >
              Welcome
            </Typography>
            <Box
              component="form"
              onSubmit={(e) => handleForm(e)}
              sx={{ padding: {  sm: "75px 0", xs: "25px 0" } }}
            >
              <Stack direction="column" spacing={2}>
                <InputField
                  type="email"
                  name="email"
                  placeholder="Johndoe@gmail.com"
                  size="small"
                  labelTop="Email"
                  fullWidth
                  required
                  error={valuesErr?.email}
                  handleChange={(event) => handleChange(event)}
                />
                <PasswordInputField
                  placeholder="xxxxxx"
                  labelTop="Password"
                  required
                  size="small"
                  fullWidth
                  error={valuesErr?.password}
                  name="password"
                  handleChange={(event) => handleChange(event)}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2}
                mt={1}
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox size="small" defaultChecked />}
                    sx={{ "& span": { fontSize: "14px" } }}
                    label="Remember Me"
                  />
                </FormGroup>
                <Button
                  variant="text"
                  type="button"
                  onClick={() => navigate('/forget-password')}
                  style={{ color: "#FF0000", fontSize: "12px" }}
                >
                  Forgot Password
                </Button>
              </Stack>
              <div style={{ marginTop: "35px" }}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={isLoading}
                  type="submit"
                  style={{
                    backgroundColor: "#333",
                    borderRadius: "25px",
                    textTransform: "none",
                  }}
                >
                  {isLoading ? (
                    <CircularProgress
                      size={18}
                      sx={{ color: "#fff", mr: 1 }}
                    />
                  ) : null}
                  SignIn
                </Button>
              </div>
            </Box>
          </Box>
        </Container>
      </div>
      <PopUpMsg
        open={open}
        type={payload.type}
        message={payload.message ?? "Something went wrong"}
        handleClose={() => setOpen(false)}
      />
    </>
  );
}
