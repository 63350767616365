import React from 'react';
import { SelectBox, InputField } from '../../../components';
import {
    Box,
    Typography,
    Container,
    Stack,
    Grid,
    Tooltip,
    IconButton,
    Divider,
    FormControlLabel,
    Switch,
    ButtonGroup,
    Button,
    Paper
} from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import PageTitle from '../../../hooks/page-title';

export default function ModuleLayout() {
    PageTitle('Module Layout');

    return (
        <Container
            maxWidth="100%"
            sx={{ paddingTop: '12px', paddingBottom: '12px' }}
        >
            <Stack direction='row' alignItems='center' spacing={2}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography
                        variant="h6"
                        component="h6"
                        mt={"12px"}
                        mb={"10px"}
                    >
                        COMPONENT SELECTION
                    </Typography>
                    <Typography
                        variant="p"
                        component="p"
                        sx={{
                            color: "#969696",
                        }}
                    >
                        Configure the components for the selected roof orientation
                    </Typography>
                </Box>
                <Box>
                    <ButtonGroup sx={{ '& .MuiButtonBase-root': { borderColor: '#ccc !important', pl: 3, pr: 3 } }} variant="text" component={Paper} aria-label="outlined button group">
                        <Button
                            color='success'
                        >
                            <DoneIcon color='success' />
                        </Button>
                        <Button
                            color='error'
                        >
                            <CloseIcon color='error' />
                        </Button>
                    </ButtonGroup>
                </Box>
            </Stack>
            <Box
                mt={4}
                pb={5}
            >
                <Stack
                    direction="row"
                    alignItems='center'
                >
                    <Typography
                        variant="p"
                        component="span"
                        mt={"12px"}
                        mb={"12px"}
                        pr={1}
                    >
                        PV Module
                    </Typography>
                    <Tooltip title='info'>
                        <InfoRoundedIcon sx={{ opacity: 0.5 }} fontSize='12px' />
                    </Tooltip>
                </Stack>
                <Grid container spacing={2}>
                    <Grid item md={2} xs={12}>
                        <SelectBox
                            label='Operating costs of PV system are based on'
                            items={['Prozent der Investition', 'Prozent der Investition']}
                            size='small'
                            styles={{ mt: 2 }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <SelectBox
                            label='Operating costs of PV system are based on'
                            items={['Prozent der Investition', 'Prozent der Investition']}
                            size='small'
                            styles={{ mt: 2 }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <SelectBox
                            label='Operating costs of PV system are based on'
                            items={['Prozent der Investition', 'Prozent der Investition']}
                            size='small'
                            styles={{ mt: 2 }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Stack direction='row' alignItems='center' spacing={2}>
                            <Box sx={{ flexGrow: 1 }}>
                                <SelectBox
                                    label='Operating costs of PV system are based on'
                                    items={['Prozent der Investition', 'Prozent der Investition']}
                                    size='small'
                                    styles={{ mt: 2 }}
                                    fullWidth
                                />
                            </Box>
                            <IconButton sx={{ backgroundColor: '#000', '&:hover': { backgroundColor: '#000' } }}>
                                <SimCardDownloadIcon sx={{ color: '#fff' }} />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item md={2} xs={12}>
                        <SelectBox
                            label='Operating costs of PV system are based on'
                            items={['Prozent der Investition', 'Prozent der Investition']}
                            size='small'
                            styles={{ mt: 2 }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <SelectBox
                            label='Operating costs of PV system are based on'
                            items={['Prozent der Investition', 'Prozent der Investition']}
                            size='small'
                            styles={{ mt: 2 }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <SelectBox
                            label='Operating costs of PV system are based on'
                            items={['Prozent der Investition', 'Prozent der Investition']}
                            size='small'
                            styles={{ mt: 2 }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Stack direction='row' alignItems='center' spacing={2}>
                            <Box sx={{ flexGrow: 1 }}>
                                <SelectBox
                                    label='Operating costs of PV system are based on'
                                    items={['Prozent der Investition', 'Prozent der Investition']}
                                    size='small'
                                    styles={{ mt: 2 }}
                                    fullWidth
                                />
                            </Box>
                            <IconButton sx={{ backgroundColor: '#000', '&:hover': { backgroundColor: '#000' } }}>
                                <SimCardDownloadIcon sx={{ color: '#fff' }} />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 4, mb: 4 }} />
                <Typography
                    variant="p"
                    component="p"
                >
                    MODULE LAYOUT
                </Typography>
                <Grid container spacing={3} sx={{ mt: 1 }}>
                    <Grid item md={3} xs={12}>
                        <InputField
                            label='Dachname'
                            initValue='Dach1'
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <InputField
                            label='module arrangement'
                            initValue='Vertical'
                            size="small"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ mt: 1 }}>
                    <Grid item md={3} xs={12}>
                        <InputField
                            label='project Manager'
                            initValue='180.00'
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <InputField
                            label='module slope'
                            initValue='30.00'
                            size="small"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ mt: 1 }}>
                    <Grid item md={3} xs={12}>
                        <InputField
                            label='specific yield'
                            initValue='Automatically'
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel control={<Switch color='success' />} label="Set manually" />
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box
                pt={4}
                pb={5}
            >
                <Typography
                    variant="h6"
                    component="h6"
                    mt={"12px"}
                    mb={"10px"}
                >
                    ROOF SURFACES
                </Typography>
                <Typography
                    variant="p"
                    component="p"
                    sx={{
                        color: "#969696",
                    }}
                >
                    Specify the roof areas intended for PV modules
                </Typography>
                <Box
                    sx={{ border: '1px solid #ccc', p: 1,maxWidth:'480px', borderRadius: '7px', mt: 2 }}
                >
                    <Stack direction='row' alignItems='center' spacing={4}>
                        <small>106.782</small>
                        <small>20,800 kWp</small>
                        <InputField
                            size='small'
                            initValue='52'
                            type='text'
                            sx={{maxWidth:'100px'}}
                        />
                        <small>52 Module</small>
                        <button
                            style={{
                                backgroundColor: 'transparent',
                                border: 0,
                                cursor: 'pointer',
                                borderLeft:'1px solid #ccc',
                            }}
                        >
                            <ArrowRightIcon />
                        </button>
                    </Stack>
                </Box>
            </Box>
            <Divider />
            <Box
                pt={4}
                pb={5}
            >
                <Typography
                    variant="h6"
                    component="h6"
                    mt={"12px"}
                    mb={"10px"}
                >
                    MORE INFORMATION
                </Typography>
                <Typography
                    variant="p"
                    component="p"
                    sx={{
                        color: "#969696",
                    }}
                >
                    Here you can enter additional information about the project, but without influencing the simulation.
                </Typography>
            </Box>
        </Container >
    )
}