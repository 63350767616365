import React from 'react'
import {
    Box,
    Container,
    Table,
    TableContainer,
} from '@mui/material';

const SolarPowerSystem = () => {
    console.log("ran")
    return (
        <Container maxWidth="100%" sx={{ paddingTop: "25px", paddingBottom: "105px" }}>
            <Box sx={{ px: { md: 4 } }}>
                <Box sx={{ display: 'flex', justifyContent: "flex-end", alignItems: "center" }}>
                    <img width="154" src={require('../../../../../assets/images/logo.png')}></img>
                </Box>
                <TableContainer>
                    <Table
                        sx={{
                            minWidth: '750px',
                            '& .MuiTableCell-root': {
                                paddingTop: "5px",
                                paddingBottom: "5px",
                            }
                        }}
                    >
                        <tbody>
                            <tr style={{ background: "#F8F8F8", tableLayout: "fixed" }}>
                                <th align="left" style={{ paddingLeft: "1em", paddingBlock: "1.6em" }}>Solarstromanlage</th>
                                <th align="left" style={{ paddingLeft: "0.9em", paddingBlock: "1.6em" }}></th>
                                <th align="left" style={{ paddingRight: "16.9em", paddingBlock: "1.6em" }}></th>
                                <th align="right" style={{ paddingRight: "1em", paddingBlock: "1.6em" }}>38'038.60</th>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>Photovoltaikmodule</td>
                                <td align="center" style={{ fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>35 St.</td>
                                <td align="center" style={{ fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>250.00</td>
                                <td style={{ fontWeight: 600, paddingTop: "1.7em", paddingBottom: "0.45em", textAlign: "left" }} align="center">8’500.00</td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>SolycoR-BF 1003/400</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr style={{ borderBottom: "2px solid #B5B5B5" }}>
                                <td style={{ paddingLeft: "2em", paddingBottom: "1.7em" }}>Deutsches Full Modul</td>
                                <td align="center" style={{ paddingBottom: "1.7em" }}></td>
                                <td align="center" style={{ paddingBottom: "1.7em" }}></td>
                                <td style={{ paddingBottom: "1.7em" }} align="center"></td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>Wechselrichter</td>
                                <td align="center" style={{ fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>1 St.</td>
                                <td align="center" style={{ fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>1’700.00</td>
                                <td style={{ fontWeight: 600, paddingTop: "1.7em", paddingBottom: "0.45em", textAlign: "left" }} align="center">1’700.00</td>
                            </tr>
                            <tr style={{ borderBottom: "2px solid #B5B5B5" }}>
                                <td style={{ paddingLeft: "2em", paddingBottom: "1.7em" }}>SolarEdge * SEK</td>
                                <td align="center" style={{ paddingBottom: "1.7em" }}></td>
                                <td align="center" style={{ paddingBottom: "1.7em" }}></td>
                                <td style={{ paddingBottom: "1.7em" }} align="center"></td>
                            </tr>
                            {/*  */}
                            <tr>
                                <td style={{ paddingLeft: "2em", fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>Unterkonstruktion</td>
                                <td align="center" style={{ fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>66.39 m</td>
                                <td align="center" style={{ fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>140.00</td>
                                <td style={{ fontWeight: 600, paddingTop: "1.7em", paddingBottom: "0.45em", textAlign: "left" }} align="center">9’294.60</td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>novotegra. Side-fox Einlegesystem Ziegel</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>Dachspann er nach statischer Berechnung</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>Lieferung und Transport</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>Aluminium Tragerprofil</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>Modulkemmen laut Herstellervorgabe</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr style={{ borderBottom: "2px solid #B5B5B5" }}>
                                <td style={{ paddingLeft: "2em", paddingBottom: "1.7em" }}>Alle Komponenten aus Aluminium oder Edelstahl</td>
                                <td align="center" style={{ paddingBottom: "1.7em" }}></td>
                                <td align="center" style={{ paddingBottom: "1.7em" }}></td>
                                <td style={{ paddingBottom: "1.7em" }} align="center"></td>
                            </tr>
                            {/*  */}
                            <tr>
                                <td style={{ paddingLeft: "2em", fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>Überspannungsschutz DC-seitig Typ II</td>
                                <td align="center" style={{ fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>1 St.</td>
                                <td align="center" style={{ fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>500.00</td>
                                <td style={{ fontWeight: 600, paddingTop: "1.7em", paddingBottom: "0.45em", textAlign: "left" }} align="center">500.00</td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>Bei Blitzentladungen werden berenungen in electrische Leiter</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>induziert. Zum Schutz der elektrischen Systeme vor diesen zerstörenden</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>Spannungsspitzen haben sich Überspannungsschutzgeräte (SPD engl</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr style={{ borderBottom: "2px solid #B5B5B5" }}>
                                <td style={{ paddingLeft: "2em", paddingBottom: "1.7em" }}>Surge Protective Device) bewahrt</td>
                                <td align="center" style={{ paddingBottom: "1.7em" }}></td>
                                <td align="center" style={{ paddingBottom: "1.7em" }}></td>
                                <td style={{ paddingBottom: "1.7em" }} align="center"></td>
                            </tr>
                            {/*  */}
                            <tr>
                                <td style={{ paddingLeft: "2em", fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>Solarkabel und Erdungskabel</td>
                                <td align="center" style={{ fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>1 St.</td>
                                <td align="center" style={{ fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>500.00</td>
                                <td style={{ fontWeight: 600, paddingTop: "1.7em", paddingBottom: "0.45em", textAlign: "left" }} align="center">500.00</td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>Oronbestandig und Ubestandig</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>Kurz und erschlusssicher</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>Halogenfrei</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>Verbessertes Brandwiderstandsverhalten</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr style={{ borderBottom: "2px solid #B5B5B5" }}>
                                <td style={{ paddingLeft: "2em", paddingBottom: "1.7em" }}>Schweizer Qualitaskabel</td>
                                <td align="center" style={{ paddingBottom: "1.7em" }}></td>
                                <td align="center" style={{ paddingBottom: "1.7em" }}></td>
                                <td style={{ paddingBottom: "1.7em" }} align="center"></td>
                            </tr>
                            {/*  */}
                            <tr>
                                <td style={{ paddingLeft: "2em", fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>Monitoring und Optimierungssystem</td>
                                <td align="center" style={{ fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>1 St.</td>
                                <td align="center" style={{ fontWeight: 500, paddingTop: "1.7em", paddingBottom: "0.45em" }}>1’500.00</td>
                                <td style={{ fontWeight: 600, paddingTop: "1.7em", paddingBottom: "0.45em", textAlign: "left" }} align="center">1’500.00</td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>Optimizer Wirkungsgrad von über 99.5%</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>25 Jahre Garantie</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>Handy und Desktop Anwendung</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "2em", paddingBottom: "0.45em" }}>Volle Anlagenkontrolle Inc. Fehlererkennung</td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td align="center" style={{ paddingBottom: "0.45em" }}></td>
                                <td style={{ paddingBottom: "0.45em" }} align="center"></td>
                            </tr>
                            <tr style={{ borderBottom: "2px solid #B5B5B5" }}>
                                <td style={{ paddingLeft: "2em", paddingBottom: "1.7em" }}>Echtzeitkontrolle</td>
                                <td align="center" style={{ paddingBottom: "1.7em" }}></td>
                                <td align="center" style={{ paddingBottom: "1.7em" }}></td>
                                <td style={{ paddingBottom: "1.7em" }} align="center"></td>
                            </tr>
                        </tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    )
}

const styles = {
    thead: {
        paddingLeft: "1em",
        paddingBlock: "1.6em"
    }
}

export default SolarPowerSystem