import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Switch,
  Typography,
} from "@mui/material";
import { InputField, CustomModal} from "../../../components";
import API from "../../../axios";
import { useDispatch } from "react-redux";
import { openPopUp } from "../../../store/reducer";

export default function UpdateComponentType({
  id,
  setId,
  handleClose,
  getData = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(true);
  const [openDrop] = React.useState(false);

  const [formData, setFormData] = useState({
    title: "",
    is_custom: true,
    status: true,
    _method: "PATCH",
  });
  const [formErrors, setFormErrors] = React.useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    getComponentTypes();
  }, []);
  const getComponentTypes = async () => {
    try{
        const res = await API(`admin/component-types/${id}`, "get")
        setGetLoading(false);
        setFormData({
          ...formData,
          title: res?.data?.title,
          status: res?.data?.active,
          is_custom: res?.data?.is_custom,
        });
      }
      catch(err) {
        let obj = {
          message: err?.response?.message,
        };
        dispatch(openPopUp(obj));
        setIsLoading(false);
      };
  };
  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let errObj = {};
    setFormErrors(errObj);

   try {
      const res = API(`admin/component-types/${id}`, "post", formData)
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: "Component type has been updated.",
          type: "success",
        })
      );
      handleClose(false);
      getData();
    }
    catch(err) {
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        dispatch(openPopUp({
          message: err?.response?.message,
          type: "error",
        }));
      }
      setIsLoading(false);
    };
  };
  return (
    <>
      <CustomModal
        handleClose={() => {
          handleClose();
          setId(null);
        }}
      >
        <Container maxWidth="100%" >
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
              Update Component Type
            </Typography>
          </Box>
          {getLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={30} />
            </Box>
          ) : (
            <form autoComplete="off" onSubmit={handleForm}>
              <Box mt={2} mb={3}>
                <InputField
                  placeholder="Title"
                  size="small"
                  label="Title"
                  required
                  fullWidth
                  styles={{ mb: 2 }}
                  name="title"
                  error={formErrors.title}
                  value={formData.title}
                  handleChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                />
                <Box>
                  <label>Custom</label>
                  <br />
                  <small>No</small>
                  <Switch
                    value={formData.is_custom}
                    checked={formData.is_custom}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        is_custom: e.target.checked ? 1 : 0,
                      })
                    }
                    color="success"
                  />
                  <small>Yes</small>
                </Box>
                <Box>
                  <label>Status</label>
                  <br />
                  <small>Disabled</small>
                  <Switch
                    value={formData.status}
                    checked={formData.status}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        status: e.target.checked ? 1 : 0,
                      })
                    }
                    color="success"
                  />
                  <small>Active</small>
                </Box>
                <Box sx={{ textAlign: "end" }}>
                  <Button
                    variant="contained"
                    onClick={() => {}}
                    sx={{
                      mt: 2,
                    }}
                    disabled={isLoading}
                    type="submit"
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={18}
                        sx={{ color: "#fff", mr: 1, size: "12px" }}
                      />
                    ) : null}
                    Update
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Container>
      </CustomModal>
    </>
  );
}
