import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Box,
    IconButton,
    Stack
} from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import PersonIcon from '@mui/icons-material/Person';

import i18next from 'i18next';

import { useDispatch } from 'react-redux';
import { toggleMenu } from '../../store/reducer';

export default function AdminHeader({ hideMenu }) {
    const matches = useMediaQuery('(min-width:900px)');
    const dispatch = useDispatch();

    return (
        <header>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                    backgroundColor: '#000',
                    p: '15px'
                }}
            >
                <div>
                    {!matches &&
                        !hideMenu &&
                        <IconButton
                            onClick={() => dispatch(toggleMenu(true))}
                        >
                            <ListIcon
                                sx={{
                                    color: '#fff'
                                }}
                            />
                        </IconButton>
                    }
                </div>
                <Box
                    component='div'
                >
                    <SideButtons />
                </Box>
            </Stack>
        </header>
    );
}

const SideButtons = () => {
    const renderBtn = (handleChange, icon) => {
        return <IconButton
            onClick={handleChange}
            size='small'
            sx={{
                color: '#000',
                backgroundColor:'#fff',
                transition: '0.2s all ease-in-out',
                '&:hover': {
                    backgroundColor: '#fff',
                    transform: 'scale(1.1)'
                }
            }}>
            {icon}
        </IconButton>
    }
    function handleLang() {
        const key = localStorage.getItem('i18nextLng')
        if (key === "en-US") {
            i18next.changeLanguage("de");

        } else {

            i18next.changeLanguage("en-US");
        }
        console.log(key)
    }

    return (
        <Stack
            direction='row'
            alignItems='center'
            justifyContent={{ sm: 'center', md: 'flex-end' }}
            spacing={2}
        >
            {renderBtn(() => { }, <PersonIcon />)}
            {/* {renderBtn(() => handleLang(), localStorage.getItem('i18nextLng') === 'en-US' ? 'En' : 'De')} */}
        </Stack>
    )
}
