
import {
  Home,
  Library,
  LibraryTabs,
  PartsOverView,
  Activities,
  CostAnalysis,
  Customer,
  Building,
  PvSystem,
  PromotionsBattery,
  EconomyPv,
  ProjectOverview,
  ModuleLayout,
  Inverter,
  PowerConsumption,
  ElectricityTariffs,
  BatteryStorage,
  Documents,
  ElectroFormSolar,
  MultiSteps,
  AdHome,
  GetOrganization,
  AddOrganization,
  UpdateOrganization,
  GetComponentTypes,
  GetManufacturer,
  AdTranslation,
  GetModels,
  GetModelsInfo,
  ComponentsPanel,
  GroupPanel,
  ProjectTemplatePanel,
  CalculatorPanel,
  MainTemplatePanel,
  RequirementsPanel
} from "../pages";

import ComingSoon from "../pages/coming-soon";

const managerRoutes = [
  {
    path: "/",
    element: <Home />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/activities",
    element: <ComingSoon />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library",
    element: <ComponentsPanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library/groups",
    element: <GroupPanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library/project-template",
    element: <ProjectTemplatePanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library/main-template",
    element: <MainTemplatePanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library/solar-calculator",
    element: <CalculatorPanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library/requirements",
    element: <RequirementsPanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/project/:id/overview",
    element: <ProjectOverview />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/cost-analysis",
    element: <CostAnalysis />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/customer",
    element: <Customer />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/project-installations",
    element: <ComingSoon />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/battery-storage",
    element: <BatteryStorage />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/building",
    element: <Building />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/pv-system",
    element: <PvSystem />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/module-layout",
    element: <ModuleLayout />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/inverter",
    element: <Inverter />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/power-consumption",
    element: <PowerConsumption />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/electricity-tariffs",
    element: <ElectricityTariffs />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/pv-batteries",
    element: <PromotionsBattery />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/eco-pv",
    element: <EconomyPv />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/eco-battery-system",
    element: <ComingSoon />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/parts-overiew",
    element: <PartsOverView />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/parts-pv",
    element: <Activities />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/batteries-systerm",
    element: <ComingSoon />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/recurring-cost",
    element: <ComingSoon />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/patch-pv",
    element: <ComingSoon />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/ele-form-solar",
    element: <ElectroFormSolar />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/documents",
    element: <Documents />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/project/:id/steps",
    element: <MultiSteps />,
    layout: false,
    type: "MANAGER",
  },
];

const adminRoutes = [
  {
    path: "/admin",
    element: <AdHome />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/organization",
    element: <GetOrganization />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/organization/create",
    element: <AddOrganization />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/organization/update",
    element: <UpdateOrganization />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/component-types",
    element: <GetComponentTypes />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/manufacturer",
    element: <GetManufacturer />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/models",
    element: <GetModels />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/models-info",
    element: <GetModelsInfo />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/translation",
    element: <AdTranslation />,
    layout: true,
    type: "SUPER_ADMIN",
  },
];

export { managerRoutes, adminRoutes };
