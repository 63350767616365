import React, { useState, useEffect } from "react";

import {
  Container,
  Button,
  Box,
  TableRow,
  TableCell,
  Typography,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";

import API from "../../../axios";
import { TableWrapper, UsePagination } from "../../../components";
import CreateManufacturer from "./create-manufacturer";
import UpdateManufacturer from "./update-manufacturer";

export default function GetManufacturer() {
  const [isLoading, setIsLoading] = useState(false);
  const [manufacturer, setManufacturer] = useState(null);
  const [componentId, setComponentId] = useState(0);
  const [id, setId] = useState(0);
  const [page, setPage] = useState(1);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  useEffect(() => {
    GetManufacturers();
  }, [page]);

  const GetManufacturers = async () => {
    setIsLoading(true);
    const res = await API(`admin/manufacturers?page=${page}`, "get");
    try {
      setManufacturer(res.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setManufacturer(null);
    }
  };
  var tableCols = ["Manufacturer", "Component Type", "Status", "Action"];

  return (
    <>
      <Container maxWidth="100%" sx={{ py: 3 }}>
        <Typography variant="h6">Manufacturers</Typography>
        <Box textAlign="end" mt={2} mb={2}>
          <Button variant="contained" onClick={() => setCreateModal(true)}>
            Create
          </Button>
        </Box>
        <TableWrapper
          thContent={tableCols.map((column, index) => (
            <TableCell key={index}>{column}</TableCell>
          ))}
          spanTd={tableCols.length}
          isLoading={isLoading}
          isContent={!!manufacturer && manufacturer?.data.length ? true : false}
          tableStyle={{minWidth:"400px"}}
        >
          {!!manufacturer &&
            manufacturer?.data.map((manufacturer, index) => (
              <TableRow key={index}>
                <TableCell>{manufacturer.manufacturer}</TableCell>
                <TableCell>{manufacturer.componenttypes?.title}</TableCell>
                <TableCell>
                  <Chip
                    label={manufacturer.active ? "active" : "disabled"}
                    color={manufacturer.active ? "success" : "error"}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={() => {
                        setUpdateModal(true);
                        setId(manufacturer.id);
                        setComponentId(manufacturer.component_type_id);
                      }}
                      color="primary"
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </Tooltip>{" "}
                </TableCell>
              </TableRow>
            ))}
        </TableWrapper>
       
        {!!manufacturer && manufacturer?.last_page > 1 && (
          <Box component="div" sx={{ mt: 2 }}>
            <UsePagination
              total={manufacturer?.total}
              perPage={manufacturer?.per_page}
              page={page}
              setPage={setPage}
            />
          </Box>
        )}
      </Container>
      {createModal && (
        <CreateManufacturer
          handleClose={() => setCreateModal(false)}
          getData={GetManufacturers}
        />
      )}
      {updateModal && (
        <UpdateManufacturer
          id={id}
          setId={setId}
          componentId={componentId}
          setComponentId={setComponentId}
          handleClose={() => setUpdateModal(false)}
          getData={GetManufacturers}
        />
      )}
    </>
  );
}
