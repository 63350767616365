import React from 'react';
import PageTitle from '../../../hooks/page-title';
import {
    Box,
    Container,
    Typography,
    Grid,
    TextField,
    Switch,
    Stack
} from '@mui/material';


export default function Building() {
    PageTitle('GEBÄUDE | GamaAg');
    return (
        <Container
            maxWidth="100%"
            sx={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
            <Typography
                variant="h5"
                component="h5"
                fontSize={"18px"}
                mt={"12px"}
                mb={"10px"}
            >
                GEBÄUDE
            </Typography>
            <Typography
                variant="p"
                component="p"
                sx={{
                    color: "#969696",
                }}
            >
                Nehmen Sie Ihre Gebäudeeinstellungen vor
            </Typography>
            <Box
                component='form'
                noValidate
                autoComplete="off"
                mt={3}
            >
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" component="h5"
                            mt={1}
                            sx={{
                                color: '#464646'
                            }}>
                            Gebäudetyp
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            id="outlined-basic" variant="outlined" placeholder="Einfamilienhaus"
                            sx={inputStyle}
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" component="h5"
                            mt={1}
                            sx={{
                                color: '#464646'
                            }}>
                            Baujahr
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            id="outlined-basic" variant="outlined" placeholder="2022"
                            sx={inputStyle}
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" component="h5"
                            mt={1}
                            sx={{
                                color: '#464646'
                            }}>
                            Letzte Sanierung
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            id="outlined-basic" variant="outlined" placeholder="2022"
                            sx={inputStyle}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" component="h5"
                            mt={2}
                            sx={{
                                color: '#464646'
                            }}>
                            Anzahl Personen
                        </Typography>
                        <TextField
                            fullWidth
                            type='email'
                            id="outlined-basic" variant="outlined" placeholder="2"
                            sx={inputStyle}
                        />
                    </Grid>
                    <Grid item md={3} xs={12} >
                        <Typography variant="subtitle1" component="h5"
                            mt={2}
                            sx={{
                                color: '#464646'
                            }}>
                            Raumtemperatur
                        </Typography>
                        <TextField
                            fullWidth
                            id="outlined-basic" variant="outlined"
                            placeholder="21°C"
                            sx={inputStyle}
                        />
                    </Grid>
                    <Grid item md={3} xs={12} >
                        <Typography variant="subtitle1" component="h5"
                            mt={2}
                            sx={{
                                color: '#464646',

                            }}>
                            Anzahl Wohneinheiten
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            id="outlined-basic" variant="outlined" placeholder="1"
                            sx={[inputStyle, { width: '100%' }]}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12} >
                        <Typography variant="subtitle1" component="h5"
                            mt={2}
                            sx={{
                                color: '#464646',

                            }}>
                            Anzahl Personen
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            id="outlined-basic" variant="outlined" placeholder="2"
                            sx={[inputStyle, { width: '100%' }]}
                        />
                    </Grid>
                    <Grid item md={3} xs={12} >
                        <Typography variant="subtitle1" component="h5"
                            mt={2}
                            sx={{
                                color: '#464646',

                            }}>
                            Raumtemperatur
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            id="outlined-basic" variant="outlined" placeholder="1 m2"
                            sx={[inputStyle, { width: '100%' }]}
                        />
                    </Grid>
                    <Grid item md={3} xs={12} >
                        <Typography variant="subtitle1" component="h5"
                            mt={2}
                            sx={{
                                color: '#464646',

                            }}>
                            Neubau
                        </Typography>
                        <Stack
                            direction="row"
                            mt={1}
                        >
                            <Switch />
                            <Typography
                                variant="p"
                                component="p"
                                sx={{
                                    color: "#969696",
                                }}
                            >
                                Falls aktiviert, gilt das Gebäudeals Neubau
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" component="h5"
                            mt={2}
                            sx={{
                                color: '#464646',

                            }}>
                            EGID
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            id="outlined-basic" variant="outlined" placeholder="1092196"
                            sx={[inputStyle, { width: '100%' }]}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={9} xs={12}>
                        <Typography variant="subtitle1" component="h5"
                            mt={2}
                            sx={{
                                color: '#464646',

                            }}>
                            Hinweise zum Sanierungsstand
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            multiline={true}
                            minRows={5}
                            id="outlined-basic" variant="outlined" placeholder="Hinweise zum Sanierungsstand"
                            sx={[inputStyle, { width: '100%' }]}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

const inputStyle = {
    mt: 1,
    width: '100%',
    '& .MuiInputBase-input': {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    "& .Mui-focused fieldset": {
        borderColor: "#1B9607 !important",
    },
    '& .MuiNativeSelect-select': {
        paddingTop: '10px',
        paddingBottom: '10px',
    }
}



