import React, { Fragment, useState } from "react";

import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Groups3Icon from "@mui/icons-material/Groups3";
import LogoutIcon from "@mui/icons-material/Logout";
import AppsIcon from "@mui/icons-material/Apps";
import HomeIcon from "@mui/icons-material/Home";
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import FactoryIcon from "@mui/icons-material/Factory";
import TranslateIcon from "@mui/icons-material/Translate";
import GridViewIcon from "@mui/icons-material/GridView";
import MenuIcon from "@mui/icons-material/Menu";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, toggleMenu } from "../../store/reducer";
import { logo2 } from "../../assets";
import API from "../../axios";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FeedIcon from '@mui/icons-material/Feed';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
const routes = [
  {
    label: "Home",
    url: "/admin",
    routeName: "/admin",
    icon: <HomeIcon />,
    children: [],
    childRoutes: [],
  },
  {
    label: "Organization",
    url: "/admin/organization",
    routeName: "/admin/organization",
    icon: <Groups3Icon />,
    children: [],
    childRoutes: [
      "/admin/organization/create",
      "/admin/organization/update",
    ],
  },

  {
    label: "Components",
    children: [
      {
        label: "Component Types",
        url: "/admin/component-types",
        routeName: "/admin/component-types",
        icon: <GridViewIcon />,
      },
      {
        label: "Manufacturers",
        url: "/admin/manufacturer",
        routeName: "/admin/manufacturer",
        icon: <FactoryIcon />,
      },
      {
        label: "Models",
        url: "/admin/Models",
        routeName: "/admin/Models",
        icon: <AutoAwesomeMotionIcon />,
      },
      {
        label: "Models Info",
        url: "/admin/models-info",
        routeName: "/admin/models-info",
        icon: <FeedIcon />,
      },
    ],
    childRoutes: [],
  },
  {
    label: "Translation",
    url: "/admin/translation",
    routeName: "/admin/translation",
    icon: <TranslateIcon />,
    children: [],
    childRoutes: [],
  },
];

export default function AdminDrawer() {
  const matches = useMediaQuery("(min-width:900px)");
  const anchorRef = React.useRef(null);
  const _openMenu = useSelector((state) => state.storeReducer.openMenu);
  const [open, setOpen] = React.useState();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    dispatch(toggleMenu(false));
  };

  const logoutUser = () => {
    setIsLoading(true);
    API("logout", "post")
      .then((res) => {
        dispatch(logout());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const drawer = (
    <Box>
      <List
        sx={{
          "& .MuiListItemIcon-root": {
            color: "#000",
          },
        }}
      >
        {routes.map((v, i) => (
          <Fragment key={i}>
            {v.children.length ? (
              <CollapseMenuItem
                title={v.label}
                item={v.children}
              ></CollapseMenuItem>
            ) : (
              <NavItem key={i} item={v} childRoutes={v.childRoutes} />
            )}
          </Fragment>
        ))}

        <Divider sx={{ mt: 3, mb: 3 }} />
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => logoutUser()}
            disabled={isLoading}
            selected={isLoading}
          >
            <ListItemIcon>
              {isLoading ? (
                <CircularProgress size={18} sx={{ color: "#000" }} />
              ) : (
                <LogoutIcon />
              )}
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <>
      <div
        className={_openMenu ? "overlay_ue0 open" : "overlay_ue0"}
        onClick={handleClick}
      ></div>
      <aside
        className={_openMenu ? "primary-navigation open" : "primary-navigation"}
        ref={anchorRef}
      >
        <Box
          sx={{
            position: "relative",
            borderBottom: "1px solid #ccc",
            textAlign: "center",
            padding: "25px 15px",
          }}
        >
          <img src={logo2} width="150px" alt="site logo" />
          {!matches ? (
            <Box
              component="div"
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <IconButton onClick={handleClick}>
                <CloseIcon />
              </IconButton>
            </Box>
          ) : null}
        </Box>
        {drawer}
      </aside>
    </>
  );
}

const NavItem = ({ item, childRoutes }) => {
  const routeName = window.location.pathname;
  const navigate = useNavigate();
  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={() => navigate(item.url)}
        selected={item.routeName === routeName}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.label} />
      </ListItemButton>
    </ListItem>
  );
};
const CollapseMenuItem = ({ title, item }) => {
  var routeName = window.location.pathname;
  const [open, setOpen] = React.useState();
  const navigate = useNavigate();
  const _url = "dd";

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={() => setOpen(!open)}>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary={title} />
          {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {item.map((_item, _i) => (
          <List component="div" key={_i} disablePadding>
            <ListItemButton
              sx={{ pl: "72px", pt: 0, pb: 0 }}
              onClick={() => navigate(_item.url)}
              selected={_item.routeName === routeName}
            >
              <ListItemIcon>{_item.icon}</ListItemIcon>
              <ListItemText
                primary={_item.label}
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "15px",
                  },
                }}
              />
            </ListItemButton>
          </List>
        ))}
      </Collapse>
    </>
  );
};
