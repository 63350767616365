import React, { useState } from 'react';
import {
    Box,
    Typography,
    Container,
    Tabs,
    Tab,
    Grid,
    Paper,
    Divider,
    Stack,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';

import PageTitle from '../../../hooks/page-title';
import { SelectBox } from '../../../components';

export default function ElectricityTariffs() {
    PageTitle('Electricity Tariffs');
    const [activeTab, setActiveTab] = useState('TARIFF_LIBRARY');

    const handleTabs = (event, newValue) => {
        setActiveTab(newValue);
    };

    const printCells = () => {
        return (
            Array(24).fill('').map((_, _i) => {
                // return <TableCell key={_i}><SquareIcon sx={{ color: _i > 5 && _i < 19 ? 'error' : '#ccc' }} /></TableCell>
                return <TableCell key={_i}><SquareIcon color={_i > 5 && _i < 19 ? 'error' : ''} /></TableCell>
            })
        );
    };

    return (
        <Container
            maxWidth="100%"
            sx={{ paddingTop: '12px', paddingBottom: '12px' }}
        >
            <Typography
                variant="h6"
                component="h6"
                mt={"12px"}
                mb={"10px"}
            >
                ELECTRICITY TARIFFS
            </Typography>
            <Typography
                variant="p"
                component="p"
                sx={{
                    color: "#969696",
                    mb: 4
                }}
            >
                Select current consumption tariff
            </Typography>
            <Box sx={{ width: '100%', mt: 3, borderBottom: '1px solid #ccc' }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabs}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="secondary tabs example"
                    sx={{ '& .MuiTabs-flexContainer': { overflowX: 'auto' } }}
                >
                    <Tab value="TARIFF_LIBRARY" label="TARIFF LIBRARY" sx={{ textTransform: 'none' }} />
                    <Tab value="OWN_RATE_ENTRY" label="OWN RATE ENTRY" sx={{ textTransform: 'none' }} />
                </Tabs>
            </Box>
            <Box sx={{ display: activeTab === 'TARIFF_LIBRARY' ? 'block' : 'none' }} >
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item md={4} xs={12}>
                        <SelectBox
                            label='energy supplier'
                            items={['Electricity supply for the municipality of Widnau', 'Electricity supply for the municipality of Widnau']}
                            fullWidth
                            size='small'
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <SelectBox
                            label='Tariffname'
                            items={['2018 Low Voltage Double Tariff', '2018 Low Voltage Double Tariff']}
                            fullWidth
                            size='small'
                        />
                    </Grid>
                </Grid>
                <Stack direction={{ md: 'row', sm: 'column' }} mt={3} spacing={2}>
                    <Box component={Paper} sx={cardStyle} maxWidth='300px'>
                        <Typography>
                            ELECTRICITY COSTS (EXCL. VAT)
                        </Typography>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <SquareIcon color='error' />
                                    </td>
                                    <td>
                                        <small>
                                            <strong>high tariff</strong><br />
                                            20.51 Rp./kWh
                                        </small>
                                    </td>
                                    <td>
                                        <small>
                                            <strong>Low tariff</strong><br />
                                            12.72 Rp./kWh
                                        </small>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                    <Box component={Paper} sx={cardStyle} maxWidth='300px'>
                        <Typography>
                            PERFORMANCE TARIFF
                        </Typography>
                        <p>0.00 CHF/KW/Month</p>
                    </Box>
                </Stack>
                <Typography
                    variant="h6"
                    component="h6"
                    mt={"12px"}
                    mb={"10px"}
                >
                    TARIFF HOURS
                </Typography>
                <TableContainer>
                    <Table sx={{ '& *': { border: 0 } }}>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                {Array(24).fill('').map((_, _i) => {
                                    return <TableCell key={_i}>{_i + 1}</TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Mon-Fri</TableCell>
                                {printCells()}
                            </TableRow>
                            <TableRow>
                                <TableCell>on</TableCell>
                                {printCells()}
                            </TableRow>
                            <TableRow>
                                <TableCell>So</TableCell>
                                {printCells()}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Divider sx={{ mt: 4, mb: 2 }} />
                <Typography
                    variant="h6"
                    component="h6"
                    mb={2}
                >
                    VIEW AND DEFINE FEED-IN TARIFF
                </Typography>
                <Box component={Paper} sx={cardStyle} maxWidth='300px'>
                    <Typography>
                        ELECTRICITY COSTS (EXCL. VAT)
                    </Typography>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <SquareIcon color='error' />
                                </td>
                                <td>
                                    <small>
                                        <strong>high tariff</strong><br />
                                        3,63 Rp./kWh
                                    </small>
                                </td>
                                <td>
                                    <small>
                                        <strong>Low tariff</strong><br />
                                        3,63 Rp./kWh
                                    </small>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </Box>
            <Box sx={{ display: activeTab === 'OWN_RATE_ENTRY' ? 'block' : 'none' }} >
                <></>
            </Box>

        </Container >
    )
}

const cardStyle = { '& p': { marginBottom: '15px' }, '& td': { padding: '5px', color: '#464646' }, p: 2, backgroundColor: '#F8F8F8' }