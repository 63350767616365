import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Paper,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  IconButton,
  Stack,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListIcon from "@mui/icons-material/List";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from '@mui/icons-material/Logout';

import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { useDispatch } from "react-redux";
import { logout, toggleMenu } from "../../store/reducer";
 import API from "../../axios";
export default function Header({ hideMenu }) {
  const matches = useMediaQuery("(min-width:900px)");
  const dispatch = useDispatch();

  return (
    <header>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          backgroundColor: "#000",
          p: "15px",
        }}
      >
        {matches ? (
          <>
            <Box component="div">
              <Menu />
            </Box>
            <Box component="div">
              <SideButtons />
            </Box>
          </>
        ) : (
          <>
            <div>
              {!hideMenu && (
                <IconButton onClick={() => dispatch(toggleMenu(true))}>
                  <ListIcon
                    sx={{
                      color: "#fff",
                    }}
                  />
                </IconButton>
              )}
            </div>
            <DropDown />
          </>
        )}
      </Stack>
    </header>
  );
}

const DropDown = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <>
      <Button
        variant="text"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        disableElevation
        onClick={handleToggle}
        ref={anchorRef}
        sx={{
          color: "#fff",
        }}
      >
        <MoreVertIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{
          zIndex: 1,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={{
                    width: "200px",
                  }}
                >
                  <Menu />
                  <Box mt={2}>
                    <SideButtons />
                  </Box>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const Menu = () => {
  const { t } = useTranslation();

  const renderLink = (url, title) => {
    return (
      <NavLink
        to={url}
        className={({ isActive }) => (isActive ? "active" : undefined)}
        end
      >
        {title}
      </NavLink>
    );
  };

  return (
    <Stack
      direction={{ sm: "column", md: "row" }}
      alignItems="center"
      justifyContent="flex-start"
    >
      {renderLink("/", t("HEADER.Projects"))}
      {renderLink("/library", t("HEADER.Library"))}
      {renderLink("/activities", t("HEADER.Activities"))}
    </Stack>
  );
};

const SideButtons = () => {
  const matches = useMediaQuery("(min-width:900px)");
  const renderBtn = (handleChange, icon) => {
    return (
      <IconButton
        onClick={handleChange}
        size="small"
        sx={{
          color: matches ? "#000" : "#fff",
          backgroundColor: matches ? "#fff" : "#000",
          transition: "0.2s all ease-in-out",
          "&:hover": {
            backgroundColor: matches ? "#fff" : "#000",
            transform: "scale(1.1)",
          },
        }}
      >
        {icon}
      </IconButton>
    );
  };
  function handleLang() {
    const key = localStorage.getItem("i18nextLng");
    if (key === "en-US") {
      i18next.changeLanguage("de");
    } else {
      i18next.changeLanguage("en-US");
    }
    console.log(key);
  }
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();

  const logoutUser = () => {
    setIsLoading(true);
    API("logout", "post")
      .then((res) => {
        dispatch(logout());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={{ sm: "center", md: "flex-end" }}
      spacing={2}
    >
      {/* {renderBtn(() => {}, <QuestionMarkIcon />)} */}
      {renderBtn(() => {}, <PlayArrowIcon />)}
      {renderBtn(() => {}, <PersonIcon />)}
      {renderBtn(() => {}, <SettingsIcon />)}
      {renderBtn(() => {}, <LogoutIcon onClick={logoutUser}/>)}
      {/* {renderBtn(() => handleLang(), localStorage.getItem('i18nextLng') === 'en-US' ? 'En' : 'De')} */}
    </Stack>
  );
};
