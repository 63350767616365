import React, { useState } from "react";

import PageTitle from "../../../../hooks/page-title";
import LibraryLayout from "../library-layout";
import EditRequirementsPanel from "./edit-requirements-panel";
import ViewRequirementsPanel from "./view-requirements-panel";


export default function RequirementsPanel() {
    PageTitle('Requirements');
    const [showEdit, setShowEdit] = useState(false);

    const switchScreen = () => setShowEdit(!showEdit)

    return (
        <LibraryLayout>
            {showEdit ?
                <EditRequirementsPanel switchScreen={switchScreen} />
                :
                <ViewRequirementsPanel switchScreen={switchScreen} />
            }
        </LibraryLayout>
    )
}