import React, { useState } from "react";

import PageTitle from "../../../../hooks/page-title";
import LibraryLayout from "../library-layout";
import ViewComponentsPanel from "./view-components-panel";
import EditComponentsPanel from "./edit-components-panel";

export default function ComponentsPanel() {
    PageTitle('Components');   
    const [showEdit,setShowEdit]= useState(false);

    const switchScreen=()=>setShowEdit(!showEdit)

    return (
        <LibraryLayout>
            {showEdit?
                <EditComponentsPanel switchScreen={switchScreen}/>
                :
                <ViewComponentsPanel switchScreen={switchScreen}/>
            }
        </LibraryLayout>
    )
}