import React, { useState } from "react";
import {
    Box,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Tooltip,
} from "@mui/material";

import { StartIconBtn } from "../../../../components";
import InfoIcon from '@mui/icons-material/Info';
import CreatePromoForm from "../forms/create-promo-form";
import AccountInfoForm from "../forms/account-info-form";

export default function ViewRequirementsPanel({ switchScreen, ...props }) {
    const [open, setOpen] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);

    return (
        <Box sx={{ pt: 2, pb: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        variant='h6'
                        component='h6'
                    >
                        PROMOTIONS
                    </Typography>
                    <Typography
                        variant='p'
                        component='p'
                        sx={{ mb: 2 }}
                    >
                        National PV subsidies are managed for you. Here you can define other (local) funding that is important to you.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table
                            sx={{
                                minWidth: '1200px',
                                '& .MuiTableCell-root': {
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                }
                            }}
                        >
                            <TableHead>
                                <TableRow
                                    sx={{
                                        backgroundColor: "#E6F5FE",
                                    }}
                                >
                                    <TableCell>Name</TableCell>
                                    <TableCell>description</TableCell>
                                    <TableCell>sponsorship type
                                        <Tooltip title="information">
                                            <InfoIcon sx={{ fontSize: '14px', opacity: 0.5 }} />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>Technology</TableCell>
                                    <TableCell>field of use
                                        <Tooltip title="information">
                                            <InfoIcon sx={{ fontSize: '14px', opacity: 0.5 }} />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>Automatically applied in the calculator
                                        <Tooltip title="information">
                                            <InfoIcon sx={{ fontSize: '14px', opacity: 0.5 }} />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>Automatically applied in Expert
                                        <Tooltip title="information">
                                            <InfoIcon sx={{ fontSize: '14px', opacity: 0.5 }} />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan='7' sx={{ textAlign: 'center' }}>
                                        You don't have any subsidies yet. Click on "Add local grant" to create a new claim.
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <StartIconBtn
                        title='Add local promotion'
                        styles={{ mt: 3 }}
                        handleClick={() => setOpen(true)}
                    />
                </Grid>
            </Grid>
            <CreatePromoForm open={open}
                submitForm={() => {
                    setOpen(false);
                    setOpenModal2(true);
                }}
                handleClose={() => setOpen(false)} />
            <AccountInfoForm open={openModal2} submitForm={() => switchScreen()} handleClose={() => setOpenModal2(false)} />
        </Box>
    )
}