import React, { useState } from "react";
import {
    Box,
} from "@mui/material";

import IdeasTabs from "../ideas-tabs";

export default function EditRequirementsPanel({switchScreen,...props}) {
    const [step, setStep] = useState(1);
    const [open, setOpen] = useState(false);

    return (
        <Box>
            <IdeasTabs />
        </Box>
    )
}