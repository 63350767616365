import React from 'react'
import {
    Box,
    Container,
    Typography,
    Grid,
    Table,
    TableContainer,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';

const YourPersonalOffer = () => {
    return (
        <Container maxWidth="100%" sx={{ paddingTop: "25px", paddingBottom: "105px" }}>
            <Box sx={{ px: { md: 4 } }}>
                <Box sx={{ display: 'flex', justifyContent: "flex-end", alignItems: "center" }}>
                    <img width="154" src={require('../../../../../assets/images/logo.png')}></img>
                </Box>
                <Grid container spacing={3}>
                    <Grid item lg={3.5}>
                        <Typography component="h5" sx={styles.head}>Timo Böhme</Typography>
                        <Typography component="p" sx={styles.body}>Algisbündtstrasse 9</Typography>
                        <Typography component="p" sx={styles.body}>9443 Widnau</Typography>
                        <Typography component="p" sx={styles.body}>Tel: 6+542156+586</Typography>
                        <Typography component="p" sx={styles.body}>info@tb-software.ch</Typography>
                    </Grid>
                    <Grid item lg={3.5}>
                        <Typography component="h5" sx={styles.head}>Ihr Kontakt für Rückfragen</Typography>
                        <Typography component="p" sx={[styles.body, { fontWeight: 600 }]}>Marcel Steiert</Typography>
                        <Typography component="p" sx={styles.body}>Wieslistrasse 15</Typography>
                        <Typography component="p" sx={styles.body}>9434 Au SG</Typography>
                        <Typography component="p" sx={styles.body}>Tel: +41794170600</Typography>
                        <Typography component="p" sx={styles.body}>marcel.steiert@gama-pv.ch</Typography>
                    </Grid>
                    <Grid item lg={3.5}>
                        <img style={{ borderRadius: 5, width: "100%", maxWidth: "170px" }} src={require("../../../../../assets/images/order-form.png")}></img>
                    </Grid>
                </Grid>
                <Box sx={{ display: "flex", alignItems: "flex-start", ml: 1, mt: 4 }}>
                    <CreateIcon sx={{ background: "#000000", color: "#fff", borderRadius: "5px", fontSize: "2rem", px: 0.8 }} />
                    <Typography component="p" sx={{ ml: 1.8, maxWidth: { lg: "550px", fontWeight: 600 } }}>Geschätzter Herr Böhme,</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", ml: 1, mt: 2 }}>
                    <CreateIcon sx={{ background: "#000000", color: "#fff", borderRadius: "5px", fontSize: "2rem", px: 0.8 }} />
                    <Typography component="p" sx={{ ml: 1.8, maxWidth: { lg: "550px", color: "#464646" } }}>Nehmen Sie sich Zeit und prüfen Sie Ihre Persönliche Offerte. Bei Fragen sind wir gerne für Sie da.</Typography>
                </Box>
                <Box component="div" sx={{ mt: 6 }}>
                    <Box sx={{ display: 'flex' }}>
                        <span style={styles.arrow}></span>
                        <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', md: "1.3rem" }, fontWeight: 500 }}>PHOTOVOLTAIKANLAGE</Typography>
                    </Box>
                </Box>
                <TableContainer>
                    <Table sx={{ width: { md: "70%" }, tableLayout: "fixed", minWidth: "600px" }}>
                        <tbody>
                            <tr style={{ borderTop: "1px solid #000000" }}>
                                <td style={{ paddingTop: "1em", paddingRight: "10em", fontWeight: 600 }} align="left">KOSTEN</td>
                                <td align="left" style={{ paddingTop: "1em" }}>Photovoltaikanlage (13.6 kWp)</td>
                                <td align="right" style={{ paddingTop: "1em" }}>38'038.60 CHF</td>
                            </tr>
                            <tr>
                                <td style={{ paddingTop: "0.35em", paddingInline: "8em" }}></td>
                                <td align="left" style={{ paddingTop: "0.35em" }}>Batteriespeicher (11 kWh)</td>
                                <td align="right" style={{ paddingTop: "0.35em" }}>9'000.00 CHF</td>
                            </tr>
                            <tr>
                                <td style={{ paddingTop: "0.35em", paddingInline: "8em" }}></td>
                                <td align="left" style={{ paddingTop: "0.35em", paddingBottom: '0.35em', borderBottom: "1px solid #000000" }}>dsfghhdfghdfghdf </td>
                                <td align="right" style={{ paddingTop: "0.35em", paddingBottom: '0.35em', borderBottom: "1px solid #000000" }}>-8'555.00 CHF</td>
                            </tr>
                            <tr>
                                <td style={{ paddingTop: "0.35em", paddingInline: "8em" }}></td>
                                <td align="left" style={{ paddingTop: "0.35em" }}>MWST 7.7%</td>
                                <td align="right" style={{ paddingTop: "0.35em" }}>2'963.25 CHF</td>
                            </tr>
                            <tr>
                                <td style={{ paddingTop: "0.35em", paddingInline: "8em" }}></td>
                                <td align="left" style={{ paddingTop: "0.35em", paddingBottom: '0.35em', }}>Kosten inkl. MWST</td>
                                <td align="right" style={{ paddingTop: "0.35em", paddingBottom: '0.35em', }}>41'446.85 CHF</td>
                            </tr>
                            <tr style={{ borderTop: "1px solid #000000" }}>
                                <td style={{ paddingTop: "1em", paddingRight: "10em", fontWeight: 600 }} align="left">FÖRDERUNGEN**</td>
                                <td align="left" style={{ paddingTop: "1em" }}>Einmalvergütung (Photovoltaik)</td>
                                <td align="right" style={{ paddingTop: "1em" }}>-5'518.00 CHF</td>
                            </tr>
                            <tr>
                                <td style={{ paddingTop: "0.35em", paddingInline: "8em" }}></td>
                                <td align="left" style={{ paddingTop: "0.35em", paddingBottom: '0.35em' }}>30 Gemeinde Windau</td>
                                <td align="right" style={{ paddingTop: "0.35em", paddingBottom: '0.35em' }}>30 Gemeinde Windau</td>
                            </tr>
                            <tr style={{ borderTop: "1px solid #000000" }}>
                                <td style={{ paddingTop: "1em", paddingRight: "10em", fontWeight: 600 }} align="left">IHRE INVESTITION</td>
                                <td align="left" style={{ paddingTop: "0.35em", paddingBottom: '0.35em', borderBottom: "1px solid #000000" }}>Ihre Gesamtinvestition</td>
                                <td align="right" style={{ paddingTop: "0.35em", paddingBottom: '0.35em', borderBottom: "1px solid #000000" }}>31'428.85 CHF</td>
                            </tr>
                            <tr>
                                <td style={{ paddingTop: "0.35em", paddingInline: "8em" }}></td>
                                <td align="left" style={{ paddingTop: "0.35em", paddingBottom: '0.35em' }}>Erwartete Steuerersparnis***</td>
                                <td align="right" style={{ paddingTop: "0.35em", paddingBottom: '0.35em' }}>-4714.35 CHF</td>
                            </tr>
                            <tr style={{ borderBottom: "1px solid #000000" }}>
                                <td style={{ paddingTop: "0.35em", paddingInline: "8em" }}></td>
                                <td align="left" style={{ paddingTop: "0.35em", paddingBottom: '0.35em' }}>Effektive Kosten</td>
                                <td align="right" style={{ paddingTop: "0.35em", paddingBottom: '0.35em' }}>26'714.50 CHF</td>
                            </tr>
                        </tbody>
                    </Table>
                </TableContainer>
                <Typography component="p" sx={{mt: 2}}>* Kosten gelten bei der Bestellung aller aufgelisteten Systeme</Typography>
            </Box>
        </Container>
    )
}

const styles = {
    head: {
        fontSize: "1.1525rem",
        color: "#000000",
        fontWeight: 500,
        marginBottom: "0.556em"
    },
    body: {
        fontSize: "1rem",
        color: "#464646",
        marginTop: "0.2em"
    },
    arrow: {
        width: 0,
        height: 0,
        display: 'inline-block',
        borderTop: '7px solid transparent',
        borderBottom: '7px solid transparent',
        borderLeft: '7px solid black',
        transform: 'rotate(221deg)',
        marginRight: '5px'
    }
}

export default YourPersonalOffer