import React from "react";
import { Box, Tooltip, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default function AddProjectBtn({ handleClick }) {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 100,
        right: 25,
        zIndex: 100,
      }}
    >
      <Tooltip title="Add Project">
        <IconButton
          onClick={handleClick}
          sx={{
            backgroundColor: "#40B92D",
            color: "#fff",
            "&:hover *": { color: "#000" },
          }}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
