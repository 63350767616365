import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab
} from '@mui/material'
import PageTitle from '../../../hooks/page-title';
import CreateDoc from './tab-panel/create-document';
import FileManager from './tab-panel/file-manager';
import DataSheet from './tab-panel/data-sheets';

export default function Documents() {
  PageTitle('PROJEKTDOKUMENTE | GamaAg');
  const [activeTab, setActiveTab] = useState('CREATEDOC');

  const handleTabs = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <>
      <Container
        maxWidth="100%"
        sx={{ paddingTop: "15px", paddingBottom: "15px" }}
      >
        <Box sx={{ pt: 2 }}>
          <Typography
            variant='h6'
            component='h6'
          >
            PROJECT DOCUMENT
          </Typography>
          <Typography
            variant='p'
            component='p'
            color='#969696'
            sx={{ mb: 2 }}
          >
            Manage your project documents
          </Typography>
        </Box>
        <Box sx={{ width: '100%', borderBottom: '1px solid #ccc' }}>
          <Tabs
            value={activeTab}
            onChange={handleTabs}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
            sx={{ '& .MuiTabs-flexContainer': { overflowX: 'auto' } }}
          >
            <Tab value="CREATEDOC" label='CREATE DOCUMENT' sx={{ textTransform: 'none' }} />
            <Tab value="FILEMANGER" label='FILE MANAGER' sx={{ textTransform: 'none' }} />
            <Tab value="DATASHEET" label='DATA SHEETS' sx={{ textTransform: 'none' }} />
          </Tabs>
        </Box>
        <Box sx={{ display: activeTab === 'CREATEDOC' ? 'block' : 'none' }} >
          <CreateDoc />
        </Box>
        <Box sx={{ display: activeTab === 'FILEMANGER' ? 'block' : 'none' }} >
          <FileManager />
        </Box>
        <Box sx={{ display: activeTab === 'DATASHEET' ? 'block' : 'none' }} >
          <DataSheet />
        </Box>
      </Container>
    </>
    // <Box>
    //   <Typography
    //     variant='h6'
    //     component='h6'
    //   >
    //     PROMOTIONS
    //   </Typography>
    //   <Typography
    //     variant='p'
    //     component='p'
    //     sx={{ mb: 2 }}
    //   >
    //     National PV subsidies are managed for you. Here you can define other (local) funding that is important to you.
    //   </Typography>
    // </Box>

  )
}
