import React, { useState } from "react";
import {
    Box,
    Button
} from "@mui/material";

export default function EditGroupPanel({ switchScreen, ...props }) {

    return (
        <Box>
            <Button
                sx={{ textTransform: 'none' }}
                onClick={() => switchScreen()}
            >
                Back
            </Button>
        </Box>

    )
}