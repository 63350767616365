import React from 'react';
import {
    Box,
    Container,
    Grid,
    Stack,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    useMediaQuery,
    Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { StartIconBtn } from '../../../components';
import CreditCardIcon from '@mui/icons-material/CreditCard';

export default function PromotionsBattery() {

    const data = false;
    return (
        <Container
            maxWidth="100%"
            sx={{ paddingTop: '12px', paddingBottom: '12px' }}
        >
            <Typography
                variant="h5"
                component="h5"
                fontSize={"18px"}
                mt={"12px"}
                mb={"10px"}
            >
                FÖRDERUNGEN PV & BATTERIE
            </Typography>
            <Typography
                variant="p"
                component="p"
                sx={{
                    color: "#969696",
                }}
            >
                Die unten angezeigte Investitionsförderung wird automatisch berechnet
            </Typography>

            <Grid container>
                <Grid item lg={6} md={9} xs={12}>
                    <Box
                        mt={6}
                        p={2}
                        pb={4}
                        sx={{
                            backgroundColor: '#F8F8F8',
                            border: 1,
                            borderRadius: 1,
                            borderColor: '#A5A5A5',
                            boxShadow: '0px 3px 3px 0px #ccc',
                        }}
                    >
                        <Typography
                            variant="h5"
                            component="h5"
                            fontSize={"18px"}
                            mb={"14px"}
                        >
                            AUTOMATISCH BERECHNETE EINMALVERGÜTUNG
                        </Typography>
                        <Stack
                            direction='row'
                            justifyContent="space-between"
                            mt={1}
                            sx={{
                                paddingRight: { lg: '32px', md: '16px' }
                            }}
                        >
                            <Typography
                                variant="p"
                                component="p"
                                sx={{
                                    color: "#464646",
                                    fontFamily: 'Inter',
                                    fontSize: '15px',
                                }}
                            >
                                Peakleistung der Photovoltaikanlage
                            </Typography>
                            <Typography
                                variant="p"
                                component="p"
                                sx={{
                                    color: "#464646",
                                    fontFamily: 'Inter',
                                    fontSize: '15px',
                                }}
                            >
                                10.000 kWp
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            justifyContent="space-between"
                            mt={1}
                            sx={{
                                paddingRight: { lg: '32px', md: '16px' }
                            }}
                        >
                            <Typography
                                variant="p"
                                component="p"
                                sx={{
                                    color: "#464646",
                                    fontFamily: 'Inter',
                                    fontSize: '15px',
                                }}
                            >
                                Einmalvergütung (inkl. MWST)
                            </Typography>
                            <Typography
                                variant="p"
                                component="p"
                                sx={{
                                    color: "#464646",
                                    fontFamily: 'Inter',
                                    fontSize: '15px',
                                }}
                            >
                                10.000 kWp
                            </Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>

            <Box
                mt={6}
            >
                <Typography
                    variant="h5"
                    component="h5"
                    fontSize={"18px"}
                    mt={"12px"}
                    mb={"10px"}
                >
                    ZUSÄTZLICHE INVESTITIONSFORDERUNGEN
                </Typography>
                <Typography
                    variant="p"
                    component="p"
                    sx={{
                        color: "#969696",
                    }}
                >
                    Diese Forderungen werden bei der Wirtschaftlichkeitsberechnung berücksichtigt, wenn Sie die "Erweiterte Wirtschaftlichkeitsrechnung" aktivieren in Projekteinstellungen
                </Typography>
            </Box>
            <Box
                component="div"
                sx={{
                    maxWidth: "750px",
                    mt: "20px",
                    mb: "20px",
                }}
            >

                <TableContainer
                    sx={{
                        borderRadius: 1,
                        border: 1,
                        borderColor: '#BEBEBE'
                    }}
                >
                    <Table sx={{
                        minWidth: 650,
                        '& .MuiTableCell-root': {
                            paddingTop: '5px',
                            paddingBottom: '5px',
                        }
                    }}>
                        <TableHead>
                            <TableRow
                                sx={{
                                    backgroundColor: "#E6F5FE",
                                }}
                            >
                                <TableCell
                                    sx={{
                                        '& .MuiTableCell-root': {
                                            paddingY: '12px'
                                        }
                                    }}
                                    align="left">Förderungs-Bezeichnung</TableCell>
                                <TableCell
                                    sx={{
                                        '& .MuiTableCell-root': {
                                            paddingY: '12px'
                                        }
                                    }}
                                    align="center">Betrag inkl. MWST</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                !data ?
                                    <TableRow>
                                        <TableCell sx={{ padding: 0, height: '100px' }} colSpan={2} rowSpan={3}>
                                            <Stack
                                                justifyContent='center'
                                                alignItems='center'
                                                sx={{ width: '100%' }}
                                            >
                                                <Typography variant='subtitle2' component='subtitle2'>
                                                    Keine Forderung hinzugefügt
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                    :
                                    <TableRow
                                        sx={{
                                            backgroundColor: "#F9FDFF",
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                '& .MuiTableCell-root': {
                                                    paddingY: '12px'
                                                }
                                            }}
                                            align="left">
                                            <Typography variant="subtitle2" component="p">
                                                dsfghhdfghdfghdf
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                '& .MuiTableCell-root': {
                                                    paddingY: '12px'
                                                }
                                            }}
                                            align="center">
                                            <Typography variant="subtitle2" component="p">
                                                pauschal
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Stack
                direction="row"
                alignItems='center'

            >
                <Stack direction='row' spacing={2}>
                    <StartIconBtn icon={<AddIcon />} title='add component' />
                    <StartIconBtn icon={<CreditCardIcon />} title='Überblick aktuelle Förderungen' />
                </Stack>
            </Stack>

        </Container>
    )
}