import React, { useState } from "react";
import {
    Container,
    Table,
    TableCell,
    TableContainer,
    Box,
    TableRow,
    TableHead,
    Checkbox,
    Stack,
    Button,
    TableBody,
    Switch,
    IconButton,
    Typography,
    Popper,
    Grow,
    Paper,
    MenuList,
    MenuItem,
    ClickAwayListener,
} from "@mui/material";

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SettingsIcon from '@mui/icons-material/Settings';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useTranslation } from "react-i18next";

import PageTitle from "../../../hooks/page-title";
import { Header, UsePagination, FilterBar1, AddProjectBtn } from "../../../components";
import ProjectForm from "./project-form";
import RemoveProjectModal from "./remove-project-model";
import { Link, useNavigate } from "react-router-dom";

export default function Home() {
    PageTitle('Heim | GamaAg');

    const [page, setPage] = useState(1);
    // project modal
    const [modal1, setModal1] = useState(false);
    // remove project modal
    const [modal2, setModal2] = useState(false);
    const [recordId, setRecordId] = useState(null);
    const [checked, setChecked] = useState(false);
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleChange = () => {
        setChecked(!checked);
    }
    return (
        <>
            <Header />
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
                <FilterBar1 />
                <Box
                    pb={2}
                >
                    <UsePagination total={200} perPage={20} page={page} setPage={setPage} />
                </Box>
                <TableContainer>
                    <Table
                        sx={{
                            minWidth: '1450px',
                            '& .MuiTableCell-root': {
                                paddingTop: "5px",
                                paddingBottom: "5px",
                            }
                        }}
                    >
                        <TableHead>
                            <TableRow
                                sx={{
                                    backgroundColor: "#E6F5FE",
                                }}
                            >
                                <TableCell
                                    sx={{ width: "10px", paddingLeft: 0, paddingRight: 0 }}
                                >
                                    <Checkbox color="success" />
                                </TableCell>
                                <TableCell>
                                    <FilterLabel title={t("HOME.TABLE.Project_References")} />
                                </TableCell>
                                <TableCell>
                                    <FilterLabel title={t("HOME.TABLE.Project_Name")} />
                                </TableCell>
                                <TableCell>
                                    {t("HOME.TABLE.Technologies")}
                                </TableCell>
                                <TableCell>
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                    >
                                        <span
                                            style={{
                                                color: !checked ? '#969696' : '#000',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleChange}
                                        >
                                            {t("HOME.TABLE.To_sue")}
                                        </span>
                                        <Switch
                                            checked={checked}
                                            color="success"
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    {t("HOME.TABLE.Project_Manager")}
                                </TableCell>
                                <TableCell>
                                    {t("HOME.TABLE.Customer")}
                                </TableCell>
                                <TableCell>
                                    {t("HOME.TABLE.Building_Address")}
                                </TableCell>
                                <TableCell>
                                    <FilterLabel title={t("HOME.TABLE.Status")} />
                                </TableCell>
                                <TableCell>
                                    {t("HOME.TABLE.Projects")}
                                </TableCell>
                                <TableCell>
                                    <FilterLabel title={t("HOME.TABLE.Last_Updated")} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array(20).fill('').map((v, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell
                                            sx={{ width: "10px", paddingLeft: 0, paddingRight: 0 }}
                                        >
                                            <Checkbox color="success" />
                                        </TableCell>
                                        <TableCell>
                                            gam-00099
                                        </TableCell>
                                        <TableCell>
                                            <Link
                                                to={'/project/123456/overview'}
                                                state={{ id: '123456' }}
                                            >
                                                Loren Ipsum
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                            >
                                                <IconButton
                                                    sx={{
                                                        backgroundColor: '#000',
                                                        '&:hover': {
                                                            backgroundColor: '#ccc',
                                                        }
                                                    }}
                                                    size='small'
                                                    onClick={() => navigate('/bibliothek')}
                                                >
                                                    <SettingsIcon sx={{ color: '#fff' }} />
                                                </IconButton>
                                                {i % 2 ?
                                                    <IconButton
                                                        sx={{
                                                            backgroundColor: '#000',
                                                            '&:hover': {
                                                                backgroundColor: '#ccc',
                                                            }
                                                        }}
                                                        size='small'
                                                    >
                                                        <Battery4BarIcon sx={{ color: '#fff', transform: 'rotate(90deg)' }} />
                                                    </IconButton>
                                                    : null
                                                }
                                            </Stack>
                                        </TableCell>
                                        <TableCell />
                                        <TableCell>
                                            Robert Fox
                                        </TableCell>
                                        <TableCell>
                                            Annette Black
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                maxWidth: "150px"
                                            }}
                                        >
                                            6391 Elgin St. Celina, Delaware 10299
                                        </TableCell>
                                        <TableCell>
                                            Projekt erstelt
                                        </TableCell>
                                        <TableCell>
                                            Loren Ipsum
                                        </TableCell>
                                        <TableCell>
                                            <Box
                                                sx={{
                                                    position: "relative",
                                                    paddingRight: "25px",
                                                }}
                                            >
                                                8.16.2022
                                                <Typography
                                                    style={{
                                                        color: '#969696',
                                                        fontSize: '10px',
                                                    }}
                                                >
                                                    10:00 am
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        right: "0",
                                                        transform: "translateY(-50%)",
                                                        zIndex: 10
                                                    }}
                                                >
                                                    <DropDown />
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    pt={2}
                >
                    <UsePagination total={200} perPage={20} page={page} setPage={setPage} />
                    {/* <UsePagination total={records?.total} perPage={records?.per_page} page={page} setPage={setPage} /> */}
                </Box>
            </Container>
            {/* add project */}
            <AddProjectBtn handleClick={() => setModal1(true)} />
            <ProjectForm open={modal1} handleClose={() => setModal1(false)} />
            <RemoveProjectModal open={modal2} id={recordId} setId={setRecordId} handleClose={() => setModal2(false)} />
        </>
    )
}

const FilterLabel = ({ title }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                paddingRight: '25px'
            }}
        >
            {title}
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    transform: 'translateY(-50%)',
                }}
            >
                <Stack
                    direction='column'
                    width='25px'
                >
                    <Button
                        variant="text"
                        sx={{
                            minWidth: '10px',
                            height: '10px',
                        }}
                    >
                        <ArrowDropUpIcon sx={{ color: '#000' }} />
                    </Button>
                    <Button
                        variant="text"
                        sx={{
                            minWidth: '10px',
                            height: '10px',
                        }}
                    >
                        <ArrowDropDownIcon sx={{ color: '#000' }} />
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}

const DropDown = () => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === "Escape") {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);
    return (
        <>
            <Button
                variant="text"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                disableElevation
                onClick={handleToggle}
                ref={anchorRef}
                sx={{
                    color: '#000',
                    minWidth: '15px',
                }}
            >
                <MoreVertIcon />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                style={{
                    zIndex: 10,
                }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom-start" ? "left top" : "left bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem onClick={handleClose}>Link 1</MenuItem>
                                    <MenuItem onClick={handleClose}>Link 2</MenuItem>
                                    <MenuItem onClick={handleClose}>Link 3</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};