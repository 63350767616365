import React from 'react'

import { Box } from '@mui/material';

import { Header, SideNavigation, TopBar } from '../../components';

export default function ManagerLayout({ children }) {

    return (
        <>
            <Header />
            <TopBar />
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <SideNavigation />
                <main>
                    {children}
                </main>
            </Box>
        </>
    )
}