import React, { useState } from "react";
import {
    TableCell,
    Box,
    TableRow,
    Checkbox,
    Stack,
    Button,
    IconButton,
} from "@mui/material";

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SettingsIcon from '@mui/icons-material/Settings';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import CreateIcon from '@mui/icons-material/Create';

import { UsePagination, FilterBar1, AddProjectBtn, CustomModal, TableWrapper } from "../../../../components";
import { useNavigate } from "react-router-dom";
import ComponentForm from "./comp-form";
import API from "../../../../axios";

export default function ViewComponentsPanel({ switchScreen, ...props }) {
    const [createCompModal, setCreateCompModal] = useState(false);
    const [page, setPage] = useState(1);
    const [applyFilters, setApplyFilters] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        getComponents();
    }, [page, applyFilters]);

    const getComponents = async () => {
        setIsLoading(true);
        try {
            let { data } = await API(`manager/components?per_page=20&page=${page}`, 'get');
            setRecords(data);
            setIsLoading(false);
        } catch (error) {
            if (!!records) setRecords(null);
            setIsLoading(false);
        }
    }


    return (
        <Box>
            <FilterBar1 />
            {!!records && records?.last_page > 1 && (
                <Box component="div" sx={{ mt: 4 }}>
                    <UsePagination
                        total={records?.total}
                        perPage={records?.per_page}
                        page={page}
                        setPage={setPage}
                    />
                </Box>
            )}
            <TableWrapper
                thContent={
                    <>
                        <TableCell
                            sx={{ width: "10px", paddingLeft: 0, paddingRight: 0 }}
                        >
                            <Checkbox />
                        </TableCell>
                        <TableCell>
                            Component Type
                        </TableCell>
                        <TableCell>
                            <FilterLabel title='Name' />
                        </TableCell>
                        <TableCell>
                            <FilterLabel title='Item Number' />
                        </TableCell>
                        <TableCell>
                            Unit
                        </TableCell>
                        <TableCell>
                            Technologies
                        </TableCell>
                        <TableCell>
                            <FilterLabel title='Language' />
                        </TableCell>
                        <TableCell>
                            Tags
                        </TableCell>
                    </>
                }
                spanTd='8'
                isLoading={isLoading}
                // isContent={!!records && records?.data.length ? true : false}
                isContent={true}
            >
                {Array(4).fill('').map((v, i) => {
                    return (
                        <TableRow key={i}>
                            <TableCell
                                sx={{ width: "10px", paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Checkbox />
                            </TableCell>
                            <TableCell>
                                Component
                            </TableCell>
                            <TableCell>
                                Loren Ipsum
                            </TableCell>
                            <TableCell>
                                62
                            </TableCell>
                            <TableCell>
                                lorem
                            </TableCell>
                            <TableCell>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                >
                                    <IconButton
                                        sx={{
                                            backgroundColor: '#000',
                                            '&:hover': {
                                                backgroundColor: '#ccc',
                                            }
                                        }}
                                        size='small'
                                        onClick={() => navigate('/bibliothek')}
                                    >
                                        <SettingsIcon sx={{ color: '#fff' }} />
                                    </IconButton>
                                    {i % 2 ?
                                        <IconButton
                                            sx={{
                                                backgroundColor: '#000',
                                                '&:hover': {
                                                    backgroundColor: '#ccc',
                                                }
                                            }}
                                            size='small'
                                        >
                                            <Battery4BarIcon sx={{ color: '#fff', transform: 'rotate(90deg)' }} />
                                        </IconButton>
                                        : null
                                    }
                                </Stack>
                            </TableCell>
                            <TableCell>
                                Deitsch (CH)
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={() => switchScreen()}
                                >
                                    <CreateIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>)
                })}
            </TableWrapper>
            {!!records && records?.last_page > 1 && (
                <Box component="div" sx={{ mt: 4 }}>
                    <UsePagination
                        total={records?.total}
                        perPage={records?.per_page}
                        page={page}
                        setPage={setPage}
                    />
                </Box>
            )}
            {createCompModal ?
                <CustomModal width='850px' children={<ComponentForm />} handleClose={() => setCreateCompModal(false)} />
                :
                <AddProjectBtn handleClick={() => setCreateCompModal(true)} />
            }
        </Box>
    )
}

const FilterLabel = ({ title }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                paddingRight: '25px'
            }}
        >
            {title}
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    transform: 'translateY(-50%)',
                }}
            >
                <Stack
                    direction='column'
                    width='25px'
                >
                    <Button
                        variant="text"
                        sx={{
                            minWidth: '10px',
                            height: '10px',
                        }}
                    >
                        <ArrowDropUpIcon sx={{ color: '#000' }} />
                    </Button>
                    <Button
                        variant="text"
                        sx={{
                            minWidth: '10px',
                            height: '10px',
                        }}
                    >
                        <ArrowDropDownIcon sx={{ color: '#000' }} />
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}