
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    CircularProgress,
} from "@mui/material";
import { memo } from "react";

function TableWrapper({ tableStyle, spanTd, message, isLoading, isContent, thContent, ...props }) {
    return (
        <TableContainer>
            <Table sx={[tableStyle, {
                '& .MuiTableCell-root': {
                    paddingTop: "5px",
                    paddingBottom: "5px",
                }
            }]}>
                <TableHead>
                    <TableRow sx={{ backgroundColor: "#E6F5FE" }}>
                        {thContent}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ? (
                        <TableRow>
                            <TableCell colSpan={spanTd} align="center">
                                <CircularProgress size={22} />
                            </TableCell>
                        </TableRow>
                    ) : isContent ? (
                        props.children
                    ) : (
                        <TableRow>
                            <TableCell colSpan={spanTd} align="center">
                                <Typography variant="caption1">{!!message ? message : 'No Records Found'}</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
TableWrapper.defaultProps = {
    tableStyle: {},
    spanTd: '1',
    message: null,
    isContent: false,
    isLoading: false
}
const areEqual = (prevProps, nextProps) => {
    return (prevProps.isLoading === nextProps.isLoading);
}
export default memo(TableWrapper, areEqual);