import React from 'react';

import { Box, Collapse, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import BatteryCharging80Icon from '@mui/icons-material/BatteryCharging80';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LayersIcon from '@mui/icons-material/Layers';
import DownloadIcon from '@mui/icons-material/Download';
import ArticleIcon from '@mui/icons-material/Article';

import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu } from '../../store/reducer';

const _url = '/project/123456';

export default function SideNavigation() {
    const matches = useMediaQuery('(min-width:900px)');
    const anchorRef = React.useRef(null);
    const _openMenu = useSelector((state) => state.storeReducer.openMenu);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = () => {
        dispatch(toggleMenu(false));
    };

    const drawer = (
        <div>
            <List
                sx={{
                    '& .MuiListItemIcon-root': {
                        color: '#000'
                    }
                }}
            >
                {!matches ? <ListItem disablePadding>
                    <Box
                        component='div'
                        sx={{
                            display: "flex",
                            justifyContent: 'flex-end',
                            width: '100%',
                            paddingRight: '25px'
                        }}
                    >
                        <IconButton
                            onClick={handleClick}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </ListItem> : null}
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(_url + '/customer', { id: '123456' })}>
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('LAYOUT.ASIDE.Customer')} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(_url + '/project-installations', { id: '123456' })}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('LAYOUT.ASIDE.Project_Installations')} />
                    </ListItemButton>
                </ListItem>
                <CollapseItem1 />
                <Box
                    mt={2}
                    mb={2}
                >
                    <Divider />
                </Box>
                <CollapseItem2 />
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => navigate(_url + '/battery-storage')}
                    >
                        <ListItemIcon>
                            <BatteryCharging80Icon />
                        </ListItemIcon>
                        <ListItemText primary={t('LAYOUT.ASIDE.Battery_Storage')} />
                    </ListItemButton>
                </ListItem>
                <CollapseItem3 />
                <CollapseItem4 />
                <CollapseItem5 />
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => navigate(_url + '/patch-pv')}
                    >
                        <ListItemIcon>
                            <BatteryCharging80Icon />
                        </ListItemIcon>
                        <ListItemText primary={t('LAYOUT.ASIDE.Financing_Patch_PV')} />
                    </ListItemButton>
                </ListItem>
                <CollapseItem6 />
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => navigate(_url + '/ele-form-solar')}
                    >
                        <ListItemIcon>
                            <BatteryCharging80Icon />
                        </ListItemIcon>
                        <ListItemText primary={t('LAYOUT.ASIDE.ElectroForm_Solar')} />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );
    return (
        <>
            <div className={_openMenu ? 'overlay_ue0 open' : 'overlay_ue0'}
                onClick={handleClick}
            ></div>
            <aside
                className={_openMenu ? 'primary-navigation open' : 'primary-navigation'}
                ref={anchorRef}
            >
                {drawer}
            </aside></>
    );
}

const CollapseItem1 = () => {
    const [open, setOpen] = React.useState();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <ListItem disablePadding>
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemIcon>
                        <DownloadIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('LAYOUT.ASIDE.Initial_Position')} />
                    {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {[{ text: 'Building', url: `${_url}/building` }, { text: 'PV_System', url: `${_url}/pv-system` }].map((_item, _i) => (
                    <List component="div" key={_i} disablePadding>
                        <ListItemButton sx={{ pl: '72px', pt: 0, pb: 0 }}
                            onClick={() => navigate(_item.url)}
                        >
                            <ListItemText primary={t(`LAYOUT.ASIDE.${_item.text}`)} sx={{
                                '& .MuiTypography-root': {
                                    fontSize: '12px'
                                }
                            }} />
                        </ListItemButton>
                    </List>
                ))}
            </Collapse>
        </>
    );
}
const CollapseItem2 = () => {
    const [open, setOpen] = React.useState();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <ListItem disablePadding>
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('LAYOUT.ASIDE.PV')} />
                    {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {[{ text: 'Module_Layout', url: '/module-layout' }, { text: 'Inverter', url: '/inverter' }, { text: 'Power_Consumption', url: '/power-consumption' }, { text: 'Electricity_Tariffs', url: '/electricity-tariffs' }].map((_item, _i) => (
                    <List component="div" key={_i} disablePadding>
                        <ListItemButton sx={{ pl: '72px', pt: 0, pb: 0 }}
                            onClick={() => navigate(`${_url + _item.url}`)}
                        >
                            <ListItemText primary={t(`LAYOUT.ASIDE.${_item.text}`)} sx={{
                                '& .MuiTypography-root': {
                                    fontSize: '12px'
                                }
                            }} />
                        </ListItemButton>
                    </List>
                ))}
            </Collapse>
        </>
    );
}
const CollapseItem3 = () => {
    const [open, setOpen] = React.useState();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <ListItem disablePadding>
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemIcon>
                        <LocalOfferIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('LAYOUT.ASIDE.Requirements')} />
                    {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: '72px', pt: 0, pb: 0 }} onClick={() => navigate(`${_url}/pv-batteries`)}>
                        <ListItemText primary={t('LAYOUT.ASIDE.PV_Batteries')} sx={{
                            '& .MuiTypography-root': {
                                fontSize: '12px'
                            }
                        }} />
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    );
}
const CollapseItem4 = () => {
    const [open, setOpen] = React.useState();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <ListItem disablePadding>
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemIcon>
                        <TrendingUpIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('LAYOUT.ASIDE.Economics')} />
                    {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {[{ text: 'PV', url: '/eco-pv' }, { text: 'Batteries_System', url: '/eco-battery-system' }].map((_item, _i) => (
                    <List component="div" key={_i} disablePadding>
                        <ListItemButton sx={{ pl: '72px', pt: 0, pb: 0 }} onClick={() => navigate(`${_url + _item.url}`)}>
                            <ListItemText primary={t(`LAYOUT.ASIDE.${_item.text}`)} sx={{
                                '& .MuiTypography-root': {
                                    fontSize: '12px'
                                }
                            }} />
                        </ListItemButton>
                    </List>
                ))}
            </Collapse>
        </>
    );
}
const CollapseItem5 = () => {
    const [open, setOpen] = React.useState();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <ListItem disablePadding>
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemIcon>
                        <LayersIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('LAYOUT.ASIDE.Parts_List')} />
                    {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {[{ text: 'Overview', url: '/parts-overiew' }, { text: 'PV', url: '/parts-pv' }, { text: 'Batteries_System', url: '/batteries-systerm' }, { text: 'Recurring_Cost', url: '/recurring-cost' }].map((_item, _i) => (
                    <List component="div" key={_i} disablePadding>
                        <ListItemButton sx={{ pl: '72px', pt: 0, pb: 0 }}
                            onClick={() => navigate(`${_url + _item.url}`)}
                        >
                            <ListItemText primary={t(`LAYOUT.ASIDE.${_item.text}`)} sx={{
                                '& .MuiTypography-root': {
                                    fontSize: '12px'
                                }
                            }} />
                        </ListItemButton>
                    </List>
                ))}
            </Collapse>
        </>
    );
}

const CollapseItem6 = () => {
    const [open, setOpen] = React.useState();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <ListItem disablePadding>
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemIcon>
                        <LayersIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('LAYOUT.ASIDE.Project_Analysis')} />
                    {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: '72px', pt: 0, pb: 0 }}
                        onClick={() => navigate(_url + '/overview', { id: '123456' })}
                    >
                        <ListItemText primary={t('LAYOUT.ASIDE.Overview')} sx={{
                            '& .MuiTypography-root': {
                                fontSize: '12px'
                            }
                        }} />
                    </ListItemButton>
                </List>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: '72px', pt: 0, pb: 0 }}
                        onClick={() => navigate(_url + '/cost-analysis', { id: '123456' })}
                    >
                        <ListItemText primary={t('LAYOUT.ASIDE.Costs_Analysis')} sx={{
                            '& .MuiTypography-root': {
                                fontSize: '12px'
                            }
                        }} />
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    );
}