import React, { useState } from "react";

import PageTitle from "../../../../hooks/page-title";
import LibraryLayout from "../library-layout";
import EditGroupPanel from "./edit-group-panel";
import ViewGroupPanel from "./view-group-panel";

export default function GroupPanel() {
    PageTitle('Groups');   
    const [showEdit,setShowEdit]= useState(false);

    const switchScreen=()=>setShowEdit(!showEdit)

    return (
        <LibraryLayout>
            {showEdit?
                <EditGroupPanel switchScreen={switchScreen}/>
                :
                <ViewGroupPanel switchScreen={switchScreen}/>
            }
        </LibraryLayout>
    )
}