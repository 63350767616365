import React, { useState } from 'react';
import {
    Box,
    Typography,
    Container,
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Tabs,
    Tab,
    Grid,
    Paper,
} from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import PageTitle from '../../../hooks/page-title';
import { InputField, SaveChangesBtn } from '../../../components';

export default function PowerConsumption() {
    PageTitle('Power Consumption');
    const [activeTab, setActiveTab] = useState('DEFAULT_LIBRARY');

    const handleTabs = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Container
            maxWidth="100%"
            sx={{ paddingTop: '12px', paddingBottom: '12px',position: 'relative' }}
        >
            <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                    <Typography
                        variant="h6"
                        component="h6"
                        mt={"12px"}
                        mb={"10px"}
                    >
                        POWER CONSUMPTION
                    </Typography>
                    <Typography
                        variant="p"
                        component="p"
                        sx={{
                            color: "#969696",
                            mb: 4
                        }}
                    >
                        Choose a suitable consumption profile for the simulation or import your own profile
                    </Typography>
                    <InputField
                        label='annual consumption'
                        initValue='9’000 kWh'
                    />
                    <small>Default value: 11808 kWh</small>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Box component={Paper} sx={cardStyle}>
                        <Typography>OVERVIEW</Typography>
                        <table width='100%'>
                            <tbody>
                                <tr>
                                    <td>
                                        <small>Those</small>
                                    </td>
                                    <td>
                                        <small>Startdarbibliothek</small>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <small>building type</small>
                                    </td>
                                    <td>
                                        <small>detached house</small>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <small>heating system</small>
                                    </td>
                                    <td>
                                        <small>heat pump</small>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <small>domestic water system</small>
                                    </td>
                                    <td>
                                        <small>Electric boiler</small>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ width: '100%', mt: 3, borderBottom: '1px solid #ccc' }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabs}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="secondary tabs example"
                    sx={{ '& .MuiTabs-flexContainer': { overflowX: 'auto' } }}
                >
                    <Tab value="DEFAULT_LIBRARY" label="DEFAULT LIBRARY" sx={{ textTransform: 'none' }} />
                    <Tab value="CSV_IMPORT" label="CSV IMPORT" sx={{ textTransform: 'none' }} />
                </Tabs>
            </Box>
            <Box sx={{ display: activeTab === 'DEFAULT_LIBRARY' ? 'block' : 'none' }} >
                <Table sx={{ mt: 4 }}>
                    <TableHead>
                        <TableRow
                            sx={{
                                backgroundColor: "#E6F5FE",
                            }}
                        >
                            <TableCell>Active</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>building type</TableCell>
                            <TableCell>heating system</TableCell>
                            <TableCell>domestic water system</TableCell>
                            <TableCell>annual consumption</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array(5).fill('').map(() => {
                            return (
                                <TableRow>
                                    <TableCell>
                                        <IconButton>
                                            <RadioButtonUncheckedIcon color='success' />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>EFH electric heating - electric boiler</TableCell>
                                    <TableCell>detached house</TableCell>
                                    <TableCell>Electric heating</TableCell>
                                    <TableCell>Electric boiler</TableCell>
                                    <TableCell>18'930 kWh</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
            <Box sx={{ display: activeTab === 'CSV_IMPORT' ? 'block' : 'none' }} >
                <></>
            </Box>
            <SaveChangesBtn />
        </Container >
    )
}

const cardStyle = { '& p': { marginBottom: '15px' }, '& td': { padding: '5px' }, '& td:last-child': { color: '#969696' }, p: 2 }