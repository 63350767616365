import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { openPopUp } from "../../../store/reducer";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Switch,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { CustomModal, InputField, SearchComboBox } from "../../../components";
import API from "../../../axios";
import ApiImage from "../../../hooks/fetch-image";

export default function UpdateModels({
  open,
  id,
  setId,
  manufacturerId,
  setManufacturerId,
  handleClose,
  getData = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    manufacturerId: 0,
    manufacturer: "",
    status: true,
    image: "",
    _method: "patch",
  });

  const [formErrors, setFormErrors] = React.useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    getModels();
  }, []);

  const getModels = async () => {
    setGetLoading(true);
    try {
      const res = await API(
        `admin/manufacturers/${manufacturerId}/modals/${id}`,
        "get"
      );
      setFormData({
        ...formData,
        title: res?.data?.title,
        status: res?.data?.active,
        manufacturerId: res?.data?.manufacturer_id,
        manufacturer: res?.data?.manufacturers?.manufacturer,
      });
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      let obj = {
        message: err?.response?.message,
        type: "error",
      };
      dispatch(openPopUp(obj));
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("_method", "patch");
    fd.append("title", formData.title);
    var status = formData.status ? "1" : "0";
    fd.append("status", status);
    if (formData.image !== "") fd.append("image", formData.image);
    setIsLoading(true);
    let errObj = {};
    let obj = {};
    setFormErrors(errObj);
    const res = await API(
      `admin/manufacturers/${formData.manufacturerId}/modals/${id}`,
      "post",
      fd
    );
    try {
      setIsLoading(false);
      obj = {
        message: "Model has been updated.",
        type: "success",
      };
      dispatch(openPopUp(obj));
      getData();
      handleClose(false);
    } catch (err) {
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        obj = {
          message: err?.response?.message,
          type: "error",
        };
        dispatch(openPopUp(obj));
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        handleClose={() => {
          handleClose();
          setId(null);
          setManufacturerId(null);
        }}
      >
        <Container maxWidth="100%">
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
              Update Model
            </Typography>
          </Box>
          {getLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={30} />
            </Box>
          ) : (
            <form autoComplete="off" onSubmit={handleForm}>
              <Box mt={2} mb={3}>
                <InputField
                  placeholder="Title"
                  size="small"
                  label="Title"
                  required
                  fullWidth
                  styles={{ mb: 2 }}
                  name="title"
                  value={formData?.title}
                  error={formErrors?.title}
                  handleChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                />

                <SearchComboBox
                  initValue={formData.manufacturer}
                  url={"admin/manufacturers?active=active&"}
                  fullWidth
                  required={false}
                  objkey="id"
                  objLabel="manufacturer"
                  error={formErrors?.manufacturer}
                  size="small"
                  handleChange={(e) => {
                    setFormData({
                      ...formData,
                      manufacturer: e.manufacturer,
                      manufacturerId: e.id,
                    });
                  }}
                  label="Manufacturer"
                />
                <ApiImage
                  url={`modal-image/${id}`}
                  styles={{
                    width: "350px",
                    height: "200px",
                    marginTop: "10px",
                    borderRadius: "5px",
                  }}
                />
                <FormControl variant="standard">
                  <Typography component="label" variant="label" mt={2}>
                    change image{" "}
                  </Typography>
                  <input
                    name="image"
                    onChange={(e) =>
                      setFormData({ ...formData, image: e.target.files[0] })
                    }
                    type="file"
                    accept="image/*"
                  />
                </FormControl>
                {formErrors?.image !== "" && (
                  <FormHelperText sx={{ color: "red", mt: "0 !important" }}>
                    {formErrors?.image}
                  </FormHelperText>
                )}
                <Box mt={2}>
                  <label>Status</label>
                  <br />
                  <small>Disabled</small>
                  <Switch
                    checked={formData.status}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        status: e.target.checked ? true : false,
                      })
                    }
                    color="success"
                  />
                  <small>Active</small>
                </Box>
                <Box sx={{ textAlign: "end" }}>
                  <Button
                    variant="contained"
                    onClick={() => {}}
                    sx={{
                      mt: 2,
                    }}
                    disabled={isLoading}
                    type="submit"
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={18}
                        sx={{ color: "#fff", mr: 1, size: "12px" }}
                      />
                    ) : null}
                    Update
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Container>
      </CustomModal>
    </>
  );
}

