import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { openPopUp } from "../../../store/reducer";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { CustomModal, InputField, SearchComboBox } from "../../../components";
import API from "../../../axios";
import ComponentType from "../../../components/component-type/component-types";
const modalInfoFields = [
  {
    label: "Group",
    name: "group",
  },
  {
    label: "Language",
    name: "language",
  },
  {
    label: "Label",
    name: "label",
  },
  {
    label: "Value",
    name: "value",
  },
  {
    label: "Description",
    name: "description",
  },
];

export default function CreateModelInfo({
  handleClose,
  getData = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    modal_id: 0,
    modal: '',
    group: "",
    language: "",
    label: "",
    value: "",
    description: "",
  });
  const [formErrors, setFormErrors] = React.useState({});

  const dispatch = useDispatch();

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let errObj = {};
    let obj = {};
    setFormErrors(errObj);
    try {
      const res = await API(`admin/modalInformations`, "post", formData);
      setIsLoading(false);
      obj = {
        message: "Model Info has been added.",
        type: "success",
      };
      dispatch(openPopUp(obj));
      getData();
      handleClose(false);
    } catch (err) {
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        obj = {
          message: err?.response?.message,
          type: "error",
        };
        dispatch(openPopUp(obj));
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        handleClose={() => {
          handleClose();
        }}
      >
        <Container maxWidth="100%">
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
              Add Model Info
            </Typography>
          </Box>
          <form autoComplete="off" onSubmit={handleForm}>
            <Box mt={2} mb={3}>
              <SearchComboBox
                initValue={formData.modal}
                url={"admin/modals?active=active&"}
                fullWidth
                required={true}
                objkey="id"
                objLabel="title"
                size="small"
                handleChange={(e) => {
                  setFormData({
                    ...formData,
                    modal: e.title,
                    modal_id: e.id,
                  });
                }}
                label="Model"
                styles={{ mb: 2 }}
              />
              {modalInfoFields.map((field, index) => {
                return (
                  <Fragment key={index}>
                    <InputField
                      placeholder={field.label}
                      size="small"
                      label={field.label}
                      name={field.name}
                      error={formErrors?.[field.name]}
                      required
                      fullWidth
                      styles={{ mb: 2 }}
                      handleChange={(e) =>
                        setFormData({
                          ...formData,
                          [field.name]: e.target.value,
                        })
                      }
                    />
                  </Fragment>
                );
              })}
              <Box sx={{ textAlign: "end" }}>
                <Button
                  variant="contained"
                  onClick={() => {}}
                  sx={{
                    mt: 2,
                  }}
                  disabled={isLoading}
                  type="submit"
                >
                  {isLoading ? (
                    <CircularProgress
                      size={18}
                      sx={{ color: "#fff", mr: 1, size: "12px" }}
                    />
                  ) : null}
                  Create
                </Button>
              </Box>
            </Box>
          </form>
        </Container>
      </CustomModal>
    </>
  );
}
