import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { openPopUp } from "../../../store/reducer";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Switch,
  Typography,
} from "@mui/material";

import { CustomModal, InputField, SearchComboBox } from "../../../components";
import API from "../../../axios";

export default function UpdateManufacturer({
  id,
  setId,
  componentId,
  setComponentId,
  handleClose,
  getData = () => {},
}) {
  console.log("🚀 ~ file: update-manufacturer.js:30 ~ componentId", componentId)
  const [isLoading, setIsLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);

  const [formData, setFormData] = useState({
    manufacturer: "",
    componentId: 0,
    component: "",
    status: true,
    _method: "patch",
  });

  const [formErrors, setFormErrors] = React.useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    getManufacturer();
  }, []);

  const getManufacturer = async () => {
    setGetLoading(true);
    try {
      const res = await API(
        `admin/component-types/${componentId}/manufacturers/${id}`,
        "get"
      );
      setFormData({
        ...formData,
        manufacturer: res?.data?.manufacturer,
        status: res?.data?.active,
        componentId: res?.data?.component_type_id,
        component: res?.data?.componenttypes?.title,
      });
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      let obj = {
        message: err?.response?.message,
        type: "error",
      };
      dispatch(openPopUp(obj));
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let errObj = {};
    let obj = {};
    setFormErrors(errObj);
    try {
      const res = await API(
        `admin/component-types/${formData.componentId}/manufacturers/${id}`,
        "post",
        formData
      );
      setIsLoading(false);
      obj = {
        message: "Manufacturer has been updated.",
        type: "success",
      };
      dispatch(openPopUp(obj));
      getData();
      handleClose(false);
    } catch (err) {
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        obj = {
          message: err?.response?.message,
          type: "error",
        };
        dispatch(openPopUp(obj));
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        handleClose={() => {
          handleClose();
          setId(null);
          setComponentId(null);
        }}
      >
        <Container maxWidth="100%">
          
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
              Update Manufacturer
            </Typography>
          </Box>
          {getLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={30} />
            </Box>
          ) : (
            <form autoComplete="off" onSubmit={handleForm}>
              <Box mt={2} mb={3}>
                <InputField
                  placeholder="Title"
                  size="small"
                  label="Title"
                  required
                  fullWidth
                  styles={{ mb: 2 }}
                  name="manufacturer"
                  value={formData?.manufacturer}
                  error={formErrors?.manufacturer}
                  handleChange={(e) =>
                    setFormData({ ...formData, manufacturer: e.target.value })
                  }
                />
                <SearchComboBox
                  initValue={formData.component}
                  url={"admin/component-types?active=active&"}
                  fullWidth
                  required={false}
                  objkey="id"
                  objLabel="title"
                  size="small"
                  handleChange={(e) => {
                    setFormData({
                      ...formData,
                      component: e.title,
                      componentId: e.id,
                    });
                  }}
                  label="Component Type"
                  styles={{ mb: 2 }}
                />
                <Box>
                  <label>Status</label>
                  <br />
                  <small>Disabled</small>
                  <Switch
                    checked={formData.status}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        status: e.target.checked ? 1 : 0,
                      })
                    }
                    color="success"
                  />
                  <small>Active</small>
                </Box>
                <Box sx={{ textAlign: "end" }}>
                  <Button
                    variant="contained"
                    onClick={() => {}}
                    sx={{
                      mt: 2,
                    }}
                    disabled={isLoading}
                    type="submit"
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={18}
                        sx={{ color: "#fff", mr: 1, size: "12px" }}
                      />
                    ) : null}
                    Update
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Container>
      </CustomModal>
    </>
  );
}

