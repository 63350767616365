import React from "react";
import {
    useMediaQuery,
    Box,
    Stack,
    Button,
    IconButton,
    Typography,
    Modal,
    CircularProgress,
    Backdrop,
    Snackbar,
    Grid,
    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Tooltip,
    Switch,
    FormGroup
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

import { InputField, SelectBox } from "../../../../components";

export default function CreatePromoForm({
    open = false,
    handleClose = () => { },
    submitForm = () => { } }) {

    const [openDrop, setOpenDrop] = React.useState(false);
    const [showMessage, setShowMessage] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const matches = useMediaQuery('(min-width:900px)');

    const handleForm = (e) => {
        e.preventDefault();
        submitForm();
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflowY: 'auto',
                }}
            >
                <Box sx={matches ? modalStyle : modalStyle2}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <IconButton
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography variant="h6" component="h5">
                        CREATE A NEW LOCAL PROMOTION
                    </Typography>
                    <Box sx={{ borderBottom: '1px solid #ccc', mt: 2 }}>
                        <Typography
                            variant='p'
                            component='p'
                            sx={{ borderBottom: '1px solid', pb: 1, borderColor: 'primary.main', width: 'fit-content' }}
                        >
                            DEUTCH (CH)
                        </Typography>
                    </Box>
                    {/* Form */}
                    <Box
                        component='form'
                        noValidate
                        autoComplete="off"
                        onSubmit={handleForm}
                        sx={{ mt: 2, mb: 4 }}
                    >
                        <Typography
                            variant='p'
                            component='p'
                            sx={{ mt: 3, mb: 3 }}
                        >
                            DEFINITION PROMOTION
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <InputField
                                    label='Name'
                                    initValue='Support for My city'
                                    size='small'
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <InputField
                                    label='Description of the grant (for internal use only)'
                                    initValue='Internal note'
                                    size='small'
                                    required
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item md={4} xs={12}>
                                <SelectBox
                                    items={['size dependency', 'battery storage', 'energy manager', 'Photovoltaikmodul', 'substructure', 'inverter', 'recurring costs']}
                                    size='small'
                                    label='Typ'
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <SelectBox
                                    items={['Battery', 'battery storage', 'energy manager', 'Photovoltaikmodul', 'substructure', 'inverter', 'recurring costs']}
                                    size='small'
                                    label='Technology'
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <SelectBox
                                    items={['-', 'battery storage', 'energy manager', 'Photovoltaikmodul', 'substructure', 'inverter', 'recurring costs']}
                                    size='small'
                                    label='size dependency'
                                    required
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item md={4} xs={12}>
                                <InputField
                                    initValue='1.00 CHF/|'
                                    size='small'
                                    label='value per unit'
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputField
                                    initValue='Min Amount|'
                                    size='small'
                                    label='Min Amount'
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputField
                                    initValue='Max Amount'
                                    size='small'
                                    label='Max amount'
                                    required
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Divider sx={{ mb: 2, mt: 2 }} />
                        <Typography
                            variant='p'
                            component='p'
                            sx={{ mt: 2, mb: 3 }}
                        >
                            APPLICATION CRITERIA
                        </Typography>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item md={4} xs={12}>
                                <SelectBox
                                    items={['size dependency', 'battery storage', 'energy manager', 'Photovoltaikmodul', 'substructure', 'inverter', 'recurring costs']}
                                    size='small'
                                    label='building age'
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputField
                                    label='Minimum system size'
                                    initValue='Min Systemgrosse'
                                    size='small'
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputField
                                    label='Max system size'
                                    initValue='Max Systemgrosse'
                                    size='small'
                                    required
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item md={4} sm={12}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Kundtype</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="Private" control={<Radio color="success" />} label="Private" />
                                        <FormControlLabel value="Business" control={<Radio color="success" />} label="Business" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12} sx={{ mt: 2 }}>
                                <SelectBox
                                    items={['size dependency', 'battery storage', 'energy manager', 'Photovoltaikmodul', 'substructure', 'inverter', 'recurring costs']}
                                    size='small'
                                    label='building age'
                                    required
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Stack
                            direction='row'
                            alignItems='center'
                            spacing={2}
                            sx={{ mt: 3 }}
                        >
                            <Typography variant="h6" component="h5">
                                Auto Application
                            </Typography>
                            <Tooltip title="information">
                                <InfoIcon sx={{ opacity: 0.5 }} />
                            </Tooltip>
                        </Stack>
                        <Box>
                            <FormGroup>
                                <FormControlLabel control={<Switch color="success" defaultChecked />} label="Automatically applied in the calculator" />
                                <FormControlLabel control={<Switch color="success" />} label="Automatically applied in Expert" />
                            </FormGroup>
                        </Box>
                        <Stack
                            direction='row'
                            spacing={1}
                            mt={7}
                        >
                            <Button
                                type="submit"
                                sx={{
                                    backgroundColor: '#000',
                                    '&:hover': {
                                        backgroundColor: '#000',
                                    },
                                    ...btn
                                }}
                            >
                                Funding investment
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#D71B1B',
                                    '&:hover': {
                                        backgroundColor: '#D71B1B',
                                    },
                                    ...btn
                                }}
                                onClick={handleClose}
                            >
                                Abort
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Modal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999999 }}
                open={openDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={showMessage}
                autoHideDuration={6000}
                onClose={() => setShowMessage(false)}
                message={message}
            />
        </>
    );
}

const btn = {
    color: '#fff',
    textTransform: 'none',
    paddingLeft: '25px',
    paddingRight: '25px',
}
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '800px',
    bgcolor: '#fff',
    border: '0',
    borderRadius: '7px',
    boxShadow: 24,
    p: 4,
    zIndex: 100
};

const modalStyle2 = {
    width: '100%',
    maxWidth: '100%',
    bgcolor: '#fff',
    border: '0',
    borderRadius: '7px',
    boxShadow: 24,
    p: 4,
    zIndex: 100
};