import React, { useState } from "react";

import PageTitle from "../../../../hooks/page-title";
import LibraryLayout from "../library-layout";
import EditProjectTemplatePanel from "./edit-project-template-panel";
import ViewProjectTemplatePanel from "./view-project-template-panel";

export default function ProjectTemplatePanel() {
    PageTitle('Project Template');
    const [showEdit, setShowEdit] = useState(false);

    const switchScreen = () => setShowEdit(!showEdit)

    return (
        <LibraryLayout>
            {showEdit ?
                <EditProjectTemplatePanel switchScreen={switchScreen} />
                :
                <ViewProjectTemplatePanel switchScreen={switchScreen} />
            }
        </LibraryLayout>
    )
}