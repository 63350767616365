import React from "react";
import {
    Box,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
} from "@mui/material";

import { FilterBar2, FilterLabel } from "../../../../components";

export default function ViewMainTemplatePanel({ switchScreen, ...props }) {
    return (
        <Box>
            <FilterBar2 type='2' />
            <Box maxWidth='800px' sx={{ mt: 3 }}>
                <TableContainer>
                    <Table
                        sx={{
                            minWidth: 650,
                            '& .MuiTableCell-root': {
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }
                        }}
                    >
                        <TableHead>
                            <TableRow
                                sx={{
                                    backgroundColor: "#E6F5FE",
                                }}
                            >
                                <TableCell><FilterLabel title='component type' /></TableCell>
                                <TableCell>Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>battery storage</TableCell>
                                <TableCell>Battery storage including installation</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    sx={{ mt: 4 }}
                    variant='contained'
                    onClick={() => switchScreen()}
                >
                    Add component
                </Button>
            </Box>
        </Box>
    )
}