import React from "react";
import {
    Box,
    Paper,
    Button,
    Popper,
    Grow,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Stack,
    Typography,
    Menu
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import useMediaQuery from '@mui/material/useMediaQuery';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useTranslation } from "react-i18next";

import { logo2 } from "../../assets";
import { useNavigate } from "react-router-dom";

export default function TopBar() {
    const medium = useMediaQuery('(min-width:900px)');
    const sm = useMediaQuery('(min-width:600px)');
    const { t } = useTranslation();

    return (
        <Box
            component='div'
            sx={{
                padding: '10px 25px',
                boxShadow: '0 0 15px rgba(0, 0, 0, 0.151)',
                clipPath: 'inset(0px 0px -150px 0px)',
                position: 'relative',
                zIndex: 1
            }}
        >
            <Stack
                direction={{ xs: 'column-reverse', md: 'row' }}
                alignItems='center'
                justifyContent='space-between'
                spacing='10px'
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent={{ xs: 'center', md: 'flex-start' }}
                    spacing='10px'
                    sx={!medium ? {
                        width: '100%'
                    } : {}}
                >
                    <DropDown />
                    <Box
                        component='div'
                        style={{
                            display: 'block',
                            width: '1px',
                            height: '15px',
                            marginLeft: '0px',
                            backgroundColor: '#969696'
                        }}
                    ></Box>
                    {sm ?
                        <BtnGroup />
                        :
                        <BasicMenu />
                    }
                </Stack>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    spacing='10px'
                    sx={!medium ? {
                        width: '100%'
                    } : {}}
                >
                    <Typography
                        sx={{
                            width: '150px',
                            fontSize: '14px',
                        }}
                    >
                        {t('LAYOUT.TOP_BAR.Status')}:
                    </Typography>
                    <img src={logo2} width='65px' alt='' />
                </Stack>
            </Stack>
        </Box>
    );
}

const BtnGroup = () => {
    const sm = useMediaQuery('(min-width:600px)');
    const { t } = useTranslation();
    const themeBtn = {
        color: "#000",
        textTransform: "none",
        fontWeight: "400",
        justifyContent: "space-between",
    }
    const _url = '/project/123456';
    const navigate=useNavigate();

    return (
        <Box
            sx={!sm ? {
                display: 'flex',
                flexDirection: 'column',
            } : {}}
        >
            <Button
                variant="text"
                disableElevation
                sx={themeBtn}
            >
                {t('LAYOUT.TOP_BAR.Variants')}
            </Button>
            <Button
                variant="text"
                disableElevation
                sx={themeBtn}
                onClick={()=>navigate(_url+'/documents')}
            >
                {t('LAYOUT.TOP_BAR.Document')}
            </Button>
            <Button
                variant="text"
                disableElevation
                sx={themeBtn}
            >
                {t('LAYOUT.TOP_BAR.Deal_History')}
            </Button>
        </Box>
    );
}
const BasicMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                variant="text"
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    color: '#000'
                }}
            >
                <MoreVertIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <BtnGroup />
            </Menu>
        </div>
    );
}

const DropDown = () => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const { t } = useTranslation();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === "Escape") {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);
    return (
        <>
            <Button
                variant="text"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                disableElevation
                onClick={handleToggle}
                ref={anchorRef}
                endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                sx={{
                    color: "#000",
                    textTransform: "none",
                    fontWeight: "400",
                    justifyContent: "space-between",
                }}
            >
                {t('LAYOUT.TOP_BAR.Bohemia_p_s')}
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{
                    zIndex: 1
                }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom-start" ? "left top" : "left bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                    sx={{
                                        width: "200px",
                                    }}
                                >
                                    <MenuItem onClick={handleClose}>Link 1</MenuItem>
                                    <MenuItem onClick={handleClose}>Link 2</MenuItem>
                                    <MenuItem onClick={handleClose}>Link 3</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
