import React from 'react'
import {
    Box,
    Container,
    Typography,
    Stack
} from '@mui/material';
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import SquareIcon from '@mui/icons-material/Square';

import PageTitle from '../../../hooks/page-title';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
    ({ theme }) => ({
        backgroundColor: "#E6F5FE",
        color: "#464646",
        "& .MuiAccordionSummary-content": {
            justifyContent: "space-between",
        },
        "& .Mui-expanded p": {
            fontWeight: "600",
            color: "#000000",
        },
    })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));


export default function CostAnalysis() {
    PageTitle('Kostenanalyse');
    const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Container
            maxWidth="100%"
            sx={{ paddingTop: "15px", paddingBottom: "15px" }}
        >
            <Typography
                variant="h5"
                component="h5"
                fontSize={"18px"}
                mt={"12px"}
                mb={"10px"}
                sx={{ fontWeight: 600 }}
            >
                COST ANALYSIS
            </Typography>
            <Typography
                variant="p"
                component="p"
                fontSize={"16px"}
                mt={"12px"}
                mb={"10px"}
                sx={{ fontWeight: 300, color: "#969696" }}
            >
                Last updated: October 05, 2022 18:42
            </Typography>
            <Box
                component="div"
                sx={{
                    maxWidth: "750px",
                    minWidth: "500px",
                    mt: "20px",
                    mb: "20px",
                }}
            >
                <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                >
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                    >
                        <Typography>Designation</Typography>
                        <Typography>Total price</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            sx={{
                                '& td': {
                                    p: "10px",
                                    borderBottom: "1px solid rgba(0, 0, 0, .125)",
                                },
                                '& tr:last-child td': {
                                    borderBottom: 'none',
                                }
                            }}
                        >
                            <table width={"100%"}>
                                <tbody>
                                    <tr>
                                        <td align="left">
                                            <Stack direction='row' spacing={1}>
                                                <SquareIcon sx={{ color: '#DFFFC6' }} />
                                                <Typography color="#464646">
                                                    Order total before discount
                                                </Typography>
                                            </Stack>
                                        </td>
                                        <td align="right">
                                            <Typography color="#464646">
                                                47039 CHF
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="left">
                                            <Stack direction='row' spacing={1}>
                                                <SquareIcon sx={{ color: '#EBCCF5B0' }} />
                                                <Typography color="#464646">
                                                    total discount
                                                </Typography>
                                            </Stack>
                                        </td>
                                        <td align="right">
                                            <Typography color="#464646">
                                                8’555 CHF
                                            </Typography>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                >
                    <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                    >
                        <Typography>Order total after discount</Typography>
                        <Typography>38’484 CHF</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            sx={{
                                '& td': {
                                    p: "10px",
                                    borderBottom: "1px solid rgba(0, 0, 0, .125)",
                                },
                                '& tr:last-child td': {
                                    borderBottom: 'none',
                                }
                            }}
                        >
                            <table width={"100%"}>
                                <tbody>
                                    <tr>
                                        <td align="left">
                                            <Stack direction='row' spacing={1}>
                                                <SquareIcon sx={{ color: '#FF9D9D' }} />
                                                <Typography color="#464646">
                                                    Cost
                                                </Typography>
                                            </Stack>
                                        </td>
                                        <td align="right">
                                            <Typography color="#464646">
                                                47039 CHF
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="left">
                                            <Stack direction='row' spacing={1}>
                                                <SquareIcon sx={{ color: '#F1DBF8' }} />
                                                <Typography color="#464646">
                                                    contribution margin
                                                </Typography>
                                            </Stack>
                                        </td>
                                        <td align="right">
                                            <Typography color="#464646">
                                                8’555 CHF
                                            </Typography>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                >
                    <AccordionSummary
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                    >
                        <Typography>Total Investmentkosten inkl. MWST</Typography>
                        <Typography>45’551.50 CHF</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            sx={{
                                '& td': {
                                    p: "10px",
                                    borderBottom: "1px solid rgba(0, 0, 0, .125)",
                                },
                                '& tr:last-child td': {
                                    borderBottom: 'none',
                                }
                            }}
                        >
                            <table width={"100%"}>
                                <tbody>
                                    <tr>
                                        <td align="left">
                                            <Stack direction='row' spacing={1}>
                                                <SquareIcon sx={{ color: '#FF9D9D' }} />
                                                <Typography color="#464646">
                                                    Cost
                                                </Typography>
                                            </Stack>
                                        </td>
                                        <td align="right">
                                            <Typography color="#464646">
                                                47039 CHF
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="left">
                                            <Stack direction='row' spacing={1}>
                                                <SquareIcon sx={{ color: '#F1DBF8' }} />
                                                <Typography color="#464646">
                                                    contribution margin
                                                </Typography>
                                            </Stack>
                                        </td>
                                        <td align="right">
                                            <Typography color="#464646">
                                                8’555 CHF
                                            </Typography>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Container>
    );
}

const ColorBox = ({ color }) => {
    return (
        <span style={{ width: '20px', height: '20px', borderRadius: '5px', display: "inline-block", background: color }}></span>
    )
}

const styles = {
    accordion: {
        width:
            { md: '70%', sm: '90%', xs: '100%', lg: '50%' },
        borderRadius: '5px',
        border: '1px solid #BEBEBE',
        overflow: 'hidden'
    }
}