import React, { useState } from "react";
import {
  Box,
  InputLabel,
  FormHelperText,
  TextField,
  MenuItem,
  Paper,
  InputAdornment,
  IconButton
} from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import API from "../../axios";

function SearchComboBox({
  labelTop,
  initValue,
  url,
  required,
  handleChange,
  styles,
  objLabel,
  objKey,
  error,
  ...props
}) {
  const [selectedValue, setSelectedValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [listItems, setListItems] = useState(null);

  React.useEffect(() => {
    if (!!initValue) {
      setSelectedValue(initValue);
    }
  }, [initValue]);

  const getRecord = async (_search) => {
    setIsLoading(true);
    try {
      let { data } = await API(url + `search=${_search}`, 'get');
      setListItems(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }
  const handleSearchInput = (v) => {
      setSelectedValue(v);
      getRecord(v);
      if (!openList) setOpenList(true);
  }

  const handleItem = (v) => {
    setOpenList(false);
    setSelectedValue(v[objLabel]);
    handleChange(v);
  }

  const inputID = `input-${Math.ceil(Math.random())}`;

  return (
    <Box sx={[styles, { position: 'relative' }]}>
      {labelTop && (
        <InputLabel
          htmlFor={inputID}
          sx={{
            marginBottom: "5px",
            color: "#000",
          }}
        >
          {labelTop}
        </InputLabel>
      )}
      <TextField
        type='text'
        value={selectedValue}
        id={inputID}
        color="success"
        required={required}
        onChange={(event) => handleSearchInput(event.target.value)}
        onFocus={(e) => {getRecord(e.target.value)}}
        InputProps={!!listItems && listItems.length ? {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setOpenList(!openList)}
              >
                {openList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </InputAdornment>
          )
        } : {}}
        {...props}
      />
      <Box
        component={Paper}
        sx={[dropDownMenu, { visibility: openList ? 'visible' : 'hidden', zIndex: openList ? 25 : -1000 }]}
      >
        {isLoading ?
          <Box
            px={2}
            py={1}
          >
            <FormHelperText sx={{ textAlign: 'center', mt: "0 !important" }}>
              ...Loading
            </FormHelperText>
          </Box>
          :
          !!listItems && listItems.length ?
            listItems.map((v, i) => (
              <MenuItem key={i} selected={selectedValue === v[objKey] ? true : false} onClick={() => handleItem(v)}>{v[objLabel]}</MenuItem>
            ))
            :
            <Box
              px={2}
              py={1}
            >
              <FormHelperText sx={{ textAlign: 'center', mt: "0 !important" }}>
                Does not match any record!
              </FormHelperText>
            </Box>
        }
      </Box>
    </Box>
  );
}
const dropDownMenu = {
  position: 'absolute',
  width: '100%',
  backgroundColor: '#fff'
}
SearchComboBox.defaultProps = {
  labelTop: "",
  initValue: '',
  handleChange: (e) => { },
  styles: {},
  error: "",
  objLabel: 'title',
  objKey: 'id',
}
export default SearchComboBox;