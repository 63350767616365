import React, { useState } from "react";
import {
    Box,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Typography
} from "@mui/material";

import { FilterBar2, FilterLabel, InputField, StartIconBtn } from "../../../../components";
import CreateComponentForm from "../forms/create-component-form";

export default function EditMainTemplatePanel({ switchScreen, ...props }) {

    const [open, setOpen] = useState(false);

    return (
        <>
            <Box>
                <Typography
                    variant='h6'
                    component='h6'
                >
                    ADD NEW COMPONENT
                </Typography>
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item md={8} sm={10} xs={12}>
                        <InputField
                            placeholder='Battery storage including installation'
                            label='Name of the main component template'
                            error=''
                            required
                            fullWidth
                            handleChange={() => { }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant='h6'
                            component='h6'
                            sx={{ mb: 2 }}
                        >
                            BATTERY STORAGE
                        </Typography>
                        <TableContainer>
                            <Table
                                sx={{
                                    minWidth: 650,
                                    '& .MuiTableCell-root': {
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }
                                }}
                            >
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            backgroundColor: "#E6F5FE",
                                        }}
                                    >
                                        <TableCell>Manufacturer</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>item number</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Wart - element 6</TableCell>
                                        <TableCell>element 6</TableCell>
                                        <TableCell>6</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <StartIconBtn
                            title='Add battery storage'
                            styles={{ mt: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant='h6'
                            component='h6'
                            sx={{ mt: 3, mb: 2 }}
                        >
                            OTHER COMPONENT
                        </Typography>
                        <TableContainer>
                            <Table
                                sx={{
                                    minWidth: 650,
                                    '& .MuiTableCell-root': {
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }
                                }}
                            >
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            backgroundColor: "#E6F5FE",
                                        }}
                                    >
                                        <TableCell>Manufacturer</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>item number</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Wart - element 6</TableCell>
                                        <TableCell>element 6</TableCell>
                                        <TableCell>6</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <StartIconBtn
                            title='Add battery storage'
                            styles={{ mt: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant='h6'
                            component='h6'
                            sx={{ mt: 3, mb: 2 }}
                        >
                            OTHER COMPONENT
                        </Typography>
                        <TableContainer>
                            <Table
                                sx={{
                                    minWidth: 650,
                                    '& .MuiTableCell-root': {
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }
                                }}
                            >
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            backgroundColor: "#E6F5FE",
                                        }}
                                    >
                                        <TableCell>components</TableCell>
                                        <TableCell>item number</TableCell>
                                        <TableCell>group</TableCell>
                                        <TableCell>Crowd</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan='4' sx={{ textAlign: 'center', opacity: 0.75 }}>No component found</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <StartIconBtn
                            title='add component'
                            styles={{ mt: 2 }}
                            handleClick={() => setOpen(true)}
                        />
                    </Grid>
                </Grid>
            </Box>
            <CreateComponentForm open={open} handleClose={() => setOpen(false)} />
        </>
    )
}

