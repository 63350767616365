import React from 'react';
import {
    Box,
    Typography,
    Container,
    Stack,
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
} from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import AddIcon from '@mui/icons-material/Add';

import PageTitle from '../../../hooks/page-title';
import { StartIconBtn } from '../../../components';

export default function Inverter() {
    PageTitle('Inverter');

    return (
        <Container
            maxWidth="100%"
            sx={{ paddingTop: '12px', paddingBottom: '12px' }}
        >
            <Typography
                variant="h6"
                component="h6"
                mt={"12px"}
                mb={"10px"}
            >
                INVERTER CONFIGURATION
            </Typography>
            <Typography
                variant="p"
                component="p"
                sx={{
                    color: "#969696",
                    mb: 4
                }}
            >
                Configure the inverters of the system.
            </Typography>
            <Stack
                direction={{ md: "row", xs: 'column' }}
                align="stretch"
                spacing={2}>
                <Box sx={CardStyle}>
                    <p>OVERVIEW PV GENERATOR</p>
                    <table width='100%'>
                        <tr>
                            <td><small>overall system</small></td>
                            <td><small>10,000 kWp</small></td>
                            <td><li><small>25 Module</small></li></td>
                        </tr>
                        <tr>
                            <td><small>roof 1</small></td>
                            <td><small>10,000 kWp</small></td>
                            <td><li><small>25 Module</small></li></td>
                        </tr>
                    </table>
                </Box>
                <Box sx={CardStyle}>
                    <p>INVERTER</p>
                    <table width='100%'>
                        <tr>
                            <td style={{ borderRight: '1px solid #C0C0C0', borderBottom: '1px solid #C0C0C0' }}></td>
                            <td style={{ borderBottom: '1px solid #C0C0C0' }}><b><small>SolarEdge</small></b><br />
                                <small>SE7K</small>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ borderRight: '1px solid #C0C0C0' }}>
                                <small>DC input</small><br />
                                <small>AC power</small><br />
                                <small>Number of DC inputs</small><br />
                                <small>Number of MPP trackers</small><br />
                            </td>
                            <td>
                                <small>9.45 kW</small><br />
                                <small>7 kW</small><br />
                                <small>2</small><br />
                                <small>0</small><br />
                            </td>
                        </tr>
                    </table>
                </Box>
            </Stack>
            <Typography
                variant="h6"
                component="h6"
                mt={4}
                mb={"10px"}
            >
                INVERTER
            </Typography>
            <Stack
            direction={{md:'row',xs:'column'}}
            alignItems='flex-end'
            spacing={2}
            >
                <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow
                            sx={{
                                backgroundColor: "#E6F5FE",
                            }}
                        >
                            <TableCell>Manufacturer</TableCell>
                            <TableCell>Typ</TableCell>
                            <TableCell>item number</TableCell>
                            <TableCell>Main component template</TableCell>
                            <TableCell>Crowd</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>SolarEdge</TableCell>
                            <TableCell>SE7K</TableCell>
                            <TableCell>32</TableCell>
                            <TableCell>No principal component template found</TableCell>
                            <TableCell>1 piece</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </TableContainer>
                <Box>
                    <IconButton sx={{ backgroundColor: '#000', '&:hover': { backgroundColor: '#000' } }}>
                        <SimCardDownloadIcon sx={{ color: '#fff' }} />
                    </IconButton>
                </Box>
            </Stack>
            <StartIconBtn icon={<AddIcon />} styles={{mt:3}} title='add component' />

        </Container >
    )
}

const CardStyle = { '& p': { marginBottom: '15px' }, '& td': { padding: '5px', color: '#464646' }, p: 2, backgroundColor: '#FCFCFC', border: '1px solid #E8E8E8', borderRadius: '7px' }