import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { openPopUp } from "../../../store/reducer";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  FormControl,
  Switch,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { CustomModal, InputField, SearchComboBox } from "../../../components";
import API from "../../../axios";
const modalPricesFields = [
  {
    label: "Price Level",
    name: "price_level",
  },
  {
    label: "Cost Price",
    name: "cost_price",
  },
  {
    label: "Calculation Surcharge",
    name: "calculation_surcharge",
  },
  {
    label: "Installation cost",
    name: "installation_cost",
  },
  {
    label: "Selling Price",
    name: "selling_price",
  },
];

export default function CreateModel({ handleClose, getData = () => {} }) {
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    manufacturer: '',
    manufacturerId: 0,
    image: "",
    modal_price: 0,
    calculation_surcharge: "",
    installation_cost: "",
    selling_price: "",
    price_level: "",
    cost_price: "",
  });
  const [formErrors, setFormErrors] = React.useState({});

  const dispatch = useDispatch();

  const handleForm = async (e) => {
    const fd = new FormData();
    fd.append("image", formData.image[0]);
    fd.append("title", formData.title);
    fd.append("modal_price", formData.modal_price);
    if (formData.modal_price === 1) {
      fd.append("calculation_surcharge", formData.calculation_surcharge);
      fd.append("installation_cost", formData.installation_cost);
      fd.append("selling_price", formData.selling_price);
      fd.append("price_level", formData.price_level);
      fd.append("cost_price", formData.cost_price);
    }
    e.preventDefault();
    setIsLoading(true);
    let errObj = {};
    let obj = {};
    setFormErrors(errObj);
    try {
      await API(
        `admin/manufacturers/${formData.manufacturerId}/modals`,
        "post",
        fd
      );
      setIsLoading(false);
      obj = {
        message: "Model has been created.",
        type: "success",
      };
      dispatch(openPopUp(obj));
      getData();
      handleClose(false);
    } catch (err) {
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        obj = {
          message: err?.response?.message,
          type: "error",
        };
        dispatch(openPopUp(obj));
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        handleClose={() => {
          handleClose();
        }}
      >
        <Container maxWidth="100%">
         
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
              Create Model
            </Typography>
          </Box>

          <form autoComplete="off" onSubmit={handleForm}>
            <Box mt={2} mb={3}>
              <InputField
                placeholder="Title"
                size="small"
                label="Title"
                required
                fullWidth
                styles={{ mb: 2 }}
                name="title"
                error={formErrors?.title}
                handleChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
              <SearchComboBox
                initValue={formData.manufacturer}
                url={"admin/manufacturers?active=active&"}
                fullWidth
                required={true}
                objkey="id"
                objLabel="manufacturer"
                error={formErrors?.manufacturer}
                size="small"
                handleChange={(e) =>{setFormData({...formData, manufacturer: e.manufacturer, manufacturerId: e.id})}}
                label="Manufacturer"
              />

              <FormControl variant="standard" mt={2}>
                <Typography component="label" variant="label">
                  select image{" "}
                </Typography>
                <input
                  required
                  name="image"
                  onChange={(e) =>
                    setFormData({ ...formData, image: e.target.files })
                  }
                  type="file"
                  accept="image/*"
                />
              </FormControl>
              {formErrors?.image !== "" && (
                <FormHelperText sx={{ color: "red", mt: "0 !important" }}>
                  {formErrors?.image}
                </FormHelperText>
              )}
              <Box>
                <Typography component="h6" variant="h6" mt={2}>
                  Modal Prices{" "}
                </Typography>
                <small>Disabled</small>
                <Switch
                  checked={formData.modal_price}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      modal_price: e.target.checked ? 1 : 0,
                    })
                  }
                  color="success"
                />
                <small>Active</small>
              </Box>
              {formData.modal_price === 1 &&
                modalPricesFields.map((field, index) => {
                  return (
                    <Fragment key={index}>
                      <InputField
                        placeholder={field.label}
                        size="small"
                        label={field.label}
                        name={field.name}
                        error={formErrors?.[field.name]}
                        required={formData.modal_price}
                        fullWidth
                        styles={{ mb: 2 }}
                        handleChange={(e) =>
                          setFormData({
                            ...formData,
                            [field.name]: e.target.value,
                          })
                        }
                      />
                    </Fragment>
                  );
                })}
              <Box sx={{ textAlign: "end" }}>
                <Button
                  variant="contained"
                  onClick={() => {}}
                  sx={{
                    mt: 2,
                  }}
                  disabled={isLoading}
                  type="submit"
                >
                  {isLoading ? (
                    <CircularProgress
                      size={18}
                      sx={{ color: "#fff", mr: 1, size: "12px" }}
                    />
                  ) : null}
                  Create
                </Button>
              </Box>
            </Box>
          </form>
        </Container>
      </CustomModal>
    </>
  );
}

