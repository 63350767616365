import React from "react";
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';

import { FilterBar2 } from "../../../../components";

export default function ViewGroupPanel({switchScreen,...props}) {

    return (
        <Box>
            <FilterBar2 />
            <Box sx={{ mt: 3 }} maxWidth='800px'>
                <TableContainer>
                    <Table
                        sx={{
                            minWidth: 650,
                            '& .MuiTableCell-root': {
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }
                        }}
                    >
                        <TableHead>
                            <TableRow
                                sx={{
                                    backgroundColor: "#E6F5FE",
                                }}
                            >
                                <TableCell>technologies</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>language</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <IconButton
                                        sx={{
                                            backgroundColor: '#000',
                                            '&:hover': {
                                                backgroundColor: '#ccc',
                                            }
                                        }}
                                        size='small'
                                        onClick={() => { }}
                                    >
                                        <SettingsIcon sx={{ color: '#fff' }} fontSize='small' />
                                    </IconButton>
                                </TableCell>
                                <TableCell>PV system standard</TableCell>
                                <TableCell>GERMAN (CH)</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>

    )
}