import React from "react";
import {
    Box,
    Stack,
    Button,
} from "@mui/material";

import { logo2 } from "../../assets";
import SelectBox from "../input-field/select-box";
import SearchBox from "../input-field/search-box";
import { useTranslation } from "react-i18next";

export default function FilterBar1() {
    const { t } = useTranslation();

      return (
        <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        mb={2}
    >
        <Box>
            <Box>
                <Stack
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='flex-end'
                    spacing={1}
                    flexWrap='wrap'
                    sx={{'& > div,& > button':{marginTop:'10px !important'}}}
                >
                    <SearchBox
                        size="small"
                        label={t('HOME.FILTERS.Search')}
                    />

                    <SelectBox items={['Alle', 'Alle']} label={t('HOME.FILTERS.Project_Manager')} size='small' />
                    <SelectBox items={['Alle', 'Alle']} label={t('HOME.FILTERS.Updated')} size='small' />
                    <SelectBox items={['Alle', 'Alle']} label={t('HOME.FILTERS.Status')} size='small' />
                    <SelectBox items={['Alle', 'Alle']} label={t('HOME.FILTERS.Technologies')} size='small' />
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#464646",
                            borderRadius: "25px",
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: "#464646",
                            }
                        }}
                    >
                        {t('HOME.FILTERS.Use')}
                    </Button>
                </Stack>
            </Box>
            <Box sx={{mt:'15px'}}>
                <Stack
                    direction='row'
                    alignItems='flex-start'
                    spacing='5px'
                >
                    <SelectBox items={['Alle', 'Alle']} label={t('HOME.FILTERS.Columns')} size='small' />
                    <SelectBox items={['Alle', 'Alle']} label={t('HOME.FILTERS.Shown')} size='small' />
                </Stack>
            </Box>
        </Box>
        <Box sx={{display:{md:'block',xs:'none'}}}>
            <img src={logo2} width="150px" alt="" />
        </Box>
    </Stack>
    )
}