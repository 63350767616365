import React, { useState, useEffect } from "react";

import {
  Container,
  Button,
  Box,
  TableRow,
  TableCell,
  Typography,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import API from "../../../axios";
import { UsePagination, PopUpMsg, TableWrapper } from "../../../components";
import { useNavigate } from "react-router-dom";

export default function GetOrganization() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [organizations, setOrganizations] = useState(null);
  const [page, setPage] = useState(1);

  const [open, setOpen] = useState(false);
  const [payload, setPayload] = useState({
    type: "success",
    message: "",
  });

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async() => {
    setIsLoading(true);
   try{
      const res = await API(`admin/organizations?page=${page}`, "get")
      setOrganizations(res.data);
      setIsLoading(false);
    }
    catch(err){
      setIsLoading(false);
      setOrganizations(null);
      setOpen(true);
      setPayload({ message: err.message, type: "error" });
    };
  };
  var tableCols = ["Title", "Description", "Status", "Action"];
  return (
    <>
    <Container maxWidth="100%" sx={{ py: 3 }}>
      <Typography variant="h6">Organizations</Typography>
      <Box textAlign="end" mt={2} mb={2}>
        <Button
          variant="contained"
          onClick={() => navigate("/admin/organization/create")}
        >
          Create
        </Button>
      </Box>
      <TableWrapper
          thContent={tableCols.map((column, index) => (
            <TableCell key={index}>{column}</TableCell>
          ))}
          spanTd={tableCols.length}
          isLoading={isLoading}
          isContent={!!organizations && organizations?.data.length ? true : false}
          tableStyle={{minWidth:"400px"}}
        >
          {!!organizations &&
             organizations?.data.map((organization, index) => (
              <TableRow key={index}>
                <TableCell>{organization.title}</TableCell>
                <TableCell width="50%">
                  <Typography
                    sx={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                    }}
                    variant="caption1"
                  >
                    {organization.description}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Chip
                    label={organization.active ? "active" : "disabled"}
                    color={organization.active ? "success" : "error"}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={() =>
                        navigate("/admin/organization/update", {
                          state: { id: organization.id },
                        })
                      }
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableWrapper>
      {!!organizations && organizations?.meta?.last_page > 1 && (
        <Box component="div" sx={{ mt: 2 }}>
          <UsePagination
            total={organizations?.meta?.total}
            perPage={organizations?.meta?.per_page}
            page={page}
            setPage={setPage}
          />
        </Box>
      )}
      <PopUpMsg
        open={open}
        type={payload.type}
        message={payload.message ?? "error occurred"}
        handleClose={() => setOpen(false)}
      />
    </Container>
    </>
  );
}
