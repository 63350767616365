import React from 'react';
import { Header, TopBar, SideNavigation, SelectBox } from '../../../components';
import PageTitle from '../../../hooks/page-title';
import {
    Box,
    Container,
    Typography,
    Grid,
    TextField,
} from '@mui/material';

export default function PvSystem() {
    PageTitle('PV - SYSTEM | GamaAg');
    return (
        <Container
            maxWidth="100%"
            sx={{ paddingTop: '15px', paddingBottom: '15px' }}
        >
            <Typography
                variant="h5"
                component="h5"
                fontSize={"18px"}
                mt={"12px"}
                mb={"10px"}
            >
                PV - SYSTEM
            </Typography>
            <Typography
                variant="p"
                component="p"
                sx={{
                    color: "#969696",
                }}
            >
                Andern Sie hier die Einstellungen Ihres PV-Systems
            </Typography>

            <Box mt={3}>
                        <SelectBox
                            label='compensation model'
                            items={['ownconsumption', 'full feed', 'Own consumption ZEV']}
                            sx={{maxWidth:'450px',mt:4}}
                        />
            </Box>
        </Container>
    )
}


const inputStyle = {
    mt: 1,
    width: '100%',
    '& .MuiInputBase-input': {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    "& .Mui-focused fieldset": {
        borderColor: "#1B9607 !important",
    },
    '& .MuiNativeSelect-select': {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
}