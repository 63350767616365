import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { openPopUp } from "../../../store/reducer";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";

import {
  CustomModal,
  InputField,
  SearchComboBox,
} from "../../../components";
import API from "../../../axios";

export default function CreateManufacturer({
  handleClose,
  getData = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);

  const [formData, setFormData] = useState({
    manufacturer: "",
    componentId: 0,
    component: "",
  });
  const [formErrors, setFormErrors] = React.useState({});

  const dispatch = useDispatch();
  useEffect(() => {}, []);

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let errObj = {};
    let obj = {};
    setFormErrors(errObj);
    try {
     await API(
        `admin/component-types/${formData.componentId}/manufacturers`,
        "post",
        formData
      );
      setIsLoading(false);
      obj = {
        message: "Manufacturer has been created.",
        type: "success",
      };
      dispatch(openPopUp(obj));
      getData();
      handleClose(false);
    } catch (err) {
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        obj = {
          message: err?.response?.message,
          type: "error",
        };
        dispatch(openPopUp(obj));
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        handleClose={() => {
          handleClose();
        }}
      >
        <Container maxWidth="100%">
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
              Create Manufacturer
            </Typography>
          </Box>
          {getLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={30} />
            </Box>
          ) : (
            <form autoComplete="off" onSubmit={handleForm}>
              <Box mt={2} mb={3}>
                <InputField
                  placeholder="Title"
                  size="small"
                  label="Title"
                  required
                  fullWidth
                  styles={{ mb: 2 }}
                  name="manufacturer"
                  error={formErrors?.manufacturer}
                  handleChange={(e) =>
                    setFormData({ ...formData, manufacturer: e.target.value })
                  }
                />
                <SearchComboBox
                  initValue={formData.component}
                  url={"admin/component-types?active=active&"}
                  fullWidth
                  required={true}
                  objkey="id"
                  objLabel="title"
                  size="small"
                  handleChange={(e) => {
                    setFormData({
                      ...formData,
                      component: e.title,
                      componentId: e.id,
                    });
                  }}
                  label="Component Type"
                  styles={{ mb: 2 }}
                />
                <Box sx={{ textAlign: "end" }}>
                  <Button
                    variant="contained"
                    onClick={() => {}}
                    sx={{
                      mt: 2,
                    }}
                    disabled={isLoading}
                    type="submit"
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={18}
                        sx={{ color: "#fff", mr: 1, size: "12px" }}
                      />
                    ) : null}
                    Create
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Container>
      </CustomModal>
     
    </>
  );
}
// const modal2Style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   overflowY: "auto",
//   width: "100%",
//   maxWidth: "400px",
//   bgcolor: "#fff",
//   border: "0",
//   borderRadius: "7px",
//   boxShadow: 24,
//   p: 2,
//   zIndex: 100,
// };
