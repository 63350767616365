import React, { Fragment, useState } from "react";
import {
  Container,
  Button,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import {
  InputField,
  PopUpMsg,
  SelectBox,
  PasswordInputField,
} from "../../../components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openPopUp } from "../../../store/reducer";
import API from "../../../axios";

export default function AddOrganization() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    telephone: "",
    mobile_phone: "",
    language: "english",
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [open, setOpen] = useState(false);
  const [payload, setPayload] = useState({
    type: "error",
    message: "",
  });
  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let errObj = {};
    setFormErrors({});
    try {
      const res = await API("admin/organizations", "post", formData);
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: "Organization has been created.",
          type: "success",
        })
      );
      navigate("/admin/organization");
    } catch (err) {
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        setOpen(true);
        setPayload({ ...payload, message: err?.response?.message });
      }
      setIsLoading(false);
    }
  };

  var inputFields = [
    {
      placeholder: "Title",
      label: "Title",
      name: "title",
      value: "",
      error: formErrors.title,
      type: "text",
      required: true,
    },
    {
      placeholder: "Description",
      name: "description",
      label: "Description",
      error: "",
      value: "",
      type: "text_area",
      required: true,
    },
    {
      placeholder: "First Name",
      label: "First Name",
      name: "first_name",
      error: formErrors?.first_name,
      value: "",
      type: "text",
      required: true,
    },
    {
      placeholder: "Last Name",
      label: "Last Name",
      name: "last_name",
      error: formErrors?.last_name,
      value: "",
      type: "text",
      required: true,
    },
    {
      placeholder: "Johndoe@gmail.com",
      label: "Email",
      name: "email",
      error: formErrors?.email,
      value: "",
      type: "email",
      required: true,
    },
    {
      placeholder: "Telephone",
      label: "Telephone",
      name: "telephone",
      error: formErrors?.telephone,
      value: "",
      type: "tel",
      required: true,
    },
    {
      placeholder: "Mobile Phone",
      label: "Mobile Phone",
      name: "mobile_phone",
      error: formErrors?.mobile_phone,
      value: "",
      type: "tel",
      required: false,
    },
    {
      placeholder: "",
      label: "Language",
      name: "language",
      error: formErrors?.language,
      value: formData.language,
      type: "select_box",
      required: true,
    },
    {
      placeholder: "******",
      label: "Password",
      name: "password",
      error: formErrors?.password,
      value: "",
      type: "password",
      required: true,
    },
  ];
  return (
    <Container maxWidth="100%" sx={{ py: 3 }}>
      <form autoComplete="off" onSubmit={handleForm}>
        <Box mt={2} mb={3}>
          <Typography variant="h6" component="h6" mb={1}>
            Organization Info
          </Typography>
          <Grid container spacing={1}>
            {inputFields.map((input, index) => {
              return (
                <>
                  {input.name === "first_name" && (
                    <Grid item md={12} sm={12} xs={12} key={index + "p"}>
                      <Typography variant="h6" component="h6" mb={1}>
                        Manager Info
                      </Typography>
                    </Grid>
                  )}
                  <Grid item md={6} sm={12} xs={12} key={index}>
                    {input.type === "select_box" ? (
                      <SelectBox
                        items={[
                          { label: "English", value: "english" },
                          { label: "Detuch", value: "detuch" },
                        ]}
                        initValue={input.value}
                        label={input.label}
                        name={input.name}
                        error={input.error}
                        size="small"
                        styles={{ mb: 2 }}
                        handleChange={handleChange}
                      />
                    ) : input.type === "password" ? (
                      <PasswordInputField
                        placeholder={input.placeholder}
                        label={input.label}
                        name={input.name}
                        required={input.required}
                        size="small"
                        fullWidth
                        styles={{ mb: 2 }}
                        handleChange={handleChange}
                      />
                    ) : (
                      <InputField
                        placeholder={input.placeholder}
                        label={input.label}
                        name={input.name}
                        error={input.error}
                        required={input.required}
                        size="small"
                        fullWidth
                        styles={{ mb: 2 }}
                        multiline={input.type === "text_area"}
                        handleChange={handleChange}
                      />
                    )}
                  </Grid>
                </>
              );
            })}
          </Grid>

          <Box sx={{ textAlign: "end" }}>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                mr: 1,
              }}
              onClick={() => navigate("/admin/organization")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {}}
              sx={{
                mt: 2,
              }}
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <CircularProgress
                  size={18}
                  sx={{ color: "#fff", mr: 1, size: "12px" }}
                />
              ) : null}
              Create
            </Button>
          </Box>
        </Box>
      </form>
      <PopUpMsg
        open={open}
        type={payload.type}
        message={payload.message ?? "error occurred"}
        handleClose={() => setOpen(false)}
      />
    </Container>
  );
}
