import React from 'react';
import {
    Container,
    Box,
    Typography,
    useMediaQuery,
    Stack,
    Grid,
    CircularProgress
} from '@mui/material'

export default function EnergySystem() {
    const min535px = useMediaQuery('(min-width:33.4375rem)');
    return (
        <Container maxWidth="100%" sx={{ paddingTop: "25px", paddingBottom: "100px" }}>
            <Box sx={{ px: { sm: 4 } }}>
                <Typography component="h5" variant="h5" sx={{ fontSize: { xs: '1rem', sm: "1.35rem" }, fontWeight: 500 }}>YOUR PERSONAL OFFER</Typography>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Stack
                        direction="row"
                        sx={{ mt: { xs: 2, sm: 0 } }}
                    >
                        <span style={styles.arrow}></span>
                        <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', sm: "1.15rem" }, fontWeight: 500 }}>YOUR NEW ENERGY SYSTEM</Typography>
                    </Stack>
                    <img width={min535px ? "154" : "100"} src={require('../../../../../assets/images/logo.png')} />
                </Stack>
            </Box>
            <Box
                sx={{
                    maxWidth: '1200px'
                }}
            >
                <Box>
                    <img src={require('../../../../../assets/images/map-banner.png')} width='100%' />
                </Box>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='center'
                            flexWrap='wrap'
                            sx={{ height: '100%' }}
                        >
                            <Stack
                                alignItems='center'
                                sx={{ m: 2 }}>
                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                    <CircularProgress
                                        variant="determinate"
                                        color="success"
                                        value={60}
                                        size={150}
                                        thickness={2}
                                    />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" component="div" color="text.secondary">
                                            60%
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography
                                    component="p"
                                    variant="p"
                                    mt={2}
                                >
                                    INDEPENDENCE
                                </Typography>
                            </Stack>
                            <Stack
                                alignItems='center'
                                sx={{ m: 2 }}>
                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                    <CircularProgress
                                        variant="determinate"
                                        color='primary'
                                        value={80}
                                        size={150}
                                        thickness={2} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" component="div" color="text.secondary">
                                            80%
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography
                                    component="p"
                                    variant="p"
                                    mt={2}
                                >
                                    OWNCONSUMPTION
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                            >
                                <Typography
                                    variant='p'
                                    component='h4'
                                >
                                    Heizing
                                </Typography>
                                <Typography
                                    variant='p'
                                    component='p'
                                    color={'#464646'}
                                >
                                    heat pump
                                </Typography>
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                            >
                                <Typography
                                    variant='p'
                                    component='h4'
                                >
                                    Heizing
                                </Typography>
                                <Typography
                                    variant='p'
                                    component='p'
                                    color={'#464646'}
                                >
                                    heat pump
                                </Typography>
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                            >
                                <Typography
                                    variant='p'
                                    component='h4'
                                >
                                    Heizing
                                </Typography>
                                <Typography
                                    variant='p'
                                    component='p'
                                    color={'#464646'}
                                >
                                    heat pump
                                </Typography>
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                            >
                                <Typography
                                    variant='p'
                                    component='h4'
                                >
                                    Heizing
                                </Typography>
                                <Typography
                                    variant='p'
                                    component='p'
                                    color={'#464646'}
                                >
                                    heat pump
                                </Typography>
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                            >
                                <Typography
                                    variant='p'
                                    component='h4'
                                >
                                    Heizing
                                </Typography>
                                <Typography
                                    variant='p'
                                    component='p'
                                    color={'#464646'}
                                >
                                    heat pump
                                </Typography>
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                            >
                                <Typography
                                    variant='p'
                                    component='h4'
                                >
                                    Heizing
                                </Typography>
                                <Typography
                                    variant='p'
                                    component='p'
                                    color={'#464646'}
                                >
                                    heat pump
                                </Typography>
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                            >
                                <Typography
                                    variant='p'
                                    component='h4'
                                >
                                    Heizing
                                </Typography>
                                <Typography
                                    variant='p'
                                    component='p'
                                    color={'#464646'}
                                >
                                    heat pump
                                </Typography>
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                            >
                                <Typography
                                    variant='p'
                                    component='h4'
                                >
                                    Heizing
                                </Typography>
                                <Typography
                                    variant='p'
                                    component='p'
                                    color={'#464646'}
                                >
                                    heat pump
                                </Typography>
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                            >
                                <Typography
                                    variant='p'
                                    component='h4'
                                >
                                    Heizing
                                </Typography>
                                <Typography
                                    variant='p'
                                    component='p'
                                    color={'#464646'}
                                >
                                    heat pump
                                </Typography>
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                            >
                                <Typography
                                    variant='p'
                                    component='h4'
                                >
                                    Heizing
                                </Typography>
                                <Typography
                                    variant='p'
                                    component='p'
                                    color={'#464646'}
                                >
                                    heat pump
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{ maxWidth: { sm: '40%' }, mt: 10 }}>
                <Typography sx={{ fontSize: '0.875rem' }}>05.10.2022</Typography>
                <Typography sx={{ fontSize: '0.875rem' }}>gam-448</Typography>
            </Stack>
        </Container>
    )
}

const styles = {
    arrow: {
        width: 0,
        height: 0,
        display: 'inline-block',
        borderTop: '7px solid transparent',
        borderBottom: '7px solid transparent',
        borderLeft: '7px solid black',
        transform: 'rotate(221deg)',
        marginRight: '5px'
    }
}