import { Box, Stack, Button, IconButton, Typography, Modal, CircularProgress, Backdrop, Snackbar } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";

export default function RemoveProjectModal({ open, getData=()=>{}, id, setId, handleClose }) {

    const [openDrop, setOpenDrop] = React.useState(false);
    const [showMessage, setShowMessage] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const removeRecord = () => {
        return
        setOpenDrop(true);
        axios.get(`https://sourceignite.com/gamaa/api/delete-project/${id}`, {
            headers: {
                'content-type': 'application/json; charset=utf-8',
            }
        }).then(res => {
            setOpenDrop(false);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 3000);
            setMessage(res.data.response.message);
            handleClose();
            getData();
        }).catch(err => {
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 3000);
            setMessage('Es ist ein Fehler aufgetreten. Bitte versuche es erneut!');
            setOpenDrop(false);
        })
    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => {
                    handleClose();
                    setId(null);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modal2Style}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                handleClose();
                                setId(null);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        mt={1}
                    >
                        <Typography
                            component='h6'
                            variant='h6'
                            textAlign='center'
                        >
                            Bist du dir sicher?
                        </Typography>
                        <Stack
                            direction='row'
                            alignItems='center'
                            justifyContent='center'
                            mt={2}
                            mb={2}
                            spacing={1}
                        >
                            <Button
                                variant='contained'
                                onClick={removeRecord}
                                color="error"

                            >
                                yes
                            </Button>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    handleClose();
                                    setId(null);
                                }}
                                color="primary"

                            >
                                no
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Modal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999999 }}
                open={openDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={showMessage}
                autoHideDuration={6000}
                onClose={() => setShowMessage(false)}
                message={message}
            />
        </>
    );
}

const modal2Style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    width: '100%',
    maxWidth: '400px',
    bgcolor: '#fff',
    border: '0',
    borderRadius: '7px',
    boxShadow: 24,
    p: 2,
    zIndex: 100
};